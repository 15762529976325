import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Link } from 'react-router-dom';
import { API } from '../../actions/API.js';
import { broadcastRole, broadcastStationSuggestion, beginStation, end } from '../../actions/Echo';
import { Button, Row, Col, Skeleton, Card, Modal, Icon, message } from 'antd';
import { setRole, roleUpdated } from '../../constants/ActionTypes';
import RoleChooser from '../Partials/RoleChooser';
import GroupStudy from '../Partials/GroupStudy';
import Slider from "../Partials/Slider"
import MetaTags from 'react-meta-tags';

import logo from '../../static/images/logo-icon.png';

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      stats: null,
      featured_stations: null,
      tutorial: null,
      view_tutorial: false
    }
  }

  componentDidMount = async () => {
    if (localStorage.getItem('token') !== null) {
      let user = await this.props.dispatch(API('/user?completed=false', 'GET'));
      let featured_stations = await this.props.dispatch(API('/station/featured', 'GET'));
      let tutorial = await this.props.dispatch(API('/tutorial', 'GET'));
      if (!this.unmounted) {
        this.setState({
          user,
          featured_stations,
          tutorial: tutorial.success ? tutorial.tutorial : null,
          view_tutorial: !user.tutorial_viewed && tutorial.success
        });

        let stats = await this.props.dispatch(API('/user/stats', 'GET'));
        if (!this.unmounted)
        {
          this.setState({stats: {
            complete: stats.completed.length,
            average: stats.average_mark,
            strengths: stats.strengths
          }});
        }
      }
    } else {
      this.props.dispatch(push('login'));
    }
  }

  componentWillUnmount = () => {
    this.unmounted = true;
  }

  goToStation = (station_no) => {
    let role = this.props.role;
    if (role === 'candidate-1') {
      this.props.dispatch(push('/station/' + station_no));
    } else if (role === undefined || role === null) {
      Modal.warning({
        title: 'Please choose a role before continuing',
        content: <RoleChooser selected={(role) => this.chooseRole(station_no, role)} groupStudy={this.props.groupStudy} members={this.props.members} redirect={(redirect) => this.roleChooserRedirect(redirect)} />,
        width: '80%',
        className: 'modal-role-chooser',
        okButtonProps: { disabled: true },
        okText: this.props.groupStudy ? 'Switch to Solo Study' : 'Switch to Group Study'
      });
    } else {
      this.chooseRole(station_no, role);
    }
  }

  chooseRole = (station_no, role) => {
    this.props.dispatch(setRole(role));
    this.props.dispatch(roleUpdated(!this.props.roleUpdated));
    this.props.dispatch(broadcastRole(this.props.role));

    if (role == 'candidate-1')
      this.props.dispatch(end());

    if (station_no !== null) {
      Modal.destroyAll();
      if (role === 'candidate-1') {
        this.props.dispatch(push('/station/' + station_no));
      } else if (role === 'examiner') {
        this.props.dispatch(beginStation(station_no));
      } else {
        this.props.dispatch(broadcastStationSuggestion(station_no));
      }
    } else {
      this.props.dispatch(push('/study'));
    }
  }

  roleChooserRedirect = (redirect) => {
    Modal.destroyAll();
    message.warning('Return to latest stations once you have entered a group');
    this.props.dispatch(push(redirect));
  }

  switchSolo = () => {
    Modal.destroyAll();
    this.props.dispatch(setRole('candidate-1'))
    this.props.dispatch(roleUpdated(!this.props.roleUpdated))
    this.props.dispatch(end('/study'));
  }

  closeTutorial = () => {
    this.setState({ view_tutorial: false });
    if (!this.state.user.tutorial_viewed)
    {
      this.props.dispatch(API("/user/update_tutorial", "POST", { status: true }));
      this.setState({user: {...this.state.user, tutorial_viewed: true}});
    }
  }

  render() {
    return (
      <div id="dashboard">
        <MetaTags>
          <title>Profile | OSCEbank</title>
        </MetaTags>

        {this.state.view_tutorial && this.state.tutorial !== null ?
          <Modal
            title="Tutorial"
            visible={this.state.view_tutorial}
            onCancel={() => this.closeTutorial()}
            cancelText="Close"
            okButtonProps={{style: {display: 'none'}}}
            className="tutorial-modal"
            width={null}
          >
            <Slider slider={this.state.tutorial} />
          </Modal> : ''}

        <div className="intro container fixed">
          {this.state.user.first_name === undefined ?
            <Skeleton avatar={{ size: 'large' }} active paragraph={{ rows: 0 }} />
          :
            <h1><img src={logo} id="logo-icon" alt="OSCEbank logo" />Welcome back, {this.state.user.first_name}.</h1>
          }

          <div className="buttons">
            {this.state.user.status !== undefined && this.state.user.status !== 'Active' ?
              <Link to="/subscriptions" className="subscription-btn ant-btn ant-btn-lg"><Icon type="dollar" /><span>Buy Subscription</span></Link>
            : ''}
            <Link to="/settings" className="settings-btn ant-btn ant-btn-lg"><Icon type="setting" /><span>Settings</span></Link>
            {this.state.tutorial !== null ?
              <Button icon="exclamation-circle" size="large" onClick={() => this.setState({ view_tutorial: !this.state.view_tutorial })}>Tutorial</Button>
              : ''}
          </div>
        </div>

        <div className="results">
          <div className="container fixed">
            <Row type="flex" justify="space-between" align="top">
              <Col md={8} xs={24} className="result-col">
                <h3>Stations Completed</h3>
                {this.state.stats === null ? <h1><Skeleton active paragraph={false} title={{ width: '25%' }} /></h1> :
                  <h1>{this.state.stats.complete}</h1>}
              </Col>
              <Col md={8} xs={24} className="result-col">
                <h3>Average Grade</h3>
                {this.state.stats === null ? <h1><Skeleton active paragraph={false} title={{ width: '25%' }} /></h1> :
                  <h1>{this.state.stats.average + '%'}</h1>}
              </Col>
              <Col md={8} xs={24} className="result-col">
                <h3>Best Specialty</h3>
                {this.state.stats === null ? <h1><Skeleton active paragraph={false} title={{ width: '25%' }} /></h1> :
                  <h1>{this.state.stats.strengths}</h1>}
              </Col>
            </Row>
          </div>
        </div>

        {this.state.user !== {} ?
          <div className="study-time container fixed">
            <h1>Study Time!</h1>

            <div className="buttons">
              <Button type="primary" className="button" onClick={() => { this.chooseRole(null, 'candidate-1'); }}><Icon type="book" /> Solo Study</Button>
              <Link to="/study-groups">
                <Button type="primary" className="button"><Icon type="team" /> Group Study</Button>
              </Link>
            </div>

            {this.props.groupStudy ? <GroupStudy layout="horizontal" displayStation={true} /> : ''}
          </div>
        : ''}

        <div className="featured-stations container fixed">
          {this.state.featured_stations !== null && this.state.featured_stations.length > 0 ?
            <Card title="Featured Stations" className="featured-stations-card">
              {this.state.featured_stations.map((value, index) => {
                return <div className="featured-station" key={'featured-station-' + index}>
                  <p><b>{value.number}</b>{value.name}</p>
                  <Button onClick={() => this.goToStation(value.number)}>Start</Button>
                </div>;
              })}
            </Card>
          : ''}
      </div>
    </div>)
  }
}

export default connect(state => {
  return {
    groupStudy: state.groupStudy !== null,
    members: state.members,
    role: state.role,
    roleUpdated: state.roleFlag
  }
})(Profile);