import React, { Component } from "react";
import { connect } from "react-redux";
import { message, Spin } from "antd";
import { API } from '../../actions/API.js';
import { getUser } from "../../actions/functions";
import SettingsForm from "./SettingsForm";
import ChangePasswordForm from "./ChangePasswordForm";
import Errors from "../Auth/Errors";
import MetaTags from 'react-meta-tags';


class SettingsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countries: null,
      medical_schools: null,
      user: null,
      loading: true,
      errors: []
    }
  }

  componentDidMount = async() => {
    let countries = await this.props.dispatch(API("/countries", "GET"));
    let medical_schools = await this.props.dispatch(API("/medical_schools", "GET"));
    let user = await getUser(this.props);
    if (!this.unmounted)
      this.setState({ countries, medical_schools, user, loading: false });
  }

  componentWillUnmount = () => {
    this.unmounted = true;
  }

  submit = async(values) => {
    this.setState({loading: true});
    values.dob = values.dob.format("YYYY-MM-DD");
    let user = await this.props.dispatch(API("/user/update", "POST", values));
    if (user.success)
    {
      message.success("Settings successfully updated!");
      if (!this.unmounted)
        this.setState({ user: user.user, errors: [], loading: false });
    }
    else if (!this.unmounted)
      this.setState({ errors: user.errors, loading: false });
  }

  changePassword = async(values) => {
    let response = await this.props.dispatch(API("/user/change_password", "POST", values));
    if (!response.success)
      message.error(response.message);
    else
      message.success(response.message);
  }

  uploadAvatar = (state) => {
    if (state.file !== undefined && state.file.percent == 100 && state.file.response !== undefined)
      this.setState({ user: {...this.state.user, avatar: state.file.response.file }})
  }

  render() {
    return <div id="settings">
      {this.state.loading ?
        <Spin />
      :
      <div>
        <MetaTags>
            <title>Settings | OSCEbank</title>
        </MetaTags>

        <div className="container">
          <Errors errors={this.state.errors} />
          <SettingsForm
            onSave={this.submit}
            countries={this.state.countries}
            medical_schools={this.state.medical_schools}
            user={this.state.user}
            uploadAvatar={this.uploadAvatar}
          />
          <ChangePasswordForm
            onSave={this.changePassword}
          />
        </div>
      </div>}
    </div>;
  }
}
export default connect()(SettingsPage);