import React, { Component } from "react";
import { connect } from "react-redux";
import { Tooltip } from "antd"

class Percentage extends Component {

  render() {
    if (this.props.mark === null || this.props.mark.isNaN)
      return "";

    let percentage = parseInt(((this.props.mark / this.props.max_marks) * 100).toFixed(2));
    let rating = "high";
    if (percentage < 50)
      rating = "low";
    else if (percentage > 49 && percentage < 70)
      rating = "medium";

    if (this.props.tooltip !== undefined && this.props.tooltip)
      return <Tooltip title="This is your most recent mark">
        <p id="percentage-display" className={"percentage " + rating}>{percentage}%</p>
      </Tooltip>

    return <p id="percentage-display" className={"percentage " + rating}>{percentage}%</p>
  }
}

export default connect()(Percentage);