import React, { Component } from "react";
import { Skeleton } from "antd";


class SkeletonDisplay extends Component {
  render() {
    return (
      <div className="station-display skeleton-display">
        <div className="station-info">
          <h2><Skeleton active paragraph={false} title={{width: "75%"}} /></h2>

          <table className="stations-list">
            <tbody>
              <tr>
                <td style={{ paddingRight: "2em" }}>Systems</td>
                <td style={{ width: "50%" }}><Skeleton active paragraph={false} /></td>
              </tr>
              <tr>
                <td style={{ paddingRight: "2em" }}>Types</td>
                <td style={{ width: "50%" }}><Skeleton active paragraph={false} /></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default SkeletonDisplay;