import React, { Component } from "react";
import { connect } from "react-redux";

class Errors extends Component {
  render() {
    if (this.props.errors.length === 0)
      return "";

    let errors = this.props.errors.map((value, index) => {
      return <p key={"error-"+index}>{value}</p>
    })

    return (
      <div id="auth-errors">
        {errors}
      </div>
    )
  }
}

export default connect()(Errors);