import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";

class Content extends Component {
    constructor(props) {
        super(props);

        this.state = {
            node_num: 0,
            xs: 24,
            sm: 24,
            md: 24
        }
    }

    componentDidMount = async () => {
        if (this.props.content.length === 2) {
            this.setState({
                xs: 24,
                sm: 12,
                md: 12
            })
        } else if (this.props.content.length === 3) {
            this.setState({
                xs: 24,
                sm: 24,
                md: 8
            })
        } else {
        }
    }

    render() {
        return <Row gutter={40} className="standard-content">
            {this.props.content.map((value, index) => {
                return <Col xs={this.state.xs} sm={this.state.sm} md={this.state.md} key={"cms-content-" + index}>
                            <h1 className="standard-content-title">{value.title}</h1>
                            <div className="standard-content-text" dangerouslySetInnerHTML={{__html: value.content}} />
                        </Col>
            })}
        </Row>
    }
}

export default connect()(Content);