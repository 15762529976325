import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route } from "react-router-dom";
import PageLayout from "./PageLayout";

class PageRoute extends Component {

  constructor(props) {
    super(props);
    this.headingRef = React.createRef();
  }

  componentDidMount = () => {
    if (!this.unmounted)
      window.scrollTo(0, this.headingRef.current.offsetTop);
  }

  componentWillReceiveProps = () => {
    if (!this.unmounted)
      window.scrollTo(0, this.headingRef.current.offsetTop);
  }

  componentWillUnmount = () => {
    this.unmounted = true;
  }

  render() {
    const props = this.props;
    const { isAuthenticated, component, location, ...rest } = props;
    const Component = component;
    return (
      <Route
        {...rest}
        render={props => {
          return <PageLayout match={props.match} ref={this.headingRef}>
              <Component match={props.match} location={location} />
            </PageLayout>
        }}
      />
    );
  }

}
const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default withRouter(connect(mapStateToProps)(PageRoute));