import React, { Component } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import { Redirect } from "react-router-dom";
import { Form, Button, Input, DatePicker, Spin, Select, Divider, Row, Card, Radio, Icon, Col, Checkbox, Modal } from "antd";
import FacebookLogin from 'react-facebook-login';
import PaypalExpressBtn from 'react-paypal-express-checkout';
import ReCAPTCHA from "react-google-recaptcha";
import { calculateTotalCost, displayPrice } from "../../../actions/functions";
const FormItem = Form.Item;
const paypal_env = process.env.REACT_APP_PAYPAL_ENV;
const paypal_currency = process.env.REACT_APP_CURRENCY;
const paypal_client = {
  sandbox: process.env.REACT_APP_PAYPAL_SANDBOX_ID,
  production: process.env.REACT_APP_PAYPAL_PRODUCTION_ID
}
const facebookAppId = process.env.REACT_APP_FACEBOOK_APPID;
const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

class SignupForm extends Component {
  constructor(props) {
    super(props);
    this.card_element = null;
    this.state = {
      validated: false,
      show_terms_and_conditions: false,
      captcha_confirmed: false,
      captcha_error: false
    };
  }

  componentWillUnmount = () => {
    this.unmounted = true;
  }

  submit = () => {
    this.props.form.validateFields(async (err, values) => {
      if (!this.state.captcha_confirmed) {
        this.setState({ captcha_error: true });
        return true;
      }

      if (err || this.state.captcha_error)
        return true;

      this.props.setLoading(true);
      if (values.payment_method === "credit_card")
      {
        let { token } = await this.props.stripe.createToken({ name: "Name" });
        if (token === undefined) {
          this.props.setLoading(false);
          return true;
        }
        values.token_id = token.id;
        values.terms_checked = this.props.termsAndConditions.id;

        if (await this.props.creditCardSignup(values)) {
          this.props.form.resetFields();
        }
      }
      else if (values.payment_method === "paypal")
      {
        let data = {
          subscription_type_id: values.subscription_type_id,
          username: values.username,
          email: values.email
        }
        if (await this.props.validate(data))
          this.setState({ validated: true });
        this.props.setLoading(false);
      }
      else if (this.props.trial)
      {
        values.terms_checked = this.props.termsAndConditions.id;
        if (await this.props.trialSignup(values)) {
          this.props.form.resetFields();
        }
      }
    });
  }

  checkPasswords = (rule, value, callback) => {
    if (value && value !== this.props.form.getFieldValue("password1"))
      callback('The entered passwords do not match.');
    callback();
  }

  checkPasswordRules = (rule, value, callback) => {
    if (value === undefined || value === "")  //Only display one error at a time
      callback();
    else
    {
      if (value.length < 5)
        callback('Please enter at least five characters for your password');
      if (! /[A-Z]/.test(value))
        callback('Please ensure that your password has at least one upper-case letter in it');
      if (! /[0-9]/.test(value))
        callback('Please ensure that your password has at least one number in it')
      callback();
    }
  }

  checkEmail = async (rule, value, callback) => {
    let result = await this.props.validate({ email: value, check_once: true });
    if (!result)
      callback('Sorry, but that email has already been taken');
    callback();
  }

  checkEmailsMatch = (rule, value, callback) => {
    if (value && value !== this.props.form.getFieldValue("email"))
      callback('The entered email addresses do not match.');
    callback();
  }

  checkUsername = async (rule, value, callback) => {
    let result = await this.props.validate({ username: value, check_once: true });
    if (!result)
      callback('Sorry, but that username has already been taken');
    callback();
  }

  checkVoucher = async(rule, value, callback) => {
    if (value !== undefined && value !== "" && this.props.voucher === null)
      callback("Press apply to use this voucher before making your payment");
    callback();
  }

  set_medical_school = () => {
    this.props.form.resetFields(['state', 'state_id']);
    if (this.props.form.getFieldValue("medical_school_id") !== undefined && this.props.form.getFieldValue("medical_school_id") !== "other")
      this.props.form.resetFields("medical_school_id");
  }

  applyVoucher = async () => {
    await this.props.applyVoucher(this.props.form.getFieldValue("voucher"));
    if (this.props.voucher !== null)
      this.props.form.setFieldsValue({ voucher: this.props.form.getFieldValue("voucher")});
  }

  getFacebookDetails = (response) => {
    if (response.first_name !== undefined)
      this.props.form.setFieldsValue({ first_name: response.first_name });
    if (response.last_name !== undefined)
      this.props.form.setFieldsValue({ last_name: response.last_name });
    if (response.email !== undefined)
      this.props.form.setFieldsValue({ email: response.email });
    /*if (response.birthday !== undefined)
      this.props.form.setFieldsValue({ dob: moment(response.birthday) })*/
  }

  payPalSignup = (response) => {
    this.props.setLoading(true);
    if (response.paid === true) {
      let data = this.props.form.getFieldsValue();
      data.paypal_reference = response.paymentID;
      data.terms_checked = this.props.termsAndConditions.id
      this.props.payPalSignup(data);
    }
  }

  freeSub = async () => {
    this.props.setLoading(true);
    this.props.freeSubscription(this.props.form.getFieldsValue());
  }

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    if (this.props.countries === null || this.props.medical_schools === null || this.props.subscription_types === null)
      return <Spin />

    let country_id = this.props.form.getFieldValue("country_id");
    let country = null;
    if (country_id !== undefined)
      country = this.props.countries.find(country => country.id === country_id);

    let cost = calculateTotalCost(this.props.voucher, this.props.subscription_types.find(type => type.id === this.props.form.getFieldValue("subscription_type_id")))
    if (cost === "0.00" && this.props.form.getFieldValue("payment_method") === "paypal")
      this.props.form.resetFields("payment_method");
    let years = [];
    for (let i = new Date().getFullYear(); i < 2100; i ++)
      years.push(i);

    let currency_symbol = paypal_currency == "GBP" ? "£" : "$";

    return (
      <div>
        <Spin spinning={this.props.loading}>
          <Modal
            title="Terms and Conditions"
            visible={this.state.show_terms_and_conditions}
            onOk={() => this.setState({show_terms_and_conditions: false})}
            cancelButtonProps={{ style: {display: "none"}}}
            width="80%"
          >
            <div dangerouslySetInnerHTML={{ __html: this.props.termsAndConditions.terms}} />
          </Modal>

          {this.props.signupSuccess ? <Redirect to={{ pathname: "/login" }} /> : ""}
          <Form className="signup-form" style={{ display: this.state.validated ? "none" : "block"}}>
                <Row gutter={{ xs: 0, sm: 60 }}>
                <Col xs={24} md={12}>
                    <h2 style={{ color: '#58677F' }}>Create an Account</h2>

                    <Divider />

                    <FacebookLogin
                      appId={facebookAppId}
                      autoLoad={true}
                      fields="first_name,last_name,email"
                      scope="public_profile,email"
                      callback={this.getFacebookDetails}
                      textButton={<span><Icon type='facebook' theme='filled' /> <b>Sign Up with Facebook</b></span>}
                      size="small"
                      cssClass="fb-button" />

                    <p>If you have signed up with OSCEbank before, please login to your account and purchase
                      a new subscription from there.</p>

                    <FormItem label="First Name">
                    {getFieldDecorator("first_name", {
                        rules: [{ required: true, message: "First Name is required" }]
                    })(<Input
                        size="large"
                    />)}
                    </FormItem>
                    <FormItem label="Surname">
                    {getFieldDecorator("last_name", {
                        rules: [{ required: true, message: "Surname is required" }]
                    })(<Input
                        size="large"
                    />)}
                    </FormItem>
                    <FormItem label="Date of Birth">
                    {getFieldDecorator("dob", {
                        rules: [{ required: true, message: "Date of Birth is required" }]
                    })(<DatePicker size="large" format={"DD/MM/YYYY"} />)}
                    </FormItem>
                    <FormItem label="Email">
                    {getFieldDecorator("email", {
                        rules: [{ required: true, message: "Email is required" }, { type: "email", message: "Please enter a valid email address"}, { validator: this.checkEmail }]
                    })(<Input
                        size="large"
                    />)}
                    </FormItem>
                    <FormItem label="Confirm Email">
                    {getFieldDecorator("email_confirm", {
                        rules: [{ validator: this.checkEmailsMatch }]
                    })(<Input
                        size="large"
                    />)}
                    </FormItem>
                    <FormItem label="Country">
                    {getFieldDecorator("country_id", {
                        rules: [{ required: true, message: "Country is required" }]
                    })(<Select
                          placeholder="Please Select"
                          size="large"
                          onChange={this.set_medical_school}
                          showSearch
                          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {this.props.countries.map(value => {
                            return <Select.Option value={value.id} key={"country-"+value.id}>{value.name}</Select.Option>
                            })}
                        </Select>)}
                    </FormItem>
                    { country === null || country.states.length === 0 ?
                        <FormItem label="State/Province">
                        {getFieldDecorator("state", {})(
                        <Input
                            size="large"
                        />)}
                        </FormItem> :
                        <FormItem label="State/Province">
                        {getFieldDecorator("state_id", {
                            rules: [{required: true, message: "State is required" }]
                        })(
                            <Select
                              placeholder="Please Select"
                              size="large"
                              showSearch
                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {country.states.map(value => {
                                  return <Select.Option value={value.id} key={"state-" + value.id}>{value.name}</Select.Option>
                                })}
                            </Select>)}
                        </FormItem>}
                    <FormItem label="Medical School">
                    {getFieldDecorator("medical_school_id", {
                        rules: [{ required: true, message: "Medical School is required" }]
                    })(
                        <Select
                        placeholder="Please Select"
                        size="large"
                        showSearch
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {this.props.medical_schools.map(value => {
                            if (country_id !== undefined && country_id === value.country_id)
                                if (this.props.form.getFieldValue("state_id") !== undefined)
                                {
                                if (this.props.form.getFieldValue("state_id") === value.state_id)
                                    return <Select.Option value={value.id} key={"medical_school-" + value.id}>{value.name}</Select.Option>
                                }
                                else
                                return <Select.Option value={value.id} key={"medical_school-" + value.id}>{value.name}</Select.Option>
                            return null;
                            })}
                            <Select.Option value="other">Other</Select.Option>
                        </Select>)}
                    </FormItem>
                    {this.props.form.getFieldValue("medical_school_id") === "other" ?
                        <FormItem label="Medical School Name">
                        {getFieldDecorator("medical_school_other", {
                            rules: [{ required: true, message: "Medical School Name is required" }]
                        })(
                            <Input
                            size="large"
                            />)}
                        </FormItem>
                    : ""}

                    <FormItem label="Graduation Year (expected)">
                      {getFieldDecorator("graduation_year", {
                        rules: [{ required: true, message: "Graduation Year is required" }]
                      })(
                        <Select
                          placeholder="Please Select"
                          size="large"
                          showSearch>
                          {years.map((value, index) => {
                            return <Select.Option value={value} key={"year-" + index}>{value}</Select.Option>
                          })}
                        </Select>)}
                    </FormItem>

                    <Divider />

                    <FormItem label="Create Username">
                    {getFieldDecorator("username", {
                        rules: [{ required: true, message: "Username is required" }, { validator: this.checkUsername }]
                    })(<Input
                        size="large"
                    />)}
                    </FormItem>
                    <FormItem label="Create Password">
                    {getFieldDecorator("password1", {
                        rules: [{ required: true, message: "Password is required" }, { validator: this.checkPasswordRules }]
                    })(<Input.Password
                        size="large"
                    />)}
                    </FormItem>
                    <FormItem label="Repeat Password">
                    {getFieldDecorator("password2", {
                        rules: [{ required: true, message: "Repeat password is required" }, { validator: this.checkPasswords }]
                    })(<Input.Password
                        size="large"
                    />)}
                    </FormItem>
                </Col>

                <Col xs={24} md={12} className="subscriptions-col">
                    <h2 style={{ color: '#58677F' }}>Select a Subscription</h2>

                    <Divider />

                    <FormItem className="subscription-select">
                        {getFieldDecorator("subscription_type_id", {
                            rules: [{ required: true, message: "Please choose a subscription type" }],
                            initialValue: this.props.subscription_types.length === 1 ? this.props.subscription_types[0].id : this.props.selectedPlan !== null ? this.props.selectedPlan : null
                        })(
                            <div className="subscriptions">
                                {this.props.subscription_types.map((value, index) => {
                                    return <Card key={"subscription-select-" + index}
                                                className={this.props.form.getFieldValue("subscription_type_id") === value.id ? "selected" : ""}
                                                onClick={() => this.props.form.setFieldsValue({ subscription_type_id: value.id })}>
                                                {this.props.form.getFieldValue("subscription_type_id") === value.id &&
                                                    <Button shape="circle" icon="check" size="large" className="circle-btn button-selected"></Button>
                                                }

                                                <h1>{value.name}</h1>
                                                <Divider/>
                                                <p className="cost">
                                                    {displayPrice(value)}
                                                    <span className="time-period">/ {value.display_length}</span>
                                                </p>
                                                <div className="description" dangerouslySetInnerHTML={{ __html: value.description }} />
                                            </Card>
                                })}
                            </div>)}
                    </FormItem>

                    <Divider />

                    {!this.props.trial ?
                        <Form.Item className="voucher">
                            <Form.Item style={{ display: 'inline-block', width: 'calc(70% - 5px)' }}>
                                {getFieldDecorator("voucher", {
                                rules: [{ validator: this.checkVoucher }]
                                })(
                                    <Input
                                        size="large"
                                        placeholder="Voucher Code"
                                    />)}
                            </Form.Item>
                            <Button
                                type="primary"
                                onClick={this.applyVoucher}
                                size="large"
                                id="apply-voucher-button"
                            >
                                Apply
                            </Button>
                        </Form.Item> : ""}

                    { cost !== null && this.props.voucher !== null &&
                        (cost === this.props.subscription_types.find(type => type.id === this.props.form.getFieldValue("subscription_type_id")).cost || cost === this.props.subscription_types.find(type => type.id === this.props.form.getFieldValue("subscription_type_id")).discounted_cost)
                        ? <p className="success"><span>Sorry, but the voucher you entered cannot be used with this subscription type. Please select another subscription type.</span></p>
                        : cost !== null && this.props.voucher !== null
                        ? <p className="success"><Icon type="check-circle" theme="twoTone" /><span>Voucher has been successfully applied. <br /> Your new subscription cost is <b>{currency_symbol}{cost}</b>.</span></p>
                        : ""}

                    <FormItem>
                      {getFieldDecorator("terms_and_conditions", {
                          rules: [{ required: true, message: "You must agree to the terms and conditions to continue" }]
                      })(<div><Checkbox value={this.props.termsAndConditions.id}>I agree to the Terms and Conditions</Checkbox> <span style={{ color: "#1890ff" }} onClick={() => this.setState({ show_terms_and_conditions: true })}>Show</span></div>)}
                    </FormItem>

                    <FormItem>
                      {getFieldDecorator("email_opt_in")(<div><Checkbox value="1">I agree to receive occasional emails regarding exclusive discounts and important updates from OSCEbank</Checkbox></div>)}
                    </FormItem>

                    <Divider />

                    {!this.props.trial ?
                        <div>
                            {!this.props.promotion || cost !== "0.00" ?
                              <div>
                                <h3>Payment Method</h3>
                                <FormItem>
                                  {getFieldDecorator("payment_method", {
                                      rules: [{ required: true, message: "Please select how you are paying for your subscription" }]
                                  })(<Radio.Group>
                                      <Radio value="credit_card">Credit Card</Radio>
                                      {cost !== "0.00" ? <Radio value="paypal">PayPal</Radio> : ""}
                                  </Radio.Group>)}
                                </FormItem>
                              </div>
                            : ""}

                            {this.props.form.getFieldValue("payment_method") === "credit_card" ?
                            <div>
                                <h3>Please enter your Card Details</h3>
                                {cost === "0.00" ? <p>Although your subscription cost comes to {currency_symbol}0, we require your credit card details for verification purposes.
                                Your card details will not be used to auto-renew a subscription.</p> :
                                <p>Your card details will <b>not</b> be used to auto-renew a subscription - only to purchase this one.</p>}
                                <CardElement onReady={(c) => this.card_element = c} />
                            </div>
                            :  this.props.form.getFieldValue("payment_method") === "paypal" && !this.state.validated ?
                                <p>You will be able to make your PayPal payment after you have successfully validated your signup</p>
                            : ""}
                        </div> : "" }

                    <div className="captcha-container" style={{ margin: '1em 0' }}>
                        <ReCAPTCHA
                            sitekey={recaptchaKey}
                            onChange={() => this.setState({ captcha_confirmed: true, captcha_error: false })}
                            onExpired={() => this.setState({ captcha_confirmed: false })}
                        />
                        {this.state.captcha_error ? <div className="has-error"><span className="ant-form-explain">Please prove that you are not a robot.</span></div> : ""}
                    </div>

                    {!this.props.promotion || cost !== "0.00" ?
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={hasErrors(getFieldsError()) || this.state.validated}
                            onClick={this.submit}
                            icon="check"
                            size="large"
                            block
                            className="signup-btn"
                            >
                              {!this.props.trial ? "Sign Up and Pay" : "Activate Trial"}
                        </Button>
                      : <Button
                        type="primary"
                        htmlType="submit"
                        disabled={hasErrors(getFieldsError()) || this.state.validated}
                        onClick={this.freeSub}
                        icon="check"
                        size="large"
                        block
                        className="signup-btn"
                      >
                        Activate Subscription
                      </Button>}
                </Col>
            </Row>
        </Form>
          {/*We need to have this here but hidden because otherwise you need to click on it twice to bring up PayPal*/}
          <div id="paypal-payment" style={{ display: this.props.form.getFieldValue("payment_method") === "paypal" && this.state.validated ? "block" : "none" }}>
            <p className="success">Please complete your purchase in order to finalise signing up</p>
            <div style={{display: parseFloat(cost) > 0 ? "block" : "none"}}>
              {!this.props.signupSuccess ?
                <PaypalExpressBtn env={paypal_env}
                  client={paypal_client}
                  currency={paypal_currency}
                  total={parseFloat(cost)}
                  onSuccess={this.payPalSignup}
                  onCancel={() => console.log("cancelled")}
                  onError={(err) => console.log(err)}
                  style={{size: "large"}} /> : ""}
            </div>
            {parseFloat(cost) <= 0 ?
              <div>
                <p>The cost of your subscription comes to {currency_symbol}0, so it cannot be processed by PayPal. Please click the button below to complete your signup.</p>
                <Button type="primary" onClick={() => this.payPalSignup({paid: true, paymentID: null})}>Complete Sign Up</Button>
              </div>
            : ""}

            <p onClick={() => this.setState({ validated: false })} style={{ color: "#1890ff", marginTop: "1em"}}>To change a detail of your signup click here</p>
          </div>
        </Spin>
      </div>
    )
  }
}
function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

export default injectStripe(Form.create({ name: 'signup' })(SignupForm));
