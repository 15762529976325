import React, { Component } from "react";
import { connect } from "react-redux";
import { APITokenless } from '../../actions/API.js';
import { Link } from "react-router-dom";
import { Skeleton, Button, Icon, Tag } from "antd";
import MetaTags from 'react-meta-tags';

const base_url = process.env.REACT_APP_IMAGE_ENDPOINT + "blog/";

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blog: null,
      loading: true
    }
  }

  componentDidMount = async () => {
    let slug = this.props.match.params.slug;
    let blog = await this.props.dispatch(APITokenless("/blogs/" + slug, "GET"));
    if (!this.unmounted)
      this.setState({ blog, loading: false });
  }

  componentWillUnmount = () => {
    this.unmounted = true;
  }

  render() {
        let blog = this.state.blog;
        if (this.state.loading)
            return <div>
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
            </div>
        return  <div id="blog">
                    <MetaTags>
                        <title>{blog.title} | OSCEbank</title>
                        <meta name="title" content={blog.title} />
                        <meta name="keywords" content={blog.meta_keywords} />
                        <meta name="description" content={blog.meta_description} />
                    </MetaTags>
                    <div className={`container fixed`}>
                        <div className="post-content">
                            <div className="post-header">
                                <h1>{this.state.blog.title}</h1>
                                <Link to="/blog">
                                    <Button><Icon type="left" />Back to all posts</Button>
                                </Link>
                            </div>
                            {blog.image !== "" && blog.image !== null && blog.image !== undefined ?
                            <img className="post-featured-img" src={base_url + blog.id + "/" + blog.image} alt={blog.title + " feature image"} /> : ""}
                            <div className="blog-content" dangerouslySetInnerHTML={{ __html: this.state.blog.content}} />

                            <div className="blog-tags">

                            </div>
                        </div>
                    </div>
                </div>
  }
}

export default connect()(Blog);