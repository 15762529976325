import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Icon, Input, Select, Divider, Spin, message } from "antd"
import { API } from "../../actions/API"

class ControlCandidates extends Component {

  constructor(props) {
    super(props)

    this.state = {
      search: "",
      candidates: [],
      searching: false
    }
  }

  removeCandidate = (id, group) => {
    this.props.removeCandidate(id, group);
  }

  search = async (value) => {
    if (value.length < 1)
      return true;
    this.setState({ searching: true });
    let user = await this.props.dispatch(API("/user/find_by_id/" + value));
    if (user.success)
      this.setState({ candidates: [user.user] });
    this.setState({ searching: false });
  };

  setCandidate = (selected) => {
    if (selected.length < 1)
    {
      this.props.removeCandidate(null, false);
      this.setState({ candidates: [] })
    }
    else
      this.props.addCandidate({ id: selected[0].key, first_name: selected[0].label })
  }

  render() {
    if (this.props.role !== "examiner")
      return "";

    return (
      <Select
        mode="multiple"
        style={{ width: '100%' }}
        labelInValue
        notFoundContent={this.state.searching ? <Spin size="small" /> : null}
        placeholder="Add Candidate(s)..."
        filterOption={false}
        onSearch={(value) => this.search(value)}
        onChange={(selected) => this.setCandidate(selected)}
      >
        {this.state.candidates.map(candidate => (
          <Select.Option key={candidate.id}>{candidate.first_name}</Select.Option>
        ))}
      </Select>
    )
  }
}

export default connect(state => {
  return {
    members: state.members,
    role: state.role
  }
})(ControlCandidates);