import React, { Component } from "react";
import { connect } from "react-redux";
import { APITokenless } from '../../actions/API.js';
import { Spin } from "antd";
import Station from "../Station/Station";
import RoleChooser from "../Partials/RoleChooser";
import MetaTags from 'react-meta-tags';

class PreviewPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      role: null,
      station: null,
      station_components: null
    }
  }

  componentDidMount = async () => {
    let preview = await this.props.dispatch(APITokenless("/preview", "GET"));
    let station = null;
    let station_components = null;
    if (preview.length !== 0) {
      station = preview.station;
      station_components = preview.station_components;
    }
    if (!this.unmounted)
      this.setState({ loading: false, station, station_components });
  }

  componentWillUnmount = () => {
    this.unmounted = true;
  }

  chooseRole = () => {
    this.setState({ role: localStorage.getItem("role") });
  }

  render() {
    let content = <Spin />;
    if (!this.state.loading && this.state.station === null)
      content = <div className={`container fixed`}>
                    <div className="preview-heading">
                        <h1>Preview Station for Free</h1>
                        <p>It looks like there aren't any preview stations available right now. Please try again later.</p>
                    </div>
                </div>
    else if (!this.state.loading && this.state.role === null)
      content = <div className={`container fixed`}>
                    <div className="preview-heading">
                        <h1>Preview Station for Free</h1>
                        <p className="heading">Please select from the roles below to start a free OSCE station</p>
                    </div>
                    <RoleChooser selected={this.chooseRole} />
                </div>;
    else if (!this.state.loading)
      content = <Station preview={true} station={this.state.station} components={this.state.station_components} userRole={this.state.role} />

    return (
        <div id="preview">
            <MetaTags>
                <title>Preview | OSCEbank</title>
            </MetaTags>

            {content}
        </div>
    )
  }
}

export default connect()(PreviewPage);