import React, { Component } from "react";
import { connect } from "react-redux";
import { Spin, message } from "antd";
import { push } from "react-router-redux";
import { API } from '../../actions/API.js';
import { logoutUser } from "../../actions/auth";
import { end } from "../../actions/Echo"
import { setPlaylist, setRole, setFilters, setHideStationNames } from "../../constants/ActionTypes"

class LogoutPage extends Component {

  componentDidMount = async () => {
    await this.props.dispatch(API("/logout", "GET"));
    message.success("You have successfully logged out");
    this.props.dispatch(end());
    this.props.dispatch(logoutUser());
    this.props.dispatch(setRole(null));
    this.props.dispatch(setFilters({}));
    this.props.dispatch(setHideStationNames(false));
    if (this.props.playlist !== null && this.props.playlist.length > 0)
      this.props.dispatch(setPlaylist(null));
    this.props.dispatch(push("/"));
  }

  render() {
    return (
      <div>
        <Spin className="loading" />
      </div>
    )
  }
}

export default connect(state => {
  return {
    playlist: state.playlist
  }
})(LogoutPage);