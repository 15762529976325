import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Card, Button, Divider } from "antd";
import { displayPrice } from "../../actions/functions"

import subscription from "../../static/images/subscription.svg";
const currency = process.env.REACT_APP_CURRENCY;

class SubscriptionDisplay extends Component {
  render() {
    return (
      this.props.plans.map((value, index) => {
        return <Card key={"subscription-select-" + index} className={value.name == "Free Trial" ? "trial" : ""}>
                <div className="subscribe-circle">
                  <img src={subscription} alt={value.name} />
                  <h1>{value.name}</h1>
                </div>
                <Divider/>
                <p className="cost">
                    {displayPrice(value)}
                    <span className="time-period">/ {value.display_length}</span>
                </p>
                <div className="description" dangerouslySetInnerHTML={{ __html: value.description }} />

                <Link to={value.name !== "Free Trial" ? "/signup?plan=" + value.id : "/free-trial"}>
                    <Button type="primary" size="large">Get Started</Button>
                </Link>
            </Card>
      })
    )
  }
}

export default connect()(SubscriptionDisplay);