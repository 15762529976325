import React, { Component } from "react";
import { connect } from "react-redux";
import { APITokenless } from '../../actions/API.js';
import { Skeleton } from "antd";
import SubscriptionDisplay from "../Partials/SubscriptionDisplay";
import MetaTags from 'react-meta-tags';
import ReactPixel from 'react-facebook-pixel';

const country = process.env.REACT_APP_COUNTRY

class SubscriptionPlansPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: null,
      plans: null
    }
  }

  componentDidMount = async () => {
    let data = await this.props.dispatch(APITokenless("/subscription_plans?country=" + country, "GET"));
    if (!this.unmounted)
      this.setState({ content: data.content !== null ? data.content : false, plans: data.plans });

    ReactPixel.pageView();
  }

  componentWillUnmount = () => {
    this.unmounted = true;
  }

  render() {
    return (
        <div id="plans-pricing">
            <div className={`container fixed`}>
                {this.state.content === null ?
                    <div>
                        <Skeleton active />
                        <Skeleton active />
                        <Skeleton active />
                    </div>
                    : this.state.content === false ?
                        <p>The page data for subscription plans has not been added yet</p>
                    : <div>
                        <MetaTags>
                            <title>{this.state.content.title} | OSCEbank</title>
                            <meta name="title" content={this.state.content.meta_title} />
                            <meta name="keywords" content={this.state.content.meta_keywords} />
                            <meta name="description" content={this.state.content.meta_description} />
                        </MetaTags>

                        <h1 dangerouslySetInnerHTML={{ __html: this.state.content.title }}></h1>
                        <p dangerouslySetInnerHTML={{ __html: this.state.content.content }}></p>
                        <div className="subscriptions">
                            <SubscriptionDisplay plans={this.state.plans} />
                        </div>
                    </div>
                }
            </div>
        </div>
    )
  }
}

export default connect()(SubscriptionPlansPage);