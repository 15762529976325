import React from "react";
import { API } from './API.js';
import candidate from "../static/images/candidate-icon.svg";
import observer from "../static/images/observer-icon.svg";
import patient from "../static/images/patient-icon.svg";
import examiner from "../static/images/examiner-icon.svg";

const currency = process.env.REACT_APP_CURRENCY;

export const generateStem = (station_components, timing) => {
  let content = "<b>THIS IS ";
  let first_type = station_components.types[0];
  if (first_type[0] === "A" || first_type[0] === "E" || first_type[0] === "I" || first_type[0] === "O" || first_type[0] === "U")
    content += "AN ";
  else
    content += "A ";

  station_components.types.map((value, index) => {
    if (index === station_components.types.length - 1 && station_components.types.length > 1)
      content += "AND " + value.toUpperCase() + " ";
    else
      content += value.toUpperCase();

    if (index !== (station_components.types.length - 1) && index !== (station_components.types.length - 2))
      content += ", ";
    else
      content += " ";

    return true;
  });

  content += "STATION</b><br>";

  if (timing && !timing.disabled)
    content += "You have <b>" + (parseInt(timing.original_station, 10) + parseInt(timing.original_question, 10)) + " minutes</b> to complete this station.<br>";

  content += station_components.stem.content;

  content += "<br>Tasks:<ol>";
  station_components.stem_tasks.forEach(task => {
    content += "<li>" + task.task + " ";
    if (timing && !timing.disabled)
    {
      switch (task.station_area)
      {
        case "reading":
          let minutes = (timing.original_reading / station_components.stem_tasks.filter(task => task.station_area === "reading").length);
          if (!Number.isInteger(minutes))
            minutes = minutes.toFixed(1);
          content += "(" + minutes + (minutes === 1 ? " minute" : " minutes") + ")</li>";
          break;
        case "station":
          minutes = (timing.original_station / station_components.stem_tasks.filter(task => task.station_area === "station").length);
          if (!Number.isInteger(minutes))
            minutes = minutes.toFixed(1);
          content += "(" + minutes + (minutes === 1 ? " minute" : " minutes") + ")</li>";
          break;
        case "question":
          minutes = (timing.original_question / station_components.stem_tasks.filter(task => task.station_area === "question").length);
          if (!Number.isInteger(minutes))
            minutes = minutes.toFixed(1);
          content += "(" + minutes + (minutes === 1  ? " minute" : " minutes") + ")</li>";
          break;
        case "feedback":
          minutes = (timing.original_feedback / station_components.stem_tasks.filter(task => task.station_area === "feedback").length);
          if (!Number.isInteger(minutes))
            minutes = minutes.toFixed(1);
          content += "(" + minutes + (minutes === 1 ? " minute" : " minutes") + ")</li>";
          break;
        case "station/questions":
          content += "(" + ((parseInt(timing.original_question, 10) + parseInt(timing.original_station, 10)) / station_components.stem_tasks.filter(task => task.station_area === "station/questions").length) + " minutes)</li>";
          break;
        default:
          break;
      }
    }
    else
      content += "</li>";
  });

  content += "</ol>";

  content += "<b>Please read the tasks carefully. They will be available to view during the station.</b>";

  return content;
}

export const getUser = async (props, completed = false) => {
  let user = await props.dispatch(API("/user?completed=" + completed, "GET"));
  return user;
}

export const getRoleDetails = (role) => {
  let role_name = null
  let img = null;

  if (role === null)
    return null;

  switch (role) {
    case "candidate-1":
      role_name = "Solo Candidate";
      img = candidate;
      break;
    case "candidate-2":
      role_name = "Candidate";
      img = candidate;
      break;
    case "examiner":
      role_name = "Examiner";
      img = examiner;
      break;
    case "patient":
      role_name = "Patient";
      img = patient;
      break;
    case "observer":
      role_name = "Observer";
      img = observer;
    default:
      break;
  }

  return { role, role_name, img }
}

export const filterStations = (stations, filters) => {
  let filtered_stations = [];

  //No filters? Return everything
  if ((filters.completed_filters === undefined || filters.completed_filters.length < 1) && (filters.name === undefined || filters.name === "") && (Object.keys(filters.filters).length < 1)) {
    if (filters.random && stations.length > 0)
      return [stations[Math.floor((Math.random() * stations.length))]];
    return stations;
  }

  //Filter on name/number
  if (filters.name !== undefined && filters.name !== "") {
    filtered_stations = stations.filter((station) => {
      return station.name.toLowerCase().includes(filters.name.toLowerCase()) || station.number.toString().includes(filters.name.toLowerCase())
    });

    stations.forEach((station) => {   //Search on specialties to see whether that matches what we entered
      station.stationTaxons.specialties.forEach((specialty) => {
        if (specialty.specialty.toLowerCase().includes(filters.name.toLowerCase()) && filtered_stations.filter(filtered_station => filtered_station.id === station.id).length < 1)
          filtered_stations.push(station);
      });
    });
    if (((filters.completed_filters === undefined || filters.completed_filters.length < 1) && Object.keys(filters.filters).length < 1)) {
      if (filters.random && filtered_stations.length > 0)
        return [filtered_stations[Math.floor((Math.random() * stations.length))]];
      return filtered_stations;
    }
  }

  stations.forEach((station) => {
    //Filter levels
    let exists = filters.filters.levels === undefined || filters.filters.levels.length === 0;
    station.stationTaxons.levels.forEach((level) => {
      if (filters.filters.levels !== undefined && filters.filters.levels.indexOf(level.id) > -1)
        exists = true;
    });

    if (!exists)
      return;

    //Filter difficulty
    exists = filters.filters.difficulties === undefined || filters.filters.difficulties.length === 0;
    station.stationTaxons.difficulties.forEach((difficulty) => {
      if (filters.filters.difficulties !== undefined && filters.filters.difficulties.indexOf(difficulty.id) > -1)
        exists = true;
    });

    if (!exists)
      return;

    //Filter type
    exists = filters.filters.types === undefined || filters.filters.types.length === 0;
    station.stationTaxons.types.forEach((type) => {
      if (filters.filters.types !== undefined && filters.filters.types.indexOf(type.id) > -1)
        exists = true;
    });

    if (!exists)
      return;

    //Filter specialty
    exists = filters.filters.specialties === undefined || filters.filters.specialties.length === 0;
    station.stationTaxons.specialties.forEach((specialty) => {
      if (filters.filters.specialties !== undefined && filters.filters.specialties.indexOf(specialty.id) > -1)
        exists = true;
    });

    if (!exists)
      return;

    //Filter systems
    exists = filters.filters.systems === undefined || filters.filters.systems.length === 0;
    station.stationTaxons.systems.forEach((system) => {
      if (filters.filters.systems !== undefined && filters.filters.systems.indexOf(system.id) > -1)
        exists = true;
    });

    if (!exists)
      return;

    exists = (filters.filters.free_trial !== undefined && station.available_to_trial) || filters.filters.free_trial === undefined;

    if (!exists)
      return;

    if (filters.completed_filters !== undefined && filters.completed_filters.indexOf("starred") > -1)
      exists = station.favourited

    if (exists && filtered_stations.filter(filtered_station => filtered_station.id === station.id).length < 1)
      filtered_stations.push(station);
  });

  //Filter on completed status
  if (filters.completed_filters !== undefined && filters.completed_filters.length > 0) {
    let new_filtered = [];
    filtered_stations.forEach((station) => {
      let keep = filters.completed_filters.filter(filter => filter !== "starred").length < 1;
      filters.completed_filters.filter(filter => filter !== "starred").forEach((value) => {
        if (value === station.status)
          keep = true;
      });
      if (keep)
        new_filtered.push(station);
    });
    filtered_stations = new_filtered;
  }

  if (filters.random && filtered_stations.length > 0)
    return [filtered_stations[Math.floor((Math.random() * filtered_stations.length))]];
  return filtered_stations;
}

export const displayTab = (role, state, tab, show_all) => {
  if (role === "observer" && tab !== "questions")
    return true;
  if (show_all && role === "candidate-1")
    return true;

  switch (tab)
  {
    case "stem":
    case "notes":
        return true;
    case "questions":
      return role === "candidate-1" && (state === "Question Time" || state === "Feedback Time")
    case "marking_guide":
      if ((role === "candidate-1" || role === "candidate-2" || role === "patient") && (state === "Feedback Time"))
        return true;
      else if (role === "examiner")
        return true;
      break;
    case "approach_guide":
      return state === "Feedback Time";
    case "patient_script":
      return (role === "patient" || role === "examiner" || (role === "candidate-1" && state === "Feedback Time"));
    case "control_candidates":
      return role === "examiner"
    default:
      break;
  }

  return false;
}

export const calculateTotalCost = (voucher, subscription) => {
  if (subscription === undefined || subscription === null)
    return null;
  if (voucher === null || subscription.applicable_vouchers.filter(value => value === voucher.id).length < 1)
  {
    if (subscription.discounted_cost > 0)
      return subscription.discounted_cost;
    else
      return subscription.cost;
  }

  let cost = subscription.cost;
  if (subscription.discounted_cost > 0)
    cost = subscription.discounted_cost;

  if (voucher.sign === "%") {
    let discount = voucher.discount / 100;
    cost = (cost - (cost * discount));
  }
  else if (voucher.sign === "$") {
    cost = (cost - voucher.discount);
  }

  cost = parseFloat(cost).toFixed(2);

  if (cost < 0)
    cost = 0;

  return cost;
}

export const detectIOS = () => {
  var iDevices = [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ];

  if (!!navigator.platform) {
    while (iDevices.length) {
      if (navigator.platform === iDevices.pop()) { return true; }
    }
  }

  return false;
}

export const breakUpLabel = (label) => {
  let whole_label = label.split(" ");
  if (whole_label.length < 3)
    return [label];
  let break_up_lines = [];
  let line = "";
  for (let i = 0; i < whole_label.length; i++) {
    line += whole_label[i] + " ";
    if (i % 2 !== 0) {
      break_up_lines.push(line);
      line = "";
    }
  }
  if (line !== "")
    break_up_lines.push(line);
  return break_up_lines;
}

export const displayPrice = (subscription_type) => {
  let currency_symbol = currency == "GBP" ? "£" : "$";

  if (subscription_type.name == "Free Trial")
    return <span><span className="price">{currency_symbol}{subscription_type.cost}</span></span>
  if (subscription_type.discounted_cost > 0)
    return <span className="price"><span className="discounted-price">{currency_symbol}{subscription_type.cost}</span> {currency_symbol}{subscription_type.discounted_cost}<span className="currency"> {currency !== "GBP" ? currency : ""}</span></span>

  return <span><span className="price">{currency_symbol}{subscription_type.cost}</span><span className="currency"> {currency !== "GBP" ? currency : ""}</span></span>
}