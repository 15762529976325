import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, Icon, Row, Col } from "antd";
import { beginStation, broadcastPlaylistSuggestion } from "../../../actions/Echo"
import { setPlaylist } from "../../../constants/ActionTypes"
import PlaylistItem from "./PlaylistItem";

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",

  ...draggableStyle
});

class Playlist extends Component {
  startPlaylist = () => {
    if (this.props.groupStudy && this.props.role === "examiner")
      this.props.dispatch(beginStation(this.props.playlist.items[0].number))
    this.props.dispatch(push("/station/" + this.props.playlist.items[0].number));
    this.props.dispatch(setPlaylist(this.props.playlist));
  }

  suggestPlaylist = () => {
    this.props.dispatch(broadcastPlaylistSuggestion(this.props.playlist))
  }

  render() {
    if (this.props.playlist.items.length < 1)
      return "";

    return <div className="playlist">
            <Row className={'playlist-title ' + (this.props.open ? 'active' : '')} type="flex" align="middle" justify="space-between">
              <Col className="playlist-name" onClick={() => this.props.toggle()}>{this.props.playlist.name}</Col>
              <Col className="actions">
                <Button type="link" size="small" icon={this.props.open ? 'up-circle' : 'down-circle'} className="toggle-playlist" onClick={() => this.props.toggle()} />
                <Button size="small" shape="circle" icon="edit" onClick={() => this.props.editName()} />
                <Button type="danger" size="small" shape="circle" icon="delete" className="delete" onClick={() => this.props.delete()} />
              </Col>
            </Row>
            {this.props.open ?
              <div>
                <Droppable droppableId={this.props.playlist.id} type="playlist-items">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {this.props.playlist.items.map((value, index) => {
                        return <Draggable key={"playlist-item-draggable-" + index + "-" + value.id} draggableId={"draggable-item-" + index + "-" + value.id} index={index} playlist={this.props.playlist.id}>
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <PlaylistItem station={value} key={"playlist-item-" + index} delete={(item_id) => this.props.deleteFromPlaylist(item_id)} index={index} role={this.props.role} />
                                  </div>
                                )}
                              </Draggable>
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>

                <div className="playlist-footer">
                  {this.props.groupStudy && this.props.role != "examiner" ?
                    <Button className="playlist-button" type="primary" onClick={() => this.suggestPlaylist()}>Suggest <Icon type="message" /></Button>
                  : this.props.groupStudy ? <div>
                                              <Button className="playlist-button" type="primary" onClick={() => this.suggestPlaylist()}>Suggest <Icon type="message" /></Button>
                                              <Button className="playlist-button" type="primary" onClick={() => this.startPlaylist()}>Start Playlist <Icon type="right" /></Button>
                                            </div>
                  : <Button className="playlist-button" type="primary" onClick={() => this.startPlaylist()}>Start Playlist <Icon type="right" /></Button>}
                </div>
              </div> : ""}
          </div>
  }
}
export default connect(state => {
  return {
    groupStudy: state.groupStudy !== null,
    role: state.role
  }
})(Playlist);