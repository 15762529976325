import React, { Component } from "react";
import { connect } from "react-redux";
import { message, Spin } from "antd";
import { API, APITokenless } from '../../actions/API.js';
import ContactForm from "./ContactForm";
import MetaTags from 'react-meta-tags';

class ContactPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    }
  }

  componentWillUnmount = () => {
    this.unmounted = true;
  }

  submit = async (values) => {
    this.setState({loading: true});
    if (localStorage.getItem("token") !== null)
      await this.props.dispatch(API("/message/user-contact", "POST", values));
    else
      await this.props.dispatch(APITokenless("/message/contact", "POST", values));
    if (!this.unmounted)
      this.setState({loading: false});
    message.success("Your message has been sent to an administrator. You should receive a reply within 24 hours.");
    return true;
  }

  componentWillUnmount = () => {
    this.unmounted = true;
  }

  render() {
    return <div id="contact-page">
        <MetaTags>
            <title>Contact | OSCEbank</title>
        </MetaTags>
        <div className={`container fixed`}>
            <Spin spinning={this.state.loading}>
            <ContactForm
                onSave={this.submit}
            />
            </Spin>
        </div>
    </div>;
  }
}
export default connect()(ContactPage);