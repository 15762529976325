import React, { Component } from "react";
import { Form, Button, Input, Checkbox, Select } from "antd";
import ReCAPTCHA from "react-google-recaptcha";

const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;
class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_select: false,
      loading: false,
      captcha_confirmed: false,
      captcha_error: false
    }
  }

  check = () => {
    if (!this.state.captcha_confirmed)
      this.setState({ captcha_error: true });
    else {
      this.setState({ captcha_error: false });
      this.props.form.validateFields(async (err, values) => {
        if (!err) {
          this.setState({ loading: true });
          await this.props.onSave(values);
          this.props.form.resetFields();
          this.setState({ loading: false });
        }
      });
    }
  }

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    let logged_in = localStorage.getItem("token") !== null;
    return (
      <div>
        <Form className="contact-form">
          <h1 style={{ textAlign: 'center' }}>Contact Us</h1>

          {!logged_in ? <Form.Item label="Name">
            {getFieldDecorator("name", { rules: [{ required: true, message: "Please enter your name" }] })(
              <Input size="large" />)}
          </Form.Item> : ""}
          {!logged_in ? <Form.Item label="Email">
            {getFieldDecorator("email", { rules: [{ required: true, message: "Please enter your email" }, {type: "email", message: "Please enter a valid email address"}] })(
              <Input size="large" />)}
          </Form.Item> : ""}

          <Form.Item label="Nature of Enquiry">
            {getFieldDecorator("enquiry", { rules: [{ required: true, message: "Please enter the nature of your enquiry" }] })(
              <Input size="large" />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("report_issue", {})(
              <Checkbox onChange={() => this.setState({show_select: !this.state.show_select})}>I am reporting an issue</Checkbox>
            )}
          </Form.Item>
          {this.state.show_select ?
            <Form.Item label="Issue Type">
              {getFieldDecorator("issue_type", { rules: [{ required: true, message: "Please select what type of error this is" }] })(
                <Select placeholder="Please select..." size="large">
                  <Select.Option value="error">Error</Select.Option>
                  <Select.Option value="offensive_content">Offensive Content</Select.Option>
                  <Select.Option value="other">Other</Select.Option>
                </Select>)}
            </Form.Item>
          : ""}
          <Form.Item label="Message">
            {getFieldDecorator("message", { rules: [{ required: true, message: "Please give some details about what you'd like to discuss" }] })(
              <Input.TextArea
                placeholder="Enter details here..."
                autosize={{ minRows: 5 }}
              />
            )}
          </Form.Item>

            <div className="captcha-container" style={{ margin: '1em 0' }}>
                <ReCAPTCHA
                    sitekey={recaptchaKey}
                    onChange={() => this.setState({captcha_confirmed: true})}
                    onExpired={() => this.setState({ captcha_confirmed: false })}
                />
                {this.state.captcha_error ? <div className="has-error"><span className="ant-form-explain">Please prove that you are not a robot.</span></div> : ""}
            </div>

          <Button
            type="primary"
            htmlType="submit"
            disabled={this.state.captcha_confirmed !== false && hasErrors(getFieldsError())}
            onClick={this.check}
            icon="check"
            size="large"
            block
            loading={this.state.loading}
            style={{marginTop: "1em"}}
            >
            Submit
          </Button>
        </Form>
      </div>
    );
  }
}

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

export default (Form.create({ name: 'report_an_issue' })(ContactForm));