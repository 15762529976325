import { combineReducers } from "redux";
import { default as auth } from './auth';
import { members, groupStudy, groupUpdateFlag, stations, stationsUpdateFlag, groupPlaylists, groupPlaylistsUpdateFlag, stationReady, currentStation, groupName, groupUniqueId } from './echo'
import { playlist, playlists, playlistIndex } from "./playlist"
import { role, roleFlag } from "./role"
import { filters, hidden_station_names } from "./filters"

export default combineReducers({
  auth,
  members,
  groupStudy,
  groupUpdateFlag,
  stations,
  stationsUpdateFlag,
  groupPlaylists,
  groupPlaylistsUpdateFlag,
  stationReady,
  currentStation,
  groupName,
  groupUniqueId,
  playlist,
  playlists,
  playlistIndex,
  role,
  roleFlag,
  filters,
  hidden_station_names
})