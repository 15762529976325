import React, { Component } from "react";
import { connect } from "react-redux";
import HomePage from "../Pages/HomePage";

class Home extends Component {
  render() {
    let token = localStorage.getItem("token");
    return <HomePage loggedIn={token !== null} />
  }
}

export default connect()(Home);