import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { getUser } from "../../actions/functions";
import { Card, message, Drawer, Modal, Button, Affix, Badge } from "antd";
import { API } from '../../actions/API.js';
import { getRoleDetails, filterStations } from "../../actions/functions";
import { broadcastRole, beginStation, end, clearRole } from "../../actions/Echo"
import { setPlaylist, setRole, roleUpdated, setFilters, setPlaylists, setPlaylistIndex, setHideStationNames } from "../../constants/ActionTypes";
import FiltersForm from "./FiltersForm";
import StationPicker from "./StationPicker";
import Playlists from "./Playlist/Playlists";
import AddItem from "./Playlist/AddItem"
import RoleChooser from "../Partials/RoleChooser";
import SkeletonDisplay from "../Partials/SkeletonDisplay";
import StationSorter from "../Partials/StationSorter";
import GroupStudy from '../Partials/GroupStudy';
import MetaTags from 'react-meta-tags';

const domain = process.env.REACT_APP_API_DOMAIN;

class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      taxons: null,
      all_stations: null,
      filtered_stations: null,
      loading: true,
      search_number: false,
      search_name: false,
      random: false,
      visible: false,
      group_visible: false,
      closable: false,
      mask: false,
      width: 0,
      height: 0,
      show_playlist: false,
      search_term: "",
      display_playlist_modal: false,
      current_station: null,
      open_playlist: null
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  handleOk = (e) => {
    this.setState({
      show_playlist: false,
    });
  }

  handleCancel = (e) => {
    this.setState({
      show_playlist: false,
    });
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  showGroup = () => {
    this.setState({
      group_visible: true
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  closeGroup = () => {
    this.setState({
      group_visible: false
    });
  };

  updateWindowDimensions() {
    if (!this.unmounted)
      this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  chooseRole = (role) => {
    this.props.dispatch(setRole(role))
    this.props.dispatch(roleUpdated(!this.props.roleUpdated))
    this.props.dispatch(broadcastRole(role));
    Modal.destroyAll();
  }

  roleChooserRedirect = (redirect) => {
    Modal.destroyAll();
    this.props.dispatch(push(redirect))
  }

  switchSolo = () => {
    Modal.destroyAll();
    this.props.dispatch(setRole("candidate-1"))
    this.props.dispatch(roleUpdated(!this.props.roleUpdated))
    this.props.dispatch(end("/study"));
  }

  componentDidMount = async() => {
    let user = await getUser(this.props, true);
    if ((this.props.role !== null && this.props.role !== "candidate-1") && !this.props.groupStudy)
    {
      this.props.dispatch(setRole(null))
      this.props.dispatch(roleUpdated(!this.props.roleUpdated))
    }

    let taxons = await this.props.dispatch(API("/taxons", "GET"));
    let all_stations = await this.props.dispatch(API("/stations", "GET"));
    if (!this.unmounted) {
      if (this.props.role === null)
        Modal.warning({
          title: 'Please choose a role before continuing',
          content: <RoleChooser selected={(role) => this.chooseRole(role)} groupStudy={this.props.groupStudy} members={this.props.members} redirect={(redirect) => this.roleChooserRedirect(redirect)} />,
          width: '80%',
          className: 'modal-role-chooser',
          okButtonProps: this.props.groupStudy ? {} : {style: {display: "none"}},
          okText: this.props.groupStudy ? "Switch to Solo Study" : "Switch to Group Study",
          onOk: () => this.switchSolo()
        });
      this.setState({user, taxons, all_stations, loading: false, filtered_stations: all_stations});
      if (Object.keys(this.props.filters).length > 0)
        this.search(this.props.filters);

      if (this.props.playlists == null)
      {
        let playlists = await this.props.dispatch(API("/playlists", "GET"));
        this.props.dispatch(setPlaylists(playlists));
      }
    }

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    document.body.classList.add('overflow-initial');

    if (this.props.groupStudy && this.props.role !== "examiner")
      window.presence.whisper('request-station', {requesting: true});
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateWindowDimensions);
    this.unmounted = true;
  }

  search = (values) => {
    this.props.dispatch(setFilters(values))
    let filtered_stations = filterStations(this.state.all_stations, values);
    this.setState({filtered_stations, visible: false});
  }

  startStation = (number) => {
    if (!this.props.groupStudy)
      this.props.dispatch(push("/station/" + number));
    else
    {
      if (this.props.role === "examiner")
        this.props.dispatch(beginStation(number))
    }
  }

  clearRoles = () => {
    this.props.dispatch(clearRole(false));
    this.props.dispatch(setRole("observer"))
    this.props.dispatch(roleUpdated(!this.props.roleUpdated))
    Modal.destroyAll();
  }

  showRoleChooser = () => {
    Modal.confirm({
      title: 'What would you like your new role to be?',
      content: <RoleChooser selected={(role) => this.chooseRole(role)}
                            groupStudy={this.props.groupStudy}
                            members={this.props.members}
                            redirect={(redirect) => this.roleChooserRedirect(redirect)}
                            clearRoles={() => this.clearRoles()}  />,
      width: '80%',
      className: 'modal-role-chooser',
      okText: this.props.groupStudy ? "Switch to Solo Study" : "Switch to Group Study",
      okButtonProps: { onClick: () => this.props.groupStudy ? this.switchSolo() : this.roleChooserRedirect("/study-groups"), style: this.props.groupStudy ? {} : {display: "none"}}
    });
  }

  sort = (type) => {
    let stations = this.state.filtered_stations;

    switch (type) {
      case "number":
        stations.sort((a, b) => (a.number > b.number) ? 1 : ((b.number > a.number) ? -1 : 0));
        break;
      case "name":
        stations.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        break;
      case "difficulty":
        let easy_stations = [];
        let medium_stations = [];
        let hard_stations = [];
        stations.forEach((value) => {
          let difficulties = value.stationTaxons.difficulties;
          if (difficulties.filter(difficulty => difficulty.difficulty === 'Easy').length > 0)
            easy_stations.push(value);
          else if (difficulties.filter(difficulty => difficulty.difficulty === 'Intermediate').length > 0)
            medium_stations.push(value);
          else if (difficulties.filter(difficulty => difficulty.difficulty === 'Advanced').length > 0)
            hard_stations.push(value);
        });
        stations = [];
        easy_stations.forEach((value) => { stations.push(value) });
        medium_stations.forEach((value) => { stations.push(value) });
        hard_stations.forEach((value) => { stations.push(value) });
        break;
      default:
        break;
    }

    this.setState({filtered_stations: stations});
  }

  searchStations = (e) => {
    let value = e.target.value
    this.setState({ search_term: value });
  }

  favourite = (station) => {
    let found_station = this.state.all_stations.find(st => st.id === station.id);
    found_station.favourited = !found_station.favourited;
    this.props.dispatch(API("/station/" + found_station.number + "/favourite", "POST", { favourited: found_station.favourited }))
  }

  saveFilters = async (filters) => {
    await this.props.dispatch(API("/user/save_filters", "POST", filters));
    this.setState({ user: {...this.state.user, filters: filters}});
    message.success("You have successfully saved your filters!");
  }

  createPlaylist = async(name) => {
    if (name.length < 1)
      return false;

    let playlists = await this.props.dispatch(API("/playlists/create", "POST", { name, station_id: this.state.current_station.id }));
    message.success("Station " + this.state.current_station.number + " successfully added to playlist");

    this.props.dispatch(setPlaylists(playlists));
    this.setState({ current_station: null, display_playlist_modal: false, open_playlist: playlists[0].id });
  }

  addToPlaylist = async(playlist) => {
    if (playlist == null)
      return false;

    let playlists = await this.props.dispatch(API("/playlists/add", "POST", { playlist_id: playlist, station_id: this.state.current_station.id }));
    message.success("Station " + this.state.current_station.number + " successfully added to playlist");

    this.setState({ current_station: null, display_playlist_modal: false, open_playlist: playlist });
    this.props.dispatch(setPlaylists(playlists));
  }

  deletePlaylist = async(playlist_id) => {
    if (playlist_id == this.state.open_playlist)
      this.setState({ open_playlist: null });
    let playlists = await this.props.dispatch(API("/playlists/delete", "POST", { playlist_id }));
    this.props.dispatch(setPlaylists(playlists));
  }

  deletePlaylistItem = async(item_id) => {
    let playlists = await this.props.dispatch(API("/playlists/remove_item", "POST", { item_id }));
    if (playlists.find(playlist => playlist.id == this.state.open_playlist) === undefined)
      this.setState({ open_playlist: null });
    this.props.dispatch(setPlaylists(playlists));
  }

  reorderPlaylists = (playlists) => {
    this.props.dispatch(setPlaylists(playlists));

    let ids = [];
    playlists.forEach(playlist => {
      ids.push(playlist.id);
    })
    this.props.dispatch(API("/playlists/reorder", "POST", { playlists: ids }));
  }

  reorderPlaylistItems = async (items, playlist) => {
    let playlists = this.props.playlists;
    let found_playlist = playlists.find(pl => pl.id === playlist.id);
    found_playlist = playlist;
    this.props.dispatch(setPlaylists(playlists));

    let ids = [];
    items.forEach(item => {
      ids.push(item.id);
    })

    this.props.dispatch(API("/playlists/reorder_items", "POST", { items: ids }))
  }

  toggleOpenPlaylist = (playlist_id) => {
    if (this.state.open_playlist == playlist_id)
      this.setState({ open_playlist: null });
    else
      this.setState({ open_playlist: playlist_id });
  }

  updatePlaylistName = (name, playlist_id) => {
    let playlists = this.props.playlists;
    let found_playlist = playlists.find(pl => pl.id === playlist_id);
    found_playlist.name = name;
    this.props.dispatch(setPlaylists(playlists));

    this.props.dispatch(API("/playlists/edit", "POST", { playlist_id, name }));
  }

  render() {
    let role_details = getRoleDetails(this.props.role);
    const width = this.state.width;
    const isSmallScreen = width <= 992;

    return (
      <div id="search">
        <MetaTags>
          <title>Study | OSCEbank</title>
        </MetaTags>

        <Modal
          title="Add Station"
          visible={this.state.display_playlist_modal}
          onCancel={() => this.setState({ display_playlist_modal: false })}
          cancelText="Cancel"
          okButtonProps={{ style: { display: "none"} }}
        >
          <AddItem playlists={this.props.playlists}
                  createPlaylist={(name) => this.createPlaylist(name)}
                  addToPlaylist={(playlist) => this.addToPlaylist(playlist)}
          />
        </Modal>

        <div className="container">
          {role_details !== null && !this.props.groupStudy ? (
            <h4 className="role-title solo-study" onClick={() => this.showRoleChooser()}>
              <img src={role_details.img} alt={role_details.role_name} />
              Change Your Role: <span>{role_details.role_name}</span>
            </h4>
          ) : ''}

          <Card className={"stations " + (this.props.groupStudy ? 'has-sidebar' : '')}>
            {this.state.filtered_stations !== null ?
              <StationSorter
                  sort={(type) => this.sort(type)}
                  reverse={() => this.setState({ filtered_stations: this.state.filtered_stations.reverse() })}
                  showFilters={() => this.setState({ visible: true })}
                  search={(e) => this.searchStations(e)}
                  searchTerm={this.state.search_term}
                  searchFilters={true} />
            : ""}
            {this.state.loading ? <div><SkeletonDisplay /><SkeletonDisplay /><SkeletonDisplay /></div> :
              <StationPicker
                stations={this.state.filtered_stations.filter(station => (station.name.toLowerCase().indexOf(this.state.search_term) !== -1 || station.number.toString().indexOf(this.state.search_term) !== -1))}
                chooseStation={(station) => this.startStation(station.number)}
                addToPlaylist={(station) => this.setState({ display_playlist_modal: true, current_station: station })}
                user={this.state.user}
                playlist={true}
                displayName={role_details !== null && (role_details.role !== "candidate-2" && !this.props.hidden_station_names) || (role_details !== null && (role_details.role == "examiner" || role_details.role == "patient"))}
                favourite={(station) => this.favourite(station)}
            />}
          </Card>

          {! isSmallScreen && this.props.groupStudy ?
            <div id="sidebar">
              {role_details !== null ? (
                <h4 className="role-title" onClick={() => this.showRoleChooser()}>
                  <img src={role_details.img} alt={role_details.role_name} />
                  Change Your Role: {role_details.role_name}
                </h4>
              ) : ''}

              {this.props.groupStudy ?
                  <GroupStudy layout="vertical"
                              displayStation={true}
                              addToPlaylist={(station) => this.setState({ display_playlist_modal: true, current_station: station })} />
                  : ''}
            </div>
          : ''}

          {isSmallScreen && this.props.groupStudy ?
            <Button.Group className={"show-drawer " + ((window.innerHeight + window.pageYOffset + 32) < document.getElementById('footer').offsetTop ? 'pinned' : '')}>
              <Button type="primary" onClick={() => this.setState({group_visible: !this.state.group_visible})}>Show Group</Button>
            </Button.Group>
          : ''}

          {this.props.groupStudy ?
            <Drawer
              title="Group Study"
              placement="right"
              closable={true}
              onClose={this.closeGroup}
              visible={isSmallScreen && this.state.group_visible}
              className="time-nav sidenav"
              mask={true}
              width="356"
            >
              {role_details !== null ? (
                <h4 className="role-title" onClick={() => this.showRoleChooser()}>
                  <img src={role_details.img} alt={role_details.role_name} />
                  Change Your Role: {role_details.role_name}
                </h4>
              ) : ''}
              <GroupStudy layout="vertical"
                          displayStation={true}
                          addToPlaylist={(station) => this.setState({ display_playlist_modal: true, current_station: station })}
              />
            </Drawer>
          : ''}

          {this.props.playlists !== null && this.props.playlists.length > 0 && <Affix className={'playlists-button' + (isSmallScreen && this.props.groupStudy ? '-group' : '')} style={{ position: 'fixed', bottom: this.state.filtered_stations !== null && this.state.filtered_stations.length > 2 ? '40px' : '180px', right: '40px', zIndex: '5' }}>
            <Badge count={this.state.open_playlist != null ? this.props.playlists.find(playlist => playlist.id === this.state.open_playlist).items.length : ""}>
              <Button shape="round" type="primary" icon="bars" size="large" onClick={() => this.setState({show_playlist: !this.state.show_playlist})}>
                Playlists
              </Button>
            </Badge>
          </Affix>}

          {this.state.show_filters ? <p>Showing Filters</p> : ""}
          <Drawer
            title="Search Filters"
            placement="left"
            onClose={this.onClose}
            visible={this.state.visible}
            className={`sidenav search-sidenav`}
            mask={true}
            width="400"
          >
            <div className="filters">
              {role_details !== null && role_details.role_name === "Solo Candidate" ?
                <Button onClick={() => this.props.dispatch(setHideStationNames(!this.props.hidden_station_names))}>{!this.props.hidden_station_names ? "Hide" : "Show"} Station Names</Button>
                : ""}
              <FiltersForm roleDetails={role_details}
                          taxons={this.state.taxons}
                          search={(values) => this.search(values)}
                          user={this.state.user}
                          filters={this.props.filters}
                          saveFilters={filters => this.saveFilters({ ...filters, hidden_station_names: this.props.hidden_station_names})} />
            </div>
          </Drawer>

          <Drawer
            title="My Playlists"
            placement="right"
            closable={true}
            onClose={this.handleCancel}
            visible={this.state.show_playlist}
            width='356'
          >
            <Playlists role={role_details}
                        playlists={this.props.playlists}
                        open_playlist={this.state.open_playlist}
                        deletePlaylist={(playlist_id) => this.deletePlaylist(playlist_id)}
                        deletePlaylistItem={(item_id) => this.deletePlaylistItem(item_id)}
                        reorderPlaylists={(playlists) => this.reorderPlaylists(playlists)}
                        reorderPlaylistItems={(items, playlist) => this.reorderPlaylistItems(items, playlist)}
                        toggleOpen={(playlist_id) => this.toggleOpenPlaylist(playlist_id)}
                        updatePlaylistName={(name, playlist_id) => this.updatePlaylistName(name, playlist_id)} />
          </Drawer>
        </div>
      </div>
    )
  }
}
export default connect(state => {
  return {
    groupStudy: state.groupStudy !== null,
    members: state.members,
    playlist: state.playlist,
    playlists: state.playlists,
    role: state.role,
    roleUpdated: state.roleFlag,
    filters: state.filters,
    hidden_station_names: state.hidden_station_names
  }
})(Search);