import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import Header from "./Partials/Header";
import Footer from "./Partials/Footer";
import CookieBanner from "react-cookie-banner";

const { Content } = Layout;

class PageLayout extends Component {

  state = {
    current: 'home',
    collapsed: false
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  render() {
    return (
      <Layout className="page-layout">
        <Header path={this.props.match.path !== "/:slug" ? this.props.match.path : this.props.match.params.slug} />
        <CookieBanner
          message="This website uses cookies to ensure you get the best experience."
          onAccept={() => { }}
          cookie="user-has-accepted-cookies"
          dismissOnScroll={false}
          styles={{ banner: {position: "fixed", bottom: 0} }} />
        <Content className="page-content">{this.props.children}</Content>

        <Footer />
      </Layout>
    );
  }
}

export default connect()(PageLayout)