export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";

export const TOKEN_EXPIRED = "TOKEN_EXPIRED";
export const UPDATE_TOKEN = "UPDATE_TOKEN";

export const RESET_REQUEST = "RESET_REQUEST";
export const RESET_REQUEST_SUCCESS = "RESET_REQUEST_SUCCESS";
export const RESET_REQUEST_ERROR = "RESET_REQUEST_ERROR";

export const SEND_RESET = "SEND_RESET";
export const RESET_SUCCESS = "RESET_SUCCESS";
export const RESET_ERROR = "RESET_ERROR";

export const MEMBERS_UPDATED = "MEMBERS_UPDATED";
export const GROUP_STUDY = "GROUP_STUDY";
export const GROUP_STUDY_FLAG = "GROUP_STUDY_FLAG";

export const updateGroupMembers = value => ({
  type: MEMBERS_UPDATED,
  value
})

export const changeGroupStudy = value => ({
  type: GROUP_STUDY,
  value
})

export const toggleGroupStudyFlag = value => ({
  type: GROUP_STUDY_FLAG,
  value
})

export const STATIONS_SUGGESTED = "STATIONS_SUGGESTED"
export const STATION_UPDATE_FLAG = "STATION_UPDATE_FLAG"

export const updateSuggestedStations = value => ({
  type: STATIONS_SUGGESTED,
  value
})

export const toggleStationUpdateFlag = value => ({
  type: STATION_UPDATE_FLAG,
  value
})

export const PLAYLISTS_SUGGESTED = "PLAYLISTS_SUGGESTED"    //Group playlists + flag
export const PLAYLIST_UPDATE_FLAG = "PLAYLIST_UPDATE_FLAG"

export const updateSuggestedPlaylists = value => ({
  type: PLAYLISTS_SUGGESTED,
  value
})

export const togglePlaylistUpdateFlag = value => ({
  type: PLAYLIST_UPDATE_FLAG,
  value
})

export const STATION_STARTED = "STATION_STARTED"

export const toggleStationStart = value => ({
  type: STATION_STARTED,
  value
})

export const CURRENT_STATION = "CURRENT_STATION"

export const setCurrentStation = value => ({
  type: CURRENT_STATION,
  value
})

export const GROUP_NAME = "GROUP_NAME"

export const updateGroupName = value => ({
  type: GROUP_NAME,
  value
})

export const GROUP_UNIQUE_ID = "GROUP_UNIQUE_ID"

export const updateGroupUniqueId = value => ({
  type: GROUP_UNIQUE_ID,
  value
})

export const PLAYLIST = "PLAYLIST"
export const PLAYLISTS = "PLAYLISTS"
export const PLAYLIST_INDEX = "PLAYLIST_INDEX"

export const setPlaylist = value => ({
  type: PLAYLIST,
  value
})

export const setPlaylists = value => ({
  type: PLAYLISTS,
  value
})

export const setPlaylistIndex = value => ({
  type: PLAYLIST_INDEX,
  value
})

export const ROLE = "ROLE"
export const ROLE_UPDATED = "ROLE_UPDATED"

export const setRole = value => ({
  type: ROLE,
  value
})

export const roleUpdated = value => ({
  type: ROLE_UPDATED,
  value
})

export const FILTERS = "FILTERS";
export const HIDE_STATION_NAMES = "HIDE_STATION_NAMES";

export const setFilters = value => ({
  type: FILTERS,
  value
})

export const setHideStationNames = value => ({
  type: HIDE_STATION_NAMES,
  value
})