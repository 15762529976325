import {
  LOGIN_SUCCESS,
  LOGOUT
} from "../constants/ActionTypes";
import "whatwg-fetch";

export const loginSuccess = token => {
  localStorage.setItem("token", token);
  return {
    type: LOGIN_SUCCESS,
    token
  };
};

export const logoutUser = () => {
  localStorage.removeItem("token");
  return {
    type: LOGOUT,
  };
};