import React, { Component } from "react";
import { connect } from "react-redux";
import { APITokenless, API } from '../../actions/API.js';
import { Skeleton, Checkbox, Button, message } from "antd";
import NotFound from "../NotFound";
import Accordion from "../Partials/Accordion";
import Content from "../Partials/Content";
import Gallery from "../Partials/Gallery";
import MetaTags from 'react-meta-tags';

import bannerImage from "../../static/images/oscebank-main-banner.jpg";

const base_url = process.env.REACT_APP_IMAGE_ENDPOINT + "page/";
const country = process.env.REACT_APP_COUNTRY

class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: null,
      terms_and_conditions: null,
      terms_and_conditions_loading: false,
      terms_and_conditions_agreed: false,
    }
  }

  componentDidMount = async() => {
    let slug = this.props.match.params.slug;
    if (country == "GB" && (slug !== "privacy-policy" && slug !== "terms-and-conditions"))
      slug += "-1";
    else if (country == "US" && (slug !== "privacy-policy" && slug !== "terms-and-conditions"))
      slug += "-2";
    let content = await this.props.dispatch(APITokenless("/page/" + slug, "GET"));
    let terms_and_conditions = null;
    if (slug === "terms-and-conditions")
        terms_and_conditions = await this.props.dispatch(APITokenless("/terms_and_conditions", "GET"));
    if(!this.unmounted)
        this.setState({content, terms_and_conditions});
  }

  componentWillReceiveProps = async(props) => {
    this.setState({content: null});
    let slug = props.match.params.slug;
    if (country == "GB" && (slug !== "privacy-policy" && slug !== "terms-and-conditions"))
      slug += "-1";
    else if (country == "US" && (slug !== "privacy-policy" && slug !== "terms-and-conditions"))
      slug += "-2";
    let content = await this.props.dispatch(APITokenless("/page/" + slug, "GET"));
    let terms_and_conditions = null;
    if (slug === "terms-and-conditions")
        terms_and_conditions = await this.props.dispatch(APITokenless("/terms_and_conditions", "GET"));
    if (!this.unmounted)
        this.setState({ content, terms_and_conditions });
  }

  componentWillUnmount = () => {
    this.unmounted = true;
  }

  updateTermsAndConditions = async() => {
    if (this.state.terms_and_conditions_agreed)
    {
        this.setState({ terms_and_conditions_loading: true });
        await this.props.dispatch(API("/user/update_terms_and_conditions", "POST", {terms: this.state.terms_and_conditions.id}));
        message.success("You have agreed to the new terms and conditions");
        localStorage.removeItem("update_terms");

        this.setState({ terms_and_conditions_loading: false });
    }
    else
        message.warning("Please check the box to confirm you agree");
  }

  render() {
    if (this.state.content !== null && Object.keys(this.state.content).length < 1)
        return <NotFound />;

    return (
        <div id="cmsPage">
            <div className="cms-page-content">
                {this.state.content === null ?
                <div className={`container fixed`}>
                    <Skeleton active />
                    <Skeleton active />
                    <Skeleton active />
                </div> :
                <div>
                    <MetaTags>
                        <title>{this.state.content.title} | OSCEbank</title>
                        <meta name="title" content={this.state.content.meta_title} />
                        <meta name="keywords" content={this.state.content.meta_keywords} />
                        <meta name="description" content={this.state.content.meta_description} />
                    </MetaTags>

                    {this.state.content.image !== null ?
                        <div className="banner" style={{ backgroundImage: this.state.content !== null && this.state.content !== false && this.state.content.image !== null ?
                            'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' + base_url + this.state.content.id + "/" + this.state.content.image + ')'
                            : 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' + bannerImage + ')' }}>
                            <div className={`container fixed`}>
                                <h1 className="cms-page-title">{this.state.content.title}</h1>
                            </div>
                        </div> :
                        <div className={`container fixed`} style={{ paddingBottom: '0' }}>
                            <h1 className="cms-page-title">{this.state.content.title}</h1>
                        </div>
                    }

                    {this.state.terms_and_conditions ?
                        <div style={{padding: "1em 5em"}}>
                            <div dangerouslySetInnerHTML={{__html: this.state.terms_and_conditions.terms}} />

                            {localStorage.getItem("update_terms") && localStorage.getItem("token") ?
                                <div>
                                    <Checkbox checked={this.state.terms_and_conditions_agreed} onClick={(e) => this.setState({terms_and_conditions_agreed: e.target.checked})}>I agree to the updated terms and conditions of use</Checkbox>
                                    <Button onClick={this.updateTermsAndConditions} type="primary" loading={this.state.terms_and_conditions_loading} size="small">Submit</Button>
                                </div>
                            : "" }
                        </div>
                    : ""}

                    {this.state.content.content && <div className={`container fixed`} style={{ paddingBottom: '0' }}>
                        <div className="cms-page-intro">
                            <h1 className="intro-title" dangerouslySetInnerHTML={{ __html:this.state.content.subtitle}} />
                            <div className="intro-text" dangerouslySetInnerHTML={{ __html: this.state.content.content }} />
                        </div>
                    </div>}

                    {this.state.content.nodes && <div className={`container fixed cms-page-contents`} style={{ paddingTop: '1em' }}>
                        {this.state.content.nodes.map((value, index) => {
                            let node = "";
                            switch (value.type) {
                                case "accordion":
                                    node = <Accordion accordion={value} key={"accordion-" + index} />
                                    break;
                                case "content":
                                    node = <Content content={value.content} key={"cms-content-" + index} />
                                    break;
                                case "gallery":
                                    node = <Gallery assets={value.assets} key={"gallery-" + index} />
                                    break;
                                default: break;
                            }
                            return node;
                        })}
                    </div>}
                </div>
                }
            </div>
        </div>
    )
  }
}

export default connect()(Page);