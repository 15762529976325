import React, { Component } from "react";
import { connect } from "react-redux";
import { Input } from "antd"

class Notes extends Component {

  updateNotes = (e) => {
    let { value } = e.target;
    this.props.updateNotes(value);

    //this.props.updateNotes(e);
  }

  render() {
    return (
      <div style={{minHeight: "200px"}}>
        <Input.TextArea
          value={this.props.notes}
          onChange={(e) => this.updateNotes(e)}
          autosize={{ minRows: 5 }}
        />
      </div>
    )
  }
}

export default connect()(Notes);