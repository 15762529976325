import React, { Component } from "react";
import { connect } from "react-redux";
import { APITokenless } from '../../actions/API.js';
import { Button, Carousel, Card, Divider, Skeleton } from "antd";
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import Iframe from 'react-iframe'
import MetaTags from 'react-meta-tags';
import ReactPixel from 'react-facebook-pixel';
import { displayPrice } from "../../actions/functions"

import bannerImage from "../../static/images/oscebank-main-banner.jpg";
import logo from "../../static/images/logo-white.svg";
import feature1 from "../../static/images/practical-resources.svg";
import feature2 from "../../static/images/clinically-relevant.svg";
import feature3 from "../../static/images/diverse-content.svg";
import screenshot from "../../static/images/osce-screenshot.png";

import howWorks1 from "../../static/images/how-it-works/choose-your-mode.png";
import howWorks2 from "../../static/images/how-it-works/select-a-station.png";
import howWorks3 from "../../static/images/how-it-works/set-your-own-pace.png";
import howWorks4 from "../../static/images/how-it-works/get-feedback.png";
import howWorks5 from "../../static/images/how-it-works/track-your-progress.png";

import testimonials from "../../static/images/osce-testimonials.svg";
import subscription from "../../static/images/subscription.svg";

const base_url = process.env.REACT_APP_IMAGE_ENDPOINT + "page/";
const country = process.env.REACT_APP_COUNTRY

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: null,
      subscription_types: [],
      testimonials: []
    }
  }

  componentDidMount = async () => {
    let content = {};
    switch(country)
    {
      case "AU":
        content = await this.props.dispatch(APITokenless("/page/home", "GET"));
        break;
      case "GB":
        content = await this.props.dispatch(APITokenless("/page/home-1", "GET"));
        break;
      case "US":
        content = await this.props.dispatch(APITokenless("/page/home-2", "GET"));
        break;
      default:
        break;
    }
    let subscription_types = await this.props.dispatch(APITokenless("/subscription_types/signup?country=" + country, "GET"));
    let testimonials = await this.props.dispatch(APITokenless("/testimonials", "GET"));
    if (!this.unmounted)
        this.setState({ content: Object.keys(content).length > 0 ? content : false, subscription_types, testimonials});

    ReactPixel.pageView();
  }

  componentWillUnmount = () => {
      this.unmounted = true;
  }

  render() {
    return (
        <div id="home">
            { this.state.content === null ?
                <div className={`container fixed`}>
                    <Skeleton active />
                    <Skeleton active />
                    <Skeleton active />
                </div> :
                <div>
                    <MetaTags>
                        <title>{this.state.content.title} | OSCEbank</title>
                        <meta name="title" content={this.state.content.meta_title} />
                        <meta name="keywords" content={this.state.content.meta_keywords} />
                        <meta name="description" content={this.state.content.meta_description} />
                    </MetaTags>

                    <div className="banner" style={{ backgroundImage: this.state.content !== null && this.state.content !== false && this.state.content.image !== null ?
                        'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' + base_url + this.state.content.id + "/" + this.state.content.image + ')'
                        : 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' + bannerImage + ')' }}>

                        <Fade delay={500} duration={2000}>
                            <div className="banner-text">
                                <img src={logo} id="logo-banner" alt="OSCEbank logo" />
                                <div>
                                    <h1 dangerouslySetInnerHTML={{ __html: this.state.content.subtitle }} />
                                    <div dangerouslySetInnerHTML={{ __html: this.state.content.content }} />
                                    <Link to="/subscription-plans">
                                        <Button type="primary" size="large">Get Started</Button>
                                    </Link>
                                </div>
                            </div>
                        </Fade>
                    </div>

                    <div className="features-section">
                        <div className={`container fixed`}>
                            <div className="row">
                                <div className="col">
                                    <Fade duration={2000}>
                                        <h1>Perform at your peak</h1>
                                        <h4>OSCEbank has been developed specifically to support medical students studying for their OSCEs.</h4>
                                    </Fade>

                                    <div className="features">
                                        <Fade delay={250} duration={2000}>
                                            <div className="feature">
                                                <div>
                                                    <img src={feature1} alt="Practical resources" />
                                                </div>
                                                <div className="feature-text">
                                                    <h3>Practical resources</h3>
                                                    <p>All the tools you need to study for OSCEs with zero preparation or research.</p>
                                                </div>
                                            </div>
                                        </Fade>

                                        <Fade delay={500} duration={2000}>
                                            <div className="feature">
                                                <div>
                                                    <img src={feature2} alt="Clinically relevant" />
                                                </div>
                                                <div className="feature-text">
                                                    <h3>Clinically relevant</h3>
                                                    <p>Created and written by doctors who have lived the experience of studying for OSCEs.</p>
                                                </div>
                                            </div>
                                        </Fade>

                                        <Fade delay={750} duration={2000}>
                                            <div className="feature">
                                                <div>
                                                    <img src={feature3} alt="Diverse content" />
                                                </div>
                                                <div className="feature-text">
                                                    <h3>Diverse content</h3>
                                                    <p>Provides exposure to a large variety of stations so nothing is a surprise on exam day.</p>
                                                </div>
                                            </div>
                                        </Fade>
                                    </div>
                                </div>
                                <Fade delay={250} duration={2000}>
                                    <div className="col">
                                        <img src={screenshot} alt="OSCEbank interface" id="screenshot"/>
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </div>

                    <div className="how-it-works">
                        <div className={`container fixed`}>
                            <h1>How it works</h1>
                            <Fade delay={250} duration={2000}>
                                <Carousel effect="fade" dots="false" arrows="true" infinite="false" swipeToSlide="true">
                                    <div className="slide">
                                        <div className="slide-img">
                                            <img src={howWorks1} alt="Diverse content" />
                                        </div>
                                        <div className="slide-text">
                                            <h3>Choose your mode</h3>
                                            <p>Study solo, in pairs, or in a larger group. Select your role and see the information relevant to you.</p>
                                        </div>
                                    </div>
                                    <div className="slide">
                                        <div className="slide-img">
                                            <img src={howWorks2} alt="Diverse content" style={{ width: '320px' }}/>
                                        </div>
                                        <div className="slide-text">
                                            <h3>Select a station</h3>
                                            <p>Search by keyword or filter by station type, specialty or difficulty. Select multiple stations by creating a playlist to work through.</p>
                                        </div>
                                    </div>
                                    <div className="slide">
                                        <div className="slide-img">
                                            <img src={howWorks3} alt="Diverse content" />
                                        </div>
                                        <div className="slide-text">
                                            <h3>Set your own pace</h3>
                                            <p>Set custom timers for each section and navigate through the station at your own pace.</p>
                                        </div>
                                    </div>
                                    <div className="slide">
                                        <div className="slide-img">
                                            <img src={howWorks4} alt="Diverse content" style={{ width: '400px' }}/>
                                        </div>
                                        <div className="slide-text">
                                            <h3>Get feedback</h3>
                                            <p>Evaluate your performance using the interactive marking guide and station approach guide.</p>
                                        </div>
                                    </div>
                                    <div className="slide">
                                        <div className="slide-img">
                                            <img src={howWorks5} alt="Diverse content" style={{ width: '400px' }}/>
                                        </div>
                                        <div className="slide-text">
                                            <h3>Track your progress</h3>
                                            <p>Identify your areas of strength and weakness, so you can focus study on the areas that need it most.</p>
                                        </div>
                                    </div>
                                </Carousel>
                            </Fade>
                        </div>
                    </div>

                    <div className="preview-section">
                        <div className={`container fixed`}>
                            <h1>What is OSCEbank?</h1>
                            <Fade delay={250} duration={2000}>
                                <div className="wistia_responsive_padding" style={{ padding: '53.75% 0 0 0', position: 'relative' }}>
                                    <div className="wistia_responsive_wrapper" style={{ height: '100%', left: '0', position: 'absolute', top: '0', width: '100%' }}>
                                        <Iframe src="https://fast.wistia.net/embed/iframe/odr7fc96so?videoFoam=true" title="OSCEbank Explainer Video" allowTransparency="true" frameBorder="0" scrolling="no" className="wistia_embed" name="wistia_embed" allowFullScreen mozAllowFullScreen webkitAllowFullScreen oAllowFullScreen msAllowFullScreen width="100%" height="100%">
                                        </Iframe>
                                    </div>
                                </div>
                                <script src="https://fast.wistia.net/assets/external/E-v1.js" async></script>
                            </Fade>
                            <Fade delay={250} duration={2000}>
                                <div className="preview-banner">
                                    <div className="col">
                                        <h2>Test it out</h2>
                                        <p>Experience the OSCEbank platform by signing up for a free trial.</p>
                                    </div>
                                    <div className="col">
                                        <Link to="/free-trial">
                                            <Button size="large">Free Trial</Button>
                                        </Link>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>

                    {this.state.testimonials.length > 0 && <div className="testimonials">
                        <div className={`container fixed`}>
                            <div className="heading">
                                <Fade delay={250} duration={2000}>
                                    <h1>
                                        <img src={testimonials} alt="osce testimonials" />
                                        What students say about OSCEbank
                                    </h1>
                                </Fade>
                            </div>
                            <Carousel
                                autoplay
                                autoplaySpeed={5000}>
                                {this.state.testimonials.map((value, index) => {
                                    return <div className="slide" key={"testimonial-" + index}>
                                        <p dangerouslySetInnerHTML={{ __html: value.testimonial}} />
                                        <p><em>{value.name}</em></p>
                                    </div>
                                })}
                            </Carousel>
                        </div>
                    </div>}

                    <div className="subscriptions">
                        <Fade delay={250} duration={2000}>
                            <div className="subscriptions-heading">
                                <h1>Get Studying</h1>
                                <p>Choose your subscription and start improving your OSCE skills today!</p>
                            </div>
                        </Fade>

                        <div className={`container fixed`}>
                            {this.state.subscription_types.map((value, index) => {
                                return <Card key={"subscription-select-" + index}>
                                    <img src={subscription} alt={value.name} />
                                    <h1>{value.name}</h1>
                                    <Divider/>
                                    <p className="cost">
                                        {displayPrice(value)}
                                        <span className="time-period">/ {value.display_length}</span>
                                    </p>
                                    <div className="description" dangerouslySetInnerHTML={{ __html: value.description }} />

                                    <Link to={"/signup?plan=" + value.id}>
                                        <Button type="primary" size="large">Get Started</Button>
                                    </Link>
                                </Card>
                            })}
                        </div>
                    </div>
                </div>
            }
        </div>
    )
  }
}

export default connect()(HomePage);
