import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, Redirect } from "react-router-dom";
import { end } from "../actions/Echo"
import { toggleStationStart, setCurrentStation, setPlaylist, setPlaylistIndex } from "../constants/ActionTypes"
import PageLayout from "./PageLayout";

class PageRoute extends Component {
  constructor(props) {
    super(props);
    this.headingRef = React.createRef();
  }

  componentDidMount = () => {
    if (!this.unmounted)
      window.scrollTo(0, this.headingRef.current.offsetTop);
  }

  componentWillReceiveProps = (props) => {
    if (this.props.path === "/station/:code")
    {
      if (this.props.role === "examiner" && props.path !== "/station/:code")
        window.presence.whisper('examiner-left', { left: true });

      this.props.dispatch(setCurrentStation(null));
      this.props.dispatch(toggleStationStart(false))

      if (props.path !== "/study" && props.path !== "/station/:code")
        this.props.dispatch(end(false));
      else if (props.path !== "/station/:code" && this.props.playlist !== null)
      {
        this.props.dispatch(setPlaylist(null));
        this.props.dispatch(setPlaylistIndex(0));
      }
    }

    if (this.props.path === "/study")
    {
      if (this.props.playlist !== null && props.path !== "/station/:code")
      {
        this.props.dispatch(setPlaylist(null));
        this.props.dispatch(setPlaylistIndex(0));
      }
    }

    if (!this.unmounted)
      window.scrollTo(0, this.headingRef.current.offsetTop);
  }

  componentWillUnmount = () => {
    this.unmounted = true;
  }

  render() {
    const props = this.props;
    const { isAuthenticated, component, location, ...rest } = props;
    const Component = component;
    return (
      <Route
        {...rest}
        ref={this.headingRef}
        render={props => {
          return isAuthenticated ? (
            <PageLayout match={props.match}>
              <Component match={props.match} location={location} />
            </PageLayout>
          ) : (
              <Redirect to={{pathname: "/login"}} />
            );
        }}
      />
    );
  }

}

export default withRouter(connect(state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    playlist: state.playlist,
    role: state.role
  };
})(PageRoute));