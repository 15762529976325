import React, { Component } from "react";
import { connect } from "react-redux";
import { Tabs, Icon, Button, Modal, Select } from "antd";
import { push } from "react-router-redux";
import { API } from '../../actions/API.js';
import { getUser } from "../../actions/functions";
import StationSorter from "../Partials/StationSorter";
import StatsStationPicker from "./StatsStationPicker";
import AverageChart from "./AverageChart";
import SkeletonDisplay from "../Partials/SkeletonDisplay";
import StationHistory from "./StationHistory"
import MetaTags from 'react-meta-tags';


const TabPane = Tabs.TabPane;

class StatsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      stats: null,
      completed_stations: null,
      attempt_again_stations: null,
      chart_data: null,
      sort_order: "strengths",
      station_id: null,
      average: "...",
      display_name: true,
      history: [],
      marking_id: null,
      tab: "strengths",
      station_page: 1,
      filter: "all",
      completed_keyword: "",
      try_again_keyword: ""
    }
  }

  componentDidMount = async () => {
    let user = await getUser(this.props, true);
    let data = await this.props.dispatch(API("/user/stats", "GET"));
    if (!this.unmounted)
      this.setState({ user, stats: {average: data.average_mark, attempt_again: data.attempt_again.length, complete: data.completed.length},
                      completed_stations: data.completed,
                      attempt_again_stations: data.attempt_again,
                      chart_data: data.chart_data });
  }

  componentWillUnmount = () => {
    this.unmounted = true;
  }

  selectTab = async (state) => {
    switch (state) {
      case "strengths":
      case "weaknesses":
        this.setState({ sort_order: state, tab: state, station_page: 1 });
        break;
      default:
        this.setState({ tab: state, station_page: 1 })
        break;
    }
  }

  sort = (type, completed) => {
    if (type === undefined)
      return false;
    let stations = [];
    if (completed)
      stations = this.state.completed_stations;
    else
      stations = this.state.attempt_again_stations;

    switch (type) {
      case "number":
        stations.sort((a, b) => (a.number > b.number) ? 1 : ((b.number > a.number) ? -1 : 0));
        break;
      case "name":
        stations.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        break;
      case "difficulty":
        let easy_stations = [];
        let medium_stations = [];
        let hard_stations = [];
        stations.forEach((value) => {
          let difficulties = value.stationTaxons.difficulties;
          if (difficulties.filter(difficulty => difficulty.difficulty === 'Easy').length > 0)
            easy_stations.push(value);
          else if (difficulties.filter(difficulty => difficulty.difficulty === 'Intermediate').length > 0)
            medium_stations.push(value);
          else if (difficulties.filter(difficulty => difficulty.difficulty === 'Advanced').length > 0)
            hard_stations.push(value);
        });
        stations = [];
        easy_stations.forEach((value) => { stations.push(value) });
        medium_stations.forEach((value) => { stations.push(value) });
        hard_stations.forEach((value) => { stations.push(value) });
        break;
      case "latest":
        stations.sort((a, b) => (a.latest_completion > b.latest_completion) ? -1 : ((b.latest_completion > a.latest_completion) ? 1 : 0));
        break;
      default:
        break;
    }

    if (completed)
      this.setState({completed_stations: stations});
    else
      this.setState({attempt_again_stations: stations});
  }

  resetStats = () => {
    Modal.confirm({
      title: this.state.station_id === null ? 'Are you sure you want to reset your stats?' : 'Are you sure you want to reset your stats for this station?',
      content: 'This action cannot be undone',
      onOk: async() => {
        let stats = await this.props.dispatch(API("/user/reset_stats", "POST", { reset: true, station_id: this.state.station_id }));
        if (!this.unmounted)
          this.setState({
            stats: { average: stats.average_mark, attempt_again: stats.attempt_again.length, complete: stats.completed.length },
            completed_stations: stats.completed,
            attempt_again_stations: stats.attempt_again,
            chart_data: stats.chart_data,
            station_id: null
          });
      }
    });
  }

  filterStats = async(date) => {
    this.setState({ stats: null, completed_stations: null, attempt_again_stations: null, chart_data: null, filter: date });
    let data = await this.props.dispatch(API("/user/stats", "POST", {date}));
    if (!this.unmounted)
      this.setState({ stats: {average: data.average_mark, attempt_again: data.attempt_again.length, complete: data.completed.length},
                    completed_stations: data.completed,
                    attempt_again_stations: data.attempt_again,
                    chart_data: data.chart_data,
                    average: data.average_mark + "%"});
  }

  average = () => {
    if (this.state.station_id === null && this.state.stats !== null)
      return this.state.stats.average + "%";
    else if (this.state.station_id !== null && this.state.completed_stations !== null && this.state.attempt_again_stations !== null)
    {
      if (this.state.completed_stations.find(station => station.id === this.state.station_id) !== undefined)
        return this.state.completed_stations.find(station => station.id === this.state.station_id).average + "%"
      if (this.state.attempt_again_stations.find(station => station.id === this.state.station_id) !== undefined)
        return this.state.attempt_again_stations.find(station => station.id === this.state.station_id).average + "%"
    }

    return "...";
  }

  keywordSearch = (e) => {
    if (this.state.tab == "completed")
      this.setState({ completed_keyword: e.target.value})
    else if (this.state.tab == "attempt_again")
      this.setState({ try_again_keyword: e.target.value })
  }

  render() {
    let labels = ["Specialties", "Types", "Systems"];
    let chart = this.state.chart_data !== null ?
      this.state.chart_data.map((value, index) => {
        return <AverageChart data={value} label={labels[index]} key={"chart-" + index} order={this.state.sort_order} />
      }) : labels.map((value, index) => {
        return <AverageChart data={null} label={value} key={"chart-" + index} />
      });
    return (
      <div id="stats-page">
        <MetaTags>
            <title>My Stats | OSCEbank</title>
        </MetaTags>
        <div className="container">
            <Select value={this.state.filter} id="filter-stats-select" onChange={this.filterStats}>
                <Select.Option value="all">Show All</Select.Option>
                <Select.Option value="last-month">Last Month</Select.Option>
                <Select.Option value="3-months">Last 3 Months</Select.Option>
                <Select.Option value="6-months">Last 6 Months</Select.Option>
            </Select>
            {this.state.history.length > 0 ?
              <Select defaultValue={this.state.marking_id} id="filter-history-select" onChange={(id) => this.setState({ marking_id: id })}>
                {this.state.history.map(item => {
                  return <Select.Option key={"history-select-" + item.id} value={item.id}>{item.created}</Select.Option>
                })}
              </Select> : ""}
            <Button id="reset-stats-button" onClick={this.resetStats}><Icon type="redo" />{this.state.station_id !== null ? "Reset Station Stats" : "Reset Stats"}</Button>

          <div className="avg-mark">
            <div>
              <p>Average<br/>Mark <b>{this.average()}</b></p>
            </div>
          </div>

          {this.state.station_id === null ?
            <Tabs type="card" onTabClick={this.selectTab} activeKey={this.state.tab}>
              <TabPane tab={<span><b><Icon type="star" />Strengths</b></span>} key="strengths">
                {chart}
              </TabPane>
              <TabPane tab={<span><b><Icon type="line-chart" />Areas for Improvement</b></span>} key="weaknesses">
                {chart}
              </TabPane>
              <TabPane tab={<span><b><Icon type="check-circle" />Stations Completed</b> {this.state.stats !== null ? '(' + this.state.stats.complete + ')' : ''}</span>} key="completed">
                {this.state.completed_stations !== null ?
                  <div>
                    {this.state.completed_stations.length > 0 ?
                      <StationSorter
                        sort={(type) => this.sort(type, true)}
                        reverse={() => this.setState({completed_stations: this.state.completed_stations.reverse()})}
                        keywordSearch={true}
                        search={this.keywordSearch}
                        searchTerm={this.state.completed_keyword}
                      />
                      : ''}
                    <StatsStationPicker
                      stations={this.state.completed_stations.filter(station => (station.name.toLowerCase().indexOf(this.state.completed_keyword.toLowerCase()) !== -1 || station.number.toString().indexOf(this.state.completed_keyword.toLowerCase()) !== -1))}
                      chooseStation={(station) => this.props.dispatch(push("/station/" + station.number))}
                      user={this.state.user}
                      playlist={false}
                      displayName={true}
                      changeDisplay={(station_id) => { this.setState({ station_id, filter: "all" }); this.filterStats("all"); }}
                      stationPage={this.state.station_page}
                      changePage={(page) => this.setState({ station_page: page})} />
                  </div> : <div><SkeletonDisplay /><SkeletonDisplay /><SkeletonDisplay /></div>}
              </TabPane>
              <TabPane tab={<span><b><Icon type="reload" />Stations to Try Again</b> {this.state.stats !== null ? '(' + this.state.stats.attempt_again + ')' : ''}</span>} key="attempt_again">
                {this.state.attempt_again_stations !== null ?
                  <div>
                    {this.state.attempt_again_stations.length > 0 ? <Button onClick={() => this.setState({ display_name: !this.state.display_name })}>{this.state.display_name ? "Hide" : "Show"} Station Names</Button> : ""}
                    {this.state.attempt_again_stations.length > 0 ?
                      <StationSorter
                        sort={(type) => this.sort(type, false)}
                        reverse={() => this.setState({ completed_stations: this.state.attempt_again_stations.reverse() })}
                        keywordSearch={true}
                        search={this.keywordSearch}
                        searchTerm={this.state.try_again_keyword} />
                      : ''}
                    <StatsStationPicker
                      stations={this.state.attempt_again_stations.filter(station => (station.name.toLowerCase().indexOf(this.state.try_again_keyword.toLowerCase()) !== -1 || station.number.toString().indexOf(this.state.try_again_keyword.toLowerCase()) !== -1))}
                      chooseStation={(station) => this.props.dispatch(push("/station/" + station.number))}
                      user={this.state.user}
                      playlist={false}
                      displayName={this.state.display_name}
                      changeDisplay={(station_id) => { this.setState({ station_id, filter: "all" }); this.filterStats("all");}}
                      stationPage={this.state.station_page}
                      changePage={(page) => this.setState({ station_page: page })} />
                  </div> : <div><SkeletonDisplay /><SkeletonDisplay /><SkeletonDisplay /></div>}
              </TabPane>
            </Tabs>
          : <StationHistory
              stationId={this.state.station_id}
              displayStations={() => this.setState({ station_id: null, history: [], marking_id: null })}
              setHistory={(history, marking_id) => {this.setState({ history, marking_id })}}
              markingId={this.state.marking_id}
              markings={this.state.history}
            />}
        </div>
      </div>
    )
  }
}

export default connect()(StatsPage);