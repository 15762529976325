import React, { Component } from "react";
import { connect } from "react-redux";
import { APITokenless } from '../../actions/API.js';
import { Link } from "react-router-dom";
import ResetPasswordForm from "./Forms/ResetPasswordForm";
import EnterEmailForm from "./Forms/EnterEmailForm";

class ActivatePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      message: null,
      success: null
    }
    this.unmounted = false;
  }

  componentWillUnmount = () => {
    this.unmounted = true;
  }

  submit = async (values) => {
    this.setState({loading: true});
    let code = this.props.match.params.code;
    let reset = await this.props.dispatch(APITokenless("/reset-password/" + code, "POST", { password: values.password1 }));

    if (!this.unmounted)
      this.setState({ loading: false, success: reset.success });
    if (reset.success === true)
      return true;

    if (!this.unmounted)
      this.setState({ message: reset.message });
    return false;
  }

  submitEmail = async (values) => {
    this.setState({ loading: true });
    await this.props.dispatch(APITokenless("/reset-password", "POST", { email: values.email }));
    if (!this.unmounted)
      this.setState({ loading: false, success: true, message: "Please check your emails for the link to reset your password"});
    return true;
  }

  render() {
    return (
        <div id="activate">
            <div className={`container fixed`}>
                {this.props.match.params.code !== undefined && this.state.success !== true ?
                    <ResetPasswordForm submit={(values) => this.submit(values)} loading={this.state.loading} />
                    : this.state.success === null ? <EnterEmailForm submit={(values) => this.submitEmail(values)} loading={this.state.loading} /> : ""
                }
                {this.state.success !== null ?
                    this.state.success && this.state.message === null ? <p class="email-submit-success">You have successfully reset your password! You can now <Link to="/login">login to your account</Link> with your new password.</p>
                    : <p className={this.props.match.params.code !== undefined ? "password-reset-error" : "email-submit-success"}>{this.state.message}</p>
                    : ""}
            </div>
        </div>
    )
  }
}

export default connect()(ActivatePage);