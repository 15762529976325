import React, { Component } from "react";
import { connect } from "react-redux";
import worker from "../../static/worker"

class Countdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      update: false,
      worker: new Worker(worker)
    }
  }

  componentDidMount() {
    this.state.worker.onmessage = (m => {
      if (!this.props.active || this.props.timing === false || this.props.paused || this.unmounted)
        return true;

      this.props.onTick(this.props.timing - 1000)
      this.setState({ update: !this.state.update });

      if ((this.props.timing - 1000) < 1)
        this.props.onComplete(true);
    })
  }

  componentWillUnmount = () => {
    this.unmounted = true;
    this.state.worker.terminate();
  }

  convertTiming = (value) => {
    var minutes = Math.floor(value / 60000);
    var seconds = ((value % 60000) / 1000).toFixed(0);
    if (seconds < 10)
      seconds = "0" + seconds;
    return { minutes: minutes, seconds: seconds};
  }

  render() {
    if (this.props.timing === false)
      return <div className="time-value">
        <p className="timing" style={{ display: !this.props.show ? "none" : "block" }}>∞</p>
      </div>

    let timing = this.convertTiming(this.props.timing)

    return (
      <div className="time-value">
        <p className="timing" style={{ display: !this.props.show ? "none" : "block" }}>{timing.minutes}:{timing.seconds}</p>
      </div>
    )
  }
}
export default connect()(Countdown);