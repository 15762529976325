import React, { Component } from "react";
import { connect } from "react-redux";
import { Spin, Input, Button, Divider, Modal, Icon } from "antd";
import { API } from '../../actions/API.js';
import { getUser } from "../../actions/functions";
import { start, end } from "../../actions/Echo.js"
import MetaTags from 'react-meta-tags';
import GroupStudy from '../Partials/GroupStudy';

import play from "../../static/images/icon-play.svg";
const domain = process.env.REACT_APP_API_DOMAIN;

class StudyGroupsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loading2: false,
      user: null,
      groups: [],
      group: null,
      nickname: null,
      search_groups: null
    }
  }

  componentDidMount = async () => {
    let user = await getUser(this.props);
    let groups = await this.props.dispatch(API("/groups", "GET"));
    if (!this.unmounted)
      this.setState({ user, groups, loading: false });
  }

  componentWillUnmount = () => {
    this.unmounted = true;
  }

  createStudyGroup = async () => {
    this.setState({ loading2: true });
    let resp = await this.props.dispatch(API("/groups/create", "POST"));
    this.setState({ groups: resp.groups, group: resp.group, nickname: resp.group.nickname, loading2: false });
  }

  updateNickname = (e) => {
    let { value } = e.target;

    if (value.length < 17)
      this.setState({ nickname: value })
  }

  saveNickname = async () => {
    this.setState({ loading2: true });

    let resp = await this.props.dispatch(API("/groups/" + this.state.group.id + "/update", "POST", {nickname: this.state.nickname}));
    this.setState({ loading2: false, groups: resp });
  }

  deleteStudyGroup = async (e, id) => {
    e.stopPropagation();

    Modal.confirm({
      title: 'Warning',
      content: "Are you sure you want to remove this group?",
      okText: 'Yes',
      onOk: () => this.confirmStudyGroupDelete(id),
      cancelText: 'No',
      width: '50%',
      className: 'modal-role-chooser'
    });
  }

  confirmStudyGroupDelete = async (id) => {
    let resp = await this.props.dispatch(API("/groups/" + id + "/delete", "GET"));
    this.setState({ groups: resp });
    Modal.destroyAll();
  }

  searchGroups = async (value) => {
    let resp = await this.props.dispatch(API("/groups/search", "POST", {search: value}));
    this.setState({ search_groups: resp });
  }

  joinGroup = async (group_id) => {
    let resp = await this.props.dispatch(API("/groups/" + group_id + "/join", "POST", {}));
    this.setState({ search_groups: null, groups: resp })
  }

  study = (group = null, e = null) => {
    if (e !== null)
      e.stopPropagation();
    if (group === null)
      group = this.state.group;

    if (this.props.groupStudy === null)
    {
      this.props.dispatch(start(group));
      this.setState({ group: null })
    }
    else
      Modal.confirm({
        title: 'Warning',
        content: "Studying in this group will end your current group study. Are you sure you want to continue?",
        okText: 'Yes',
        onOk: () => this.startNew(group),
        cancelText: 'No',
        width: '50%',
        className: 'modal-role-chooser'
      });
  }

  startNew = (group) => {
    Modal.destroyAll();
    this.props.dispatch(end());
    this.props.dispatch(start(group));
    this.setState({ group: null })
  }

  stopStudying = (e) => {
    e.stopPropagation();
    this.props.dispatch(end());
  }

  render() {
    return <div id="study-groups-page">
      {this.state.loading ?
          <Spin />
        :
        <div>
          <MetaTags>
            <title>Study Groups | OSCEbank</title>
          </MetaTags>

          <div className="container">
            <div className="study-groups-head">
              <Input.Search size="large"
                    placeholder="Find Study Group"
                    enterButton="Search"
                    onSearch={(value) => this.searchGroups(value)}
                    className="search-groups"
              />
              <Button
                icon="usergroup-add"
                size="large"
                type="primary"
                onClick={() => this.createStudyGroup()}
                loading={this.state.loading2}
              >
                Create Study Group
              </Button>
            </div>

            <div className="study-group-search">
              {this.state.search_groups !== null ?
                (this.state.search_groups.length > 0 ?
                    <div className="study-groups-list">
                      {this.state.search_groups.map((group, index) => {
                        return <div className="study-group"
                                    key={"group-search-" + index}>
                                  <div>
                                    <b>{group.code}</b>
                                    <p>{group.members} Members</p>
                                    {group.in_group ? <p>Study group already saved.</p> :
                                      <Button className="join-study-group" onClick={() => this.joinGroup(group.id)}>Join</Button>}
                                  </div>
                              </div>
                      })}
                    </div>
                  : <p>No groups were found.</p>)
              : ""}
            </div>

            <Modal
              title="Group"
              visible={this.state.group !== null}
              onOk={() => this.setState({ group: null})}
              closable={false}
              className="study-group-display"
            >
              {this.state.group !== null ?
                <div>
                  <div className="study-details">
                    <div className="study-code">{this.state.group.code}</div>
                    {this.state.group.id !== this.props.groupStudy ?
                      <Button className="start-study-group" onClick={() => this.study()}>Start Studying {'>'}</Button>
                      : <Button className="start-study-group" onClick={() => {this.setState({ group: null }); this.props.dispatch(end());}}>Leave Group</Button>
                    }
                  </div>
                  <div className="group-nickname">
                    <Input placeholder="Group Nickname" onChange={(e) => this.updateNickname(e)} value={this.state.nickname} />
                    <Button type="primary" onClick={() => this.saveNickname()} loading={this.state.loading2}>Save</Button>
                  </div>
                </div>
                : ""}
            </Modal>

            <Divider />

            <div className={"study-groups-list " + (this.props.groupStudy ? 'has-sidebar' : '')}>
              <h2>My Study Groups</h2>

              {this.state.groups.length < 1 ?
                <p>You don't seem to have any study groups.</p>
              : this.state.groups.map(group => {
                return (
                  <div
                    className={"study-group " + (this.props.groupStudy === group.id ? 'active' : '')}
                    onClick={() => this.setState({ group, nickname: group.nickname })}
                    key={"group-" + group.id}
                  >
                    <div className="group-details">
                      <div className="group-text">
                        <p className="group-nickname">{group.nickname !== null ? group.nickname : group.code}</p>
                        <p className="group-code">{group.nickname !== null ? '(' + group.code + ')' : ''}</p>
                        <p className="studying">{this.props.groupStudy === group.id ? 'Currently studying' : ''}</p>
                      </div>

                      <div className="group-controls">
                        {this.props.groupStudy !== group.id ?
                          <Button type="primary" className="start-study-group play-btn" onClick={(e) => this.study(group, e)}><img src={play} alt="Start Studying" className="play-icon" /></Button>
                        : <Button type="primary" className="leave-study-group" onClick={(e) => this.stopStudying(e)}><Icon type="usergroup-delete" /></Button>}
                        <Button className="edit-study-group"><Icon type="edit" /></Button>
                        {this.props.groupStudy !== group.id ?
                          <Button type="danger" ghost className="delete-study-group" onClick={(e) => this.deleteStudyGroup(e, group.id)}><Icon type="delete" theme="twoTone" twoToneColor="ff4d4f" /></Button>
                        : ''}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>

            {this.props.groupStudy ?
              <div id="sidebar">
                <GroupStudy layout="vertical" displayStation={true} />
              </div>
            : ''}
          </div>
        </div>}
    </div>;
  }
}
export default connect(state => { return {
    groupStudy: state.groupStudy,
  }})(StudyGroupsPage);