import React, { Component } from "react";
import { Form, Button, Input, Spin } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const FormItem = Form.Item;

class EnterEmailForm extends Component {

  submit = () => {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        if (await this.props.submit(values))
          this.props.form.resetFields();
      }
    });
  }

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    return (
      <div>
        <Spin spinning={this.props.loading}>
          <Form className="login-form">
            <h1 style={{ textAlign: 'center', marginBottom: '1em' }}>Get password reset link</h1>

            <FormItem>
              {getFieldDecorator("email", {
                rules: [{ required: true, message: "Email is required" }]
              })(<Input size="large"
                    placeholder="Your email" />)}
            </FormItem>
            <Button
              type="primary"
              htmlType="submit"
              disabled={hasErrors(getFieldsError())}
              onClick={this.submit}
              icon="check"
              size="large"
              block
            >
              Send Reset Link
            </Button>
            <Link to="/login" style={{ display: "table", marginTop: "1em" }}>
                Cancel
            </Link>
          </Form>
        </Spin>
      </div>
    )
  }
}

export default connect()(Form.create({ name: 'enter-email' })(EnterEmailForm));

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}