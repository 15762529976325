import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Row, Col } from "antd";

class PlaylistItem extends Component {

  render() {
    return <Row className="playlist-item" type="flex" align="middle" justify="space-between" style={{ flexWrap: 'nowrap' }}>
            <Col><p className="playlist-item-name">Station <b>{this.props.station.number}</b></p>
              {this.props.role !== "candidate-2" && !this.props.hidden_station_names ? <p className="playlist-item-description">{this.props.station.name}</p> : ""}</Col>
            <Col><Button type="danger" size="small" shape="circle" icon="delete" onClick={() => this.props.delete(this.props.station.id)} /></Col>
          </Row>;
  }
}
export default connect(state => {
  return {
    hidden_station_names: state.hidden_station_names
  }
})(PlaylistItem);