import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon } from "antd"

const base_url = process.env.REACT_APP_IMAGE_ENDPOINT + "slider/";

class Slider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      slide: 0
    };
  }

  prevImage = () => {
    if (this.props.slider.slides[this.state.slide - 1] !== undefined)
      this.setState({ slide: this.state.slide - 1 });
  }

  nextImage = () => {
    if (this.props.slider.slides[this.state.slide + 1] !== undefined)
      this.setState({ slide: this.state.slide + 1 });
  }

  render() {
    return (
      <div className="slider">
        <div className="slider-image">
          <img src={base_url + this.props.slider.id + "/" + this.props.slider.slides[this.state.slide].file_name} alt={"slider-image"} className="slider-image" />
        </div>

        <div className="slider-description">
          <h3>{this.props.slider.slides[this.state.slide].headline}</h3>
          <div className="description" dangerouslySetInnerHTML={{ __html: this.props.slider.slides[this.state.slide].subhead }} />

          <div className="buttons">
            <Button shape="circle" onClick={() => this.prevImage()} ghost={this.props.slider.slides[this.state.slide - 1] === undefined} disabled={this.props.slider.slides[this.state.slide - 1] === undefined}><Icon type="left"/></Button>
            <Button shape="circle" onClick={() => this.nextImage()} ghost={this.props.slider.slides[this.state.slide + 1] === undefined} disabled={this.props.slider.slides[this.state.slide + 1] === undefined}><Icon type="right" /></Button>
          </div>
        </div>
      </div>
    )
  }
}

export default connect()(Slider);