import {
  FILTERS,
  HIDE_STATION_NAMES
} from "../constants/ActionTypes";

export const filters = (state = {}, action) => {
  switch (action.type) {
    case FILTERS:
      return action.value
    default:
      return state;
  }
}

export const hidden_station_names = (state = false, action) => {
  switch(action.type) {
    case HIDE_STATION_NAMES:
        return action.value
    default:
        return state;
  }
}