import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Form, Button, Input, Select, DatePicker, Upload, Icon } from "antd";
const FormItem = Form.Item;
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const domain = process.env.REACT_APP_API_DOMAIN;

class SettingsForm extends Component {
  submit = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSave(values);
      }
    });
  }

  set_medical_school = () => {
    this.props.form.resetFields(['state', 'state_id']);
    if (this.props.form.getFieldValue("medical_school_id") !== "other")
      this.props.form.setFields({medical_school_id: {value: null}});
  }

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    let medical_school_id = this.props.form.getFieldValue("medical_school_id");
    if (medical_school_id === undefined)
      medical_school_id = this.props.user.medical_school_id;

    let country_id = this.props.user.country_id;
    if (this.props.form.getFieldValue("country_id") !== undefined)
      country_id = this.props.form.getFieldValue("country_id");
    let country = null;
    if (country_id !== undefined)
      country = this.props.countries.find(country => country.id === country_id);

    let years = [];
    for (let i = new Date().getFullYear(); i < 2100; i ++)
      years.push(i);

    return (
      <Form className="account-form">
        <h1 style={{ textAlign: 'center' }}>Account Settings</h1>

        <FormItem label="Avatar">
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={apiEndpoint + "/user/upload_avatar"}
            beforeUpload={() => {}}
            onChange={this.props.uploadAvatar}
            headers={{Authorization: "Bearer " + this.props.token}}
          >
            {this.props.user.avatar.length > 0 ? <img src={domain + this.props.user.avatar} alt="avatar" style={{ maxHeight: "500px", maxWidth: "400px" }} /> : <Icon type="plus" />}
            {this.props.user.avatar.length > 0 && <Button type="primary" style={{marginTop: "2em"}}>Replace</Button>}
          </Upload>
        </FormItem>

        <FormItem label="ID">
          <Input size="large" value={this.props.user.display_id} disabled={true}></Input>
        </FormItem>

        <FormItem label="First Name">
          {getFieldDecorator("first_name", {
            rules: [{ required: true, message: "First Name is required" }],
            initialValue: this.props.user.first_name
          })(<Input
            size="large"
            placeholder="First Name"
          />)}
        </FormItem>
        <FormItem label="Surname">
          {getFieldDecorator("last_name", {
            rules: [{ required: true, message: "Surname is required" }],
            initialValue: this.props.user.last_name
          })(<Input
            size="large"
            placeholder="Surname"
          />)}
        </FormItem>
        <FormItem label="Username">
          {getFieldDecorator("username", {
            rules: [{ required: true, message: "Username is required" }],
            initialValue: this.props.user.username
          })(<Input
            size="large"
            placeholder="Create Username"
          />)}
        </FormItem>
        <FormItem label="Date of Birth">
          {getFieldDecorator("dob", {
            rules: [{ required: true, message: "Date of Birth is required" }],
            initialValue: moment(this.props.user.dob)
          })(<DatePicker placeholder="Date of Birth" size="large" format={"DD/MM/YYYY"} />)}
        </FormItem>
        <FormItem label="Email">
          {getFieldDecorator("email", {
            rules: [{ required: true, message: "Email is required" }, { type: "email", message: "Please enter a valid email address" }],
            initialValue: this.props.user.email
          })(<Input
            size="large"
            placeholder="Your Email"
          />)}
        </FormItem>
        <FormItem label="Country">
          {getFieldDecorator("country_id", {
            rules: [{ required: true, message: "Country is required" }],
            initialValue: this.props.user.country_id
          })(<Select placeholder="Country" size="large" onChange={this.set_medical_school}>
            {this.props.countries.map(value => {
              return <Select.Option value={value.id} key={"country-" + value.id}>{value.name}</Select.Option>
            })}
          </Select>)}
        </FormItem>
        { country === null || country.states.length === 0 ?
          <FormItem label="State/Province">
            {getFieldDecorator("state", {
              initialValue: this.props.user.state_other
            })(
              <Input
                size="large"
                placeholder="State/Province"
              />)}
          </FormItem> :
          <FormItem label="State/Province">
            {getFieldDecorator("state_id", {
              rules: [{ required: true, message: "State is required" }],
              initialValue: this.props.user.state_id !== null ? this.props.user.state_id : null
            })(
              <Select placeholder="Please Select" size="large">
                {country.states.map(value => {
                  return <Select.Option value={value.id} key={"state-" + value.id}>{value.name}</Select.Option>
                })}
              </Select>)}
          </FormItem> }
        <FormItem label="Medical School">
          {getFieldDecorator("medical_school_id", {
            rules: [{ required: true, message: "Medical School is required" }],
            initialValue: this.props.user.medical_school_id !== null ? this.props.user.medical_school_id : "other"
          })(
            <Select
              placeholder="Medical School"
              size="large"
              showSearch
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {this.props.medical_schools.map(value => {
                  if (country_id !== undefined && country_id === value.country_id)
                    if (this.props.form.getFieldValue("state_id") !== undefined) {
                      if (this.props.form.getFieldValue("state_id") === value.state_id)
                        return <Select.Option value={value.id} key={"medical_school-" + value.id}>{value.name}</Select.Option>
                    }
                    else
                      return <Select.Option value={value.id} key={"medical_school-" + value.id}>{value.name}</Select.Option>
                  else if (country_id === undefined && value.country_id === this.props.user.country_id)
                    if (this.props.user.state_id !== null) {
                      if (this.props.user.state_id === value.state_id)
                        return <Select.Option value={value.id} key={"medical_school-" + value.id}>{value.name}</Select.Option>
                    }
                    else
                      return <Select.Option value={value.id} key={"medical_school-" + value.id}>{value.name}</Select.Option>
                  return null;
                })}
                <Select.Option value="other">Other</Select.Option>
            </Select>)}
        </FormItem>
        <FormItem label="Graduation Year (expected)">
          {getFieldDecorator("graduation_year", {
            rules: [{ required: true, message: "Graduation Year is required" }],
            initialValue: this.props.user.graduation_year
          })(
            <Select
              placeholder="Please Select"
              size="large"
              showSearch>
              {years.map((value, index) => {
                return <Select.Option value={value} key={"year-" + index}>{value}</Select.Option>
              })}
            </Select>)}
        </FormItem>

        {medical_school_id === "other" || medical_school_id === null ?
          <FormItem label="Medical School Name">
            {getFieldDecorator("medical_school_other", {
              rules: [{ required: true, message: "Medical School Name is required" }],
              initialValue: this.props.user.medical_school_other
            })(
              <Input
                size="large"
                placeholder="Medical School Name"
              />)}
          </FormItem>
        : ""}

        <Button
          type="primary"
          htmlType="submit"
          disabled={hasErrors(getFieldsError())}
          onClick={this.submit}
          icon="check"
          size="large"
          block
        >
          Update
        </Button>
      </Form>
    )
  }
}

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

export default connect(state => {
  return {
    token: state.auth.token
  }
})(Form.create({ name: 'settings' })(SettingsForm));