import React, { Component } from "react";
import { connect } from "react-redux";

const base_url = process.env.REACT_APP_IMAGE_ENDPOINT + "gallery/";

class Gallery extends Component {
    render() {
        return <div className="gallery">
            {this.props.assets.map((value, index) => {
                return <img src={base_url + value.file_name} alt={"gallery"} key={"gallery-image-" + index} className="gallery-image" />
            })}
        </div>
    }
}

export default connect()(Gallery);