import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Icon, message } from "antd";
import Countdown from "./Countdown";

import ringing_noise from "../../static/audio/small-bell-ringing.wav";  //Originally from http://www.orangefreesounds.com/small-bell-ringing/

class CountdownDisplay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current_countdown: "reading",
      bell_visible: false,
      update: false
    }
  }

  componentDidUpdate = () => {
    let timing = this.props.timing;
    if (!timing.disabled)
    {
      if (timing.reading_time === 0 && this.state.current_countdown === "reading")
        this.setState({current_countdown: "station"});
      else if (timing.reading_time > 0 && this.state.current_countdown === "station")
        this.setState({current_countdown: "reading"});
      else if (timing.station_time === 0 && this.state.current_countdown === "station")
        this.setState({current_countdown: "questions"});
      else if (timing.station_time > 0 && this.state.current_countdown === "questions")
        this.setState({current_countdown: "station"});
      else if (timing.question_time === 0 && this.state.current_countdown === "questions")
        this.setState({current_countdown: "feedback"});
      else if (timing.question_time > 0 && this.state.current_countdown === "feedback")
        this.setState({current_countdown: "questions"});
      else if (timing.feedback_time === 0 && this.state.current_countdown === "feedback")
        this.setState({current_countdown: "complete"});
    }
    else
    {
      if (timing.reading_time === false && this.state.current_countdown !== "reading")
        this.setState({current_countdown: "reading"})
      else if (timing.reading_time === 0 && timing.station_time === false && this.state.current_countdown !== "station")
        this.setState({current_countdown: "station" })
      else if (timing.station_time === 0 && timing.question_time === false && this.state.current_countdown !== "questions")
        this.setState({current_countdown: "questions" })
      else if (timing.question_time === 0 && timing.feedback_time === false && this.state.current_countdown !== "feedback")
        this.setState({current_countdown: "feedback"})
    }
  }

  componentDidMount = () => {
    let timing = this.props.timing;
    if (timing.reading_time < 1 && timing.station_time > 0)
      this.setState({current_countdown: "station"})
    else if (timing.station_time < 1 && timing.question_time > 0)
      this.setState({current_countdown: "questions"})
    else if (timing.question_time < 1 && timing.feedback_time > 0)
      this.setState({current_countdown: "feedback"})
    else if (timing.reading_time === 0 && timing.station_time === 0 && timing.question_time === 0 && timing.feedback_time === 1)
      this.setState({current_countdown: "complete"});
  }

  finish = (countdown, previous, alarm = true) => {
    if (alarm)
    {
      let audio = new Audio(ringing_noise);
      let promise = audio.play();
      if (promise !== undefined) {
        promise.catch(error => {
          if (countdown === "station")
            message.info("Station Time!", 5);
          else if (countdown === "questions")
            message.info("Question Time!", 5);
          else if (countdown === "feedback")
            message.info("Feedback Time!", 5);
        });
      }
    }
    this.setState({ current_countdown: countdown });
    this.props.updateTiming(previous, 0)
  }

  active = (title) => {
    if (title === "Reading Time" && this.state.current_countdown === "reading")
      return true;
    else if (title === "Station Time" && this.state.current_countdown === "station")
      return true;
    else if (title === "Question Time" && this.state.current_countdown === "questions")
      return true;
    else if (title === "Feedback Time" && this.state.current_countdown === "feedback")
      return true;

    return false;
  }

  onTick = (type, value) => {
    this.props.updateTiming(type, value)
    this.setState({ update: !this.state.update })
  }

  render() {
    let timing = this.props.timing;

    if (timing === null)
      return "";

    let countdowns = [];
    let reading_countdown = <Countdown
                              timing={timing.reading_time}
                              onComplete={(alarm) => this.finish("station", "reading", alarm)}
                              onTick={(value) => this.onTick("reading", value)}
                              show={this.props.show}
                              paused={this.props.paused}
                              active={this.state.current_countdown === "reading"}
                              key="reading-countdown"
                              name="reading"
                            />;
    let station_countdown = <Countdown
                              timing={timing.station_time}
                              onComplete={(alarm) => this.finish("questions", "station", alarm)}
                              onTick={(value) => this.onTick("station", value)}
                              show={this.props.show}
                              paused={this.props.paused}
                              active={this.state.current_countdown === "station"}
                              key="station-countdown"
                              name="station"
                            />;
    let question_countdown = <Countdown
                              timing={timing.question_time}
                              onComplete={(alarm) => this.finish("feedback", "question", alarm)}
                              onTick={(value) => this.onTick("question", value)}
                              show={this.props.show}
                              paused={this.props.paused}
                              active={this.state.current_countdown === "questions"}
                              key="questions-countdown"
                              name="questions"
                            />;
    let feedback_countdown = <Countdown
                              timing={timing.feedback_time}
                              onComplete={(alarm) => this.finish("complete", "feedback", alarm)}
                              onTick={(value) => this.onTick("feedback", value)}
                              show={this.props.show}
                              paused={this.props.paused}
                              active={this.state.current_countdown === "feedback"}
                              key="feedback-countdown"
                              name="feedback"
                            />;
    countdowns.push(reading_countdown);
    countdowns.push(station_countdown);
    countdowns.push(question_countdown);
    countdowns.push(feedback_countdown);

    let labels = ["Reading Time", "Station Time", "Question Time", "Feedback Time"];
    return <div>
      {countdowns.map((value, index) => {
        return <Row type="flex" justify="space-between" align="middle" key={"timer-row-" + index} className={this.active(labels[index]) ? "timer-row-active" : "timer-row"}>
              <Col span={18}>
                <h3 className="current-state"><Icon type="clock-circle" style={{ marginRight: '1em' }} />{labels[index]}</h3>
              </Col>
              <Col span={6}>
                {value}
              </Col>
            </Row>
      })}
    </div>
  }
}

export default connect()(CountdownDisplay);