import React, { Component } from "react";
import { connect } from "react-redux";
import { HorizontalBar } from 'react-chartjs-2';
import { Spin } from "antd";
import { breakUpLabel } from "../../actions/functions";

class AverageChart extends Component {
  render() {
    if (this.props.data === null)
      return <Spin>
        <div className="chart-container">
            <HorizontalBar
                data={{labels: [], datasets: []}}
                width={100}
                height={50}
                options={{
                    maintainAspectRatio: false,
                    resposive: true,
                    scales: {
                        xAxes: [{
                            maxBarThickness: 60,

                            ticks: {
                                max: 100,
                                beginAtZero: true
                            }
                        }]
                    },
                    title: { display: true, text: this.props.label, fontSize: 20},
                    tooltips: {
                        callbacks: {
                            label: function (tooltipItem) {
                                return tooltipItem.xLabel + "%";
                            }
                        }
                    }
                }}
            />
            </div>
        </Spin>

    if (this.props.data.length === 0)
      return <p>There is currently no data available for {this.props.label}</p>
    if (this.props.order === "strengths")
      this.props.data.sort(function(a, b) { return b.average - a.average});
    else if (this.props.order === "weaknesses")
      this.props.data.sort(function(a, b) { return a.average - b.average});

    let data = {
        labels: this.props.data.map((value) => {
            return breakUpLabel(value.label);
        }),
        datasets: [
            {
                label: "Average",
                backgroundColor: '#66B3FF',
                borderColor: '#3399FF',
                borderWidth: 1,
                hoverBackgroundColor: '#FFA366',
                hoverBorderColor: '#FF8533',
                data: this.props.data.map((value) => { return value.average.toFixed(2) })
            }
        ]
    };

    return (
        <div className="chart-container">
            <HorizontalBar
                data={data}
                width={100}
                height={50}
                options={{
                maintainAspectRatio: false,
                resposive: true,
                scales: {
                    xAxes: [{
                        maxBarThickness: 60,

                        ticks: {
                            max: 100,
                            beginAtZero: true,
                            callback: function(value) {
                            return value + "%";
                            }
                        },
                    }],
                    yAxes: [{
                        maxBarThickness: 60
                    }]
                },
                title: { display: true, text: this.props.label, fontSize: 20},
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem) {
                            return tooltipItem.xLabel + "%";
                        }
                    }
                }
                }}
            />
        </div>
    )
  }
}

export default connect()(AverageChart);