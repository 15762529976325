import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  UPDATE_TOKEN,
  TOKEN_EXPIRED,
  RESET_REQUEST,
  RESET_REQUEST_SUCCESS,
  RESET_REQUEST_ERROR,
  SEND_RESET,
  RESET_SUCCESS,
  RESET_ERROR
} from "../constants/ActionTypes";

const initialState = {
  isFetching: false,
  isAuthenticated: localStorage.getItem("token") ? true : false,
  token: localStorage.getItem("token"),
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
        token: null,
        success: null,
        message: null
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        token: action.token,
        success: true,
        message: "You have been successfully logged in."
      };
    case TOKEN_EXPIRED:
      return {
        ...state,
        isFetching: true,
        token: null,
        isAuthenticated: false
      };
    case UPDATE_TOKEN:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        token: action.token
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        token: null,
        success: false,
        message: `${action.statusText} [${action.status}]`
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        message: "You have logged out"
      };
    case RESET_REQUEST:
      return {
        ...state
      };
    case RESET_REQUEST_SUCCESS:
      return {
        ...state,
        success: true,
        message: "Your reset link is valid for 30 minutes. Please check your email."
      };
    case RESET_REQUEST_ERROR:
      return {
        ...state,
        success: false,
        message: `${action.statusText} [${action.status}]`
      };
    case SEND_RESET:
      return {
        ...state
      };
    case RESET_SUCCESS:
      return {
        ...state,
        success: true,
        message: "Your password has been reset.",
      };
    case RESET_ERROR:
      return {
        ...state,
        success: false,
        message: `${action.statusText} [${action.status}]`
      };
    default:
      return state;
  }
};

export default auth;
