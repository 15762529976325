import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Input, Checkbox, Button } from "antd";

class MarkingDisplay extends Component {

  total() {
    let score = 5;

    this.props.guides.map((question) => {
      score +=  question.max_marks;
    });

    return score;
  }

  render() {
    let answers = [];
    let questions = this.props.guides.map((question) => {
      let checkboxes = [];
      for (let i = 0; i < question.max_marks; i++)
      {
        let answer = this.props.marks.find(score => score.guide_id === question.id);
        if (answer !== undefined && i < answer.points)
          answers.push(question.id + "_" + i)
        checkboxes.push(<Checkbox checked={true} value={question.id + "_" + i} className="marking-checkbox" key={"checkbox-" + question.id + "-" + i} />);
      }

      return <Row type="flex" key={question.id} className="marking-guide-content">
        <Col className="marking-guide-checkboxes">{checkboxes}</Col>
        <Col className="marking-guide-task"><span dangerouslySetInnerHTML={{ __html: question.task }} /></Col>
      </Row>
    });

    return (
      <div id="marking-guide">
        <Checkbox.Group value={answers}>
          {questions}
        </Checkbox.Group>
        <Row type="flex" align="middle" style={{ marginTop: '1em' }}>
          <Col xs={12} sm={6}>
            <p>Global score:</p>
          </Col>
          <Col xs={12} sm={18} className="marking-guide-input">
            <Input value={this.props.globalScore} addonAfter={"/5"} />
          </Col>
        </Row>

        <Row type="flex" align="middle" style={{ marginTop: '1em' }}>
          <Col xs={12} sm={6}>
            <p>Cumulative total:</p>
          </Col>
          <Col xs={12} sm={18} className="marking-guide-input">
            <Input value={answers.length + this.props.globalScore} addonAfter={"/" + this.total()} disabled />
          </Col>
        </Row>
      </div>
    )
  }
}

export default connect()(MarkingDisplay);