import React, { Component } from "react";
import { connect } from "react-redux";
import { APITokenless } from '../../actions/API.js';
import { Spin, Button } from "antd";
import { Link } from "react-router-dom";

class ActivatePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      message: null,
      success: false
    }
  }

  componentDidMount = async() => {
    let code = this.props.match.params.code;
    let activate = await this.props.dispatch(APITokenless("/activate", "POST", {code}));
    if (!this.unmounted)
      this.setState({loading: false, message: activate.message, success: activate.success});
  }

  componentWillUnmount = () => {
    this.unmounted = true;
  }

  render() {
    return (
        <div id="activate">
            <div className={`container fixed`}>
                {this.state.loading ? <h2>Please wait while we activate your account...</h2> : ""}
                <Spin spinning={this.state.loading}>
                <div className="small-container">
                    { this.state.message !== null ?
                        <p>{this.state.message}</p>
                        : ""
                    }
                    { this.state.success ?
                        <Link to="/login" style={{ display: 'table', margin: '2em auto'}}>
                            <Button type="primary" size="large" style={{ width: '200px' }}>Log In</Button>
                        </Link>
                    : ""}
                </div>
                </Spin>
            </div>
        </div>
    )
  }
}

export default connect()(ActivatePage);