import React, { Component } from 'react';
import { connect } from "react-redux";
import { Menu, Icon, Button, Drawer } from 'antd';
import { Link } from "react-router-dom";
import { push } from "react-router-redux";
import GroupStudy from "./GroupStudy";

import heading from "../../static/images/oscebank-logo.svg";

import flagAU from "../../static/images/icon-flag-au.png";
import flagUS from "../../static/images/icon-flag-us.png";
import flagGB from "../../static/images/icon-flag-gb.png";

const country = process.env.REACT_APP_COUNTRY;
const environment = process.env.REACT_APP_ENVIRONMENT;
const SubMenu = Menu.SubMenu;

class Header extends Component {
  state = {
    collapsed: true,
    group_visible: false
  }

  homeCurrent = () => {
    this.setState({
      current: 'home'
    });
  }

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  closeGroup = () => {
    this.setState({
      group_visible: false
    });
  }

  getRoute = () => {  //From the route we're on, calculate the equivalent key
    let key = "home";
    switch (this.props.path) {
      case "/profile":
        key = "profile";
        break;
      case "/search":
      case "/study":
      case "/station/:code":
        key = "study";
        break;
      case "/stats":
        key = "stats";
        break;
      case "/settings":
        key = "settings";
        break;
      case "/study-groups":
        key = "study-groups";
        break;
      case "/subscriptions":
        key = "subscriptions";
        break;
      case "/contact":
        key = "contact";
        break;
      case "/preview":
        key = "preview";
        break;
      case "/login":
        key = "login";
        break;
      case "/home-page":
        key = "home-page";
        break;
      case "/subscription-plans":
      case "/signup":
        key = "subscription-plans";
        break;
      case "/free-trial":
        key = "free-trial";
        break;
      case "about-us":
        key = "about-us";
        break;
      default:
        break;
    }
    return [key];
  }

  render() {
    let logged_in = localStorage.getItem("token") !== null;
    let groupStudyPages = ['/profile', '/study-groups', '/study', '/station/:code'];
    let flags = [
      {
        'code': 'AU',
        'name': 'Australia',
        'url': environment == "production" ? 'https://oscebank.com.au/' : "http://au-staging.oscebank.com/",
        'image': flagAU
      },
      {
        'code': 'US',
        'name': 'United States',
        'url': environment == "production" ? 'https://oscebank.com/' : "http://us-staging.oscebank.com/",
        'image': flagUS
      },
      {
        'code': 'GB',
        'name': 'United Kingdom',
        'url': environment == "production" ? 'https://oscebank.co.uk' : "http://uk-staging.oscebank.com/",
        'image': flagGB
      }
    ];
    let currentCountry = flags.find(flag => flag.code === country);

    return (
      <div className="header">
        <Link to={"/"} onClick={this.homeCurrent}>
          <img src={heading} id="osce-heading" alt="OSCEbank logo" />
        </Link>

        {logged_in ?
          <Menu selectedKeys={this.getRoute()} mode="horizontal" className="desktop-menu">
            <Menu.Item key="logout" className="login-menu-item">
              <Link to={"/logout"}>
                <Icon type="logout" />Logout
              </Link>
            </Menu.Item>
            <Menu.Item key="contact">
              <Link to={"/contact"}>
                <Icon type="phone" />Contact
              </Link>
            </Menu.Item>
            <Menu.Item key="stats">
              <Link to="/stats">
                <Icon type="bar-chart" />My Stats
              </Link>
            </Menu.Item>
            <Menu.Item key="study">
              <Link to={"/study"}>
                <Icon type="book" />Study
              </Link>
            </Menu.Item>
            <SubMenu
              key="profile-menu"
              title={<span><Icon type="user" /><span>Profile</span></span>}
              onTitleClick={() => this.props.dispatch(push("/profile"))}
            >
              <Menu.Item key="study-groups">
                <Link to="/study-groups">
                  <Icon type="team" />Study Groups
                </Link>
              </Menu.Item>
              <Menu.Item key="settings">
                <Link to="/settings">
                  <Icon type="setting" />Account Settings
                </Link>
              </Menu.Item>
              <Menu.Item key="subscriptions">
                <Link to="/subscriptions">
                  <Icon type="dollar" />Manage Subscriptions
                </Link>
              </Menu.Item>
            </SubMenu>
            {this.props.groupStudy && groupStudyPages.indexOf(this.props.path) === -1 ?
              <Menu.Item key="group-study" className="group-study-select" onClick={() => this.setState({ group_visible: !this.state.group_visible })}>
                <Icon type="team" />Group Study
              </Menu.Item>
            : ''}
            <SubMenu
              key="country-selector"
              title={
                <button className="current-country">
                  <img src={currentCountry.image} alt={currentCountry.name} />
                </button>
              }
            >
              {flags.map((flag) => {
                return (<Menu.Item key={'country-' + flag.code}>
                  <a href={country == flag.code ? '#' : flag.url}>
                    <img src={flag.image} alt={flag.name} />
                    {flag.name}
                  </a>
                </Menu.Item>);
              })}
            </SubMenu>
          </Menu>
        :
          <Menu selectedKeys={this.getRoute()} mode="horizontal" className="desktop-menu">
            <Menu.Item key="login" className="login-menu-item">
              <Link to="/login">Login</Link>
            </Menu.Item>
            <Menu.Item key="free-trial">
              <Link to="/free-trial">Trial</Link>
            </Menu.Item>
            <Menu.Item key="subscription-plans">
              <Link to={"/subscription-plans"}>Subscribe</Link>
            </Menu.Item>
            <Menu.Item key="about-us">
              <Link to={"/about-us"}>About</Link>
            </Menu.Item>
            <Menu.Item key="home">
              <Link to="/">Home</Link>
            </Menu.Item>
            <SubMenu
              key="country-selector"
              title={
                <button className="current-country">
                  <img src={currentCountry.image} alt={currentCountry.name} />
                </button>
              }
            >
              {flags.map((flag) => {
                return (<Menu.Item key={'country-' + flag.code}>
                  <a href={country == flag.code ? '#' : flag.url}>
                    <img src={flag.image} alt={flag.name} />
                    {flag.name}
                  </a>
                </Menu.Item>);
              })}
            </SubMenu>
          </Menu>
        }

        <Menu mode="horizontal" className="mobile-country-selector">
          <SubMenu
            key="country-selector"
            title={
              <button className="current-country">
                <img src={currentCountry.image} alt={currentCountry.name} />
              </button>
            }
          >
            {flags.map((flag) => {
              return (<Menu.Item key={'country-' + flag.code}>
                <a href={country == flag.code ? '#' : flag.url}>
                  <img src={flag.image} alt={flag.name} />
                  {flag.name}
                </a>
              </Menu.Item>);
            })}
          </SubMenu>
        </Menu>

        <Button type="primary" className="menu-trigger" onClick={this.toggleCollapsed}>
          <Icon type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'} />
        </Button>

        {logged_in ?
          <Menu onClick={this.handleClick} selectedKeys={this.getRoute()} mode="inline" className={'mobile-menu ' + this.state.collapsed}>
            {this.props.groupStudy && groupStudyPages.indexOf(this.props.path) === -1 ?
              <Menu.Item key="group-study" className="group-study-select" onClick={() => this.setState({ group_visible: !this.state.group_visible })}>
                <Icon type="team" />Group Study
              </Menu.Item>
            : ''}
            <Menu.Item key="profile" onClick={this.toggleCollapsed}>
              <Link to="/profile">
                <Icon type="user" />Profile
              </Link>
            </Menu.Item>
            <Menu.Item key="study" onClick={this.toggleCollapsed}>
              <Link to={"/study"}>
                <Icon type="book" />Study
              </Link>
            </Menu.Item>
            <Menu.Item key="stats" onClick={this.toggleCollapsed}>
              <Link to="/stats">
                <Icon type="bar-chart" />My Stats
              </Link>
            </Menu.Item>
            <Menu.Item key="contact" onClick={this.toggleCollapsed}>
              <Link to={"/contact"}>
                <Icon type="phone" />Contact
              </Link>
            </Menu.Item>
            <Menu.Item key="settings" onClick={this.toggleCollapsed}>
              <Link to="/settings">
                <Icon type="setting" />Account Settings
              </Link>
            </Menu.Item>
            <Menu.Item key="study-groups" onClick={this.toggleCollapsed}>
              <Link to="/study-groups">
                <Icon type="team" />Study Groups
              </Link>
            </Menu.Item>
            <Menu.Item key="subscriptions" onClick={this.toggleCollapsed}>
              <Link to="/subscriptions">
                <Icon type="dollar" />Manage Subscriptions
              </Link>
            </Menu.Item>
            <Menu.Item key="logout" onClick={this.toggleCollapsed} className="login-menu-item">
              <Link to={"/logout"}>
                <Icon type="logout" />Logout
              </Link>
            </Menu.Item>
          </Menu>
        :
          <Menu onClick={this.handleClick} selectedKeys={this.getRoute()} mode="inline" className={'mobile-menu ' + this.state.collapsed}>
            <Menu.Item key="home" onClick={this.toggleCollapsed}>
              <Link to="/">
                <Icon type="home" />Home
              </Link>
            </Menu.Item>
            <Menu.Item key="about-us" onClick={this.toggleCollapsed}>
              <Link to={"/about-us"}>
                <Icon type="info-circle" />About
              </Link>
            </Menu.Item>
            <Menu.Item key="subscription-plans" onClick={this.toggleCollapsed}>
              <Link to={"/subscription-plans"}>
                <Icon type="credit-card" />Subscribe
              </Link>
            </Menu.Item>
            <Menu.Item key="free-trial" onClick={this.toggleCollapsed}>
              <Link to="/free-trial">
                <Icon type="eye" />Trial
              </Link>
            </Menu.Item>
            <Menu.Item key="login" className="login-menu-item" onClick={this.toggleCollapsed}>
              <Link to="/login">
                <Icon type="user" />Login
              </Link>
            </Menu.Item>
          </Menu>
        }

        {localStorage.getItem("update_terms") && this.props.path !== "terms-and-conditions" && localStorage.getItem("token") ?
          <div className="t-and-c-reminder">We have updated our terms and conditions. Please <Link to="/terms-and-conditions">review them and consent</Link> if you wish to continue using OSCEbank.</div>
        : ''}

        {this.props.groupStudy ?
          <Drawer
            title="Group Study"
            placement="right"
            closable={true}
            onClose={this.closeGroup}
            visible={this.state.group_visible}
            className="time-nav sidenav"
            mask={true}
            width="356"
          >
            <GroupStudy layout="vertical" displayStation={true} />
          </Drawer>
        : ''}
      </div>
    );
  }
}

export default connect(state => {
  return {
    groupStudy: state.groupStudy !== null,
  }
})(Header);
