import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getRoleDetails } from '../../actions/functions';
import { end, beginStation } from '../../actions/Echo';
import { Button, message } from 'antd';
import { push } from 'react-router-redux';
import { setPlaylist } from '../../constants/ActionTypes';

const domain = process.env.REACT_APP_API_DOMAIN;

class GroupStudy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playlist: null
    }
  }

  start = (station) => {
    this.props.dispatch(beginStation(station));
  }

  join = (station) => {
    if (this.props.role === null)
      message.warning('Please choose a role before joining a station');
    else
    {
      this.props.dispatch(setPlaylist(null));
      this.props.dispatch(push('/station/' + station));
    }
  }

  startPlaylist = (playlist) => {
    this.props.dispatch(setPlaylist(playlist.playlist));
    this.start(playlist.playlist.items[0].number);
  }

  viewPlaylist = (playlist) => {
    if (this.state.playlist === playlist)
      this.setState({ playlist: null });
    else
      this.setState({ playlist });
  }

  addToPlaylist = (station) => {
    if (station.station_id !== undefined) //So that we can keep accessing the same property while in Search.js
      station.id = station.station_id;
    this.props.addToPlaylist(station);
  }

  render() {
    return (
      <div className={"group-study-details " + this.props.layout}>
        <div className="currently-studying">
          <h2>Currently Studying</h2>

          <div className="current-study-group">
            <h3>{this.props.groupName}</h3>

            <div className="members">
              {this.props.members.map((value, index) => {
                let details = getRoleDetails(value.role);
                return (
                  <div className="member" key={"member-" + index}>
                    <div className="avatar">{value.avatar.length > 0 ? <img src={domain + value.avatar} /> : ''}</div>

                    <div className="member-details">
                      <div className="member-name">{value.name}</div>

                      <div className="member-role">
                        {details !== null ? <img src={details.img} /> : ''}
                        {details !== null ? details.role_name : ''}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="buttons">
              <Button type="primary" onClick={() => this.props.dispatch(end())}>Leave</Button>
            </div>
          </div>
        </div>

        {this.props.displayStation && this.props.stations.length > 0 ?
          <div className="suggested-stations">
            <h2>Suggested Stations</h2>

            <div className="stations-list">
              {this.props.stations.map((value, index) => {
                return (
                  <div className="suggested-station" key={"suggested-stations-" + index}>
                    <div className="station-details">
                      <p className="station-number">{value.station_no}</p>
                      {this.props.role !== 'candidate-2' ? <p className="station-name">{value.station_name}</p> : ''}
                      <p className="suggestor">Suggested by {value.name}</p>
                    </div>
                    {this.props.role == 'examiner' ?
                      <div className="station-actions">
                        <Button.Group>
                          <Button type="primary" size="small" onClick={() => this.start(value.station_no)}>Start</Button>
                          <Button type="primary" size="small" onClick={() => this.addToPlaylist(value)}>+</Button>
                        </Button.Group>
                      </div>
                    : ''}
                  </div>
                )
              })}
            </div>
          </div>
        : ''}

        {this.props.displayStation && this.props.suggestedPlaylists.length > 0 ?
          <div className="suggested-playlists">
            <h2>Suggested Playlists</h2>

            {this.props.suggestedPlaylists.map((value, index) => {
              return (
                <div className="playlist" key={"suggested-playlists-" + index}>
                  <div className="playlist-details">
                    <p><b>{value.playlist.name}</b> ({value.playlist.items.length} {value.playlist.items.length == 1 ? 'Station' : 'Stations'})</p>
                    <span className="suggestor">Suggested by {value.name}</span>
                  </div>
                  <div className="playlist-actions">
                    {this.props.role == 'examiner' ?
                      <Button.Group>
                        <Button type="primary" size="small" onClick={() => this.viewPlaylist(value.user)}>{this.state.playlist === value.user ? 'Hide' : 'View'}</Button>
                        <Button type="primary" size="small" onClick={() => this.startPlaylist(value)}>Start</Button>
                      </Button.Group>
                    : <Button type="primary" size="small" onClick={() => this.viewPlaylist(value.user)}>{this.state.playlist === value.user ? 'Hide' : 'View'}</Button> }
                  </div>
                  <div className="playlist-stations">
                    {this.state.playlist === value.user ? value.playlist.items.map((station, playlist_index) => {
                      return (
                        <div className="playlist-station" key={"playlist-items-" + playlist_index}>
                          <b className="station-number">{station.number}</b>
                          <div className="station-name">{this.props.role !== 'candidate-2' ? station.name : ''}</div>
                          {this.props.role == 'examiner' ?
                            <div className="station-actions">
                              <Button type="primary" size="small" ghost onClick={() => this.addToPlaylist(station)}>+</Button>
                            </div>
                          : ''}
                        </div>
                      )
                    }) : ''}
                  </div>
                </div>
              )
            })}
          </div>
        : ''}

        {this.props.displayStation && this.props.currentStation !== null ?
          <div className="current-station">
            <h2>Current Station</h2>

            <div className="current-station-details">
              <div className="current-station-number">
                <b>{this.props.currentStation}</b>
              </div>
              <div className="current-station-actions">
                <Button type="primary" size="small" onClick={() => this.join(this.props.currentStation)}>Join</Button>
              </div>
            </div>
          </div>
        : ''}
      </div>
    )
  }
}

export default connect(state => {
  return {
    groupStudy: state.groupStudy,
    groupName: state.groupName,
    members: state.members,
    groupUpdateFlag: state.groupUpdateFlag,
    stations: state.stations,
    stationsUpdateFlag: state.stationsUpdateFlag,
    suggestedPlaylists: state.groupPlaylists,
    playlistsUpdateFlag: state.groupPlaylistsUpdateFlag,
    currentStation: state.currentStation,
    playlist: state.playlist,
    role: state.role,
    roleUpdated: state.roleFlag
  }
})(GroupStudy);
