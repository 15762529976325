import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Input } from "antd";
import { API } from '../../../actions/API.js';
import Percentage from "../../Partials/Percentage"

class StationStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mark: props.score.length + parseInt(props.globalScore, 10),
    }

    let max = 0;
    this.props.marking.map((value) => {
      return max += value.max_marks;
    });
    this.max_marks = max;
  }

  setStatus = async (status) => {
    if(!this.props.preview)
    {
      if (this.props.role === "candidate-1" || this.props.role === "candidate-2")
      {
        this.setState({ loading: true });
        await this.props.dispatch(API("/station/" + this.props.stationId + "/mark", "POST", { mark: this.state.mark, notes: this.props.notes, examiner_notes: this.props.examiner_notes, scores: this.props.score }));
        this.setState({ loading: false });
      }
      await this.props.dispatch(API("/station/" + this.props.stationId + "/status", "POST", { status }));
    }
    this.props.finish();
  }

  render() {
    return (
      <div className="station-marking">
        <h3>Please enter the mark you received for this station.</h3>
        <Input disabled value={this.state.mark} addonAfter={"/" + (parseInt(this.max_marks, 10) + 5)} style={{ margin: "1em 0", width: "150px" }} />
        <Percentage mark={this.state.mark} max_marks={this.max_marks + 5} />

        <h3>Please choose a status regarding your completion of this station:</h3>
        <div className="centered-buttons">
          <Button onClick={() => this.setStatus("attempt_again")}>Mark as attempt again</Button>
          <Button onClick={() => this.setStatus("complete")} type="primary">Mark as complete</Button>
          <Button onClick={() => this.setStatus("hidden")} className="danger-button">Don't save stats</Button>
        </div>
      </div>
    )
  }
}

export default connect(state => {
  return {
    role: state.role,
  }
})(StationStatus);