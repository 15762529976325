import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Input, Checkbox, Button, Divider } from "antd";
import Notes from "./Notes"
import ControlCandidates from "./ControlCandidates"

class MarkingGuide extends Component {
  updateGlobalScore = (e) => {
    let { value } = e.target;
    if (!Number.isNaN(value)) {
      if (value === "")
        this.props.onChange(this.props.answers, 0, this.props.cumulative_score);
      else {
        value = parseInt(value);
        if (Number.isInteger(value) && value < (6)) {
          this.props.onChange(this.props.answers, value, this.props.cumulative_score);
        }
      }
    }
  }

  updateCumulativeScore = (e, max_marks) => {
    let { value } = e.target;
    if (!Number.isNaN(value)) {
      if (value === "")
        this.props.onChange(this.props.answers, this.props.global_score, 0);
      else {
        value = parseInt(value);
        if (Number.isInteger(value) && value < (max_marks + 1)) {
          this.props.onChange(this.props.answers, this.props.global_score, value);
        }
      }
    }
  }

  selectAll = () => {
    let answers = [];
    this.props.questions.forEach((value) => {
      for(let i = 0; i < value.max_marks; i++)
        answers.push(value.id + "_" + i);
    });
    this.props.onChange(answers, this.props.global_score, this.props.cumulative_score);
  }

  render() {
    if (this.props.questions === null)
      return "";
    if (this.props.questions.length < 1)
      return <p>No marking guide questions have been added for this station yet.</p>;
    let max_marks = 0;

    let questions = this.props.questions.map((question) => {
      let checkboxes = [];
      max_marks += question.max_marks;
      for (let i = 0; i < question.max_marks; i++)
        checkboxes.push(<Checkbox value={question.id + "_" + i} key={"checkbox-" + question.id + "-" + i} />);

      return <Row type="flex" key={question.id} className="marking-guide-content">
                <Col className="marking-guide-checkboxes">{checkboxes}</Col>
                <Col className="marking-guide-task"><span dangerouslySetInnerHTML={{ __html: question.task }} /></Col>
            </Row>
    });

    return (
      <div id="marking-guide">
        <Button.Group>
          <Button type="primary" onClick={this.selectAll}>Select All</Button>
          <Button type="primary" onClick={() => this.props.onChange([], this.props.global_score, this.props.cumulative_score)}>Deselect All</Button>
        </Button.Group>

        <Checkbox.Group value={this.props.answers} onChange={(changedValues) => this.props.onChange(changedValues, this.props.global_score, this.props.cumulative_score)}>
          {questions}
        </Checkbox.Group>

        <Row type="flex" align="middle" style={{ marginTop: '1em' }}>
          <Col xs={12} sm={6}>
            <p>Global score:</p>
          </Col>
          <Col xs={12} sm={18} className="marking-guide-input">
            <Input onChange={(e) => this.updateGlobalScore(e)} value={this.props.global_score} addonAfter={"/5"}/>
          </Col>
        </Row>

        <Row type="flex" align="middle" style={{ marginTop: '1em' }}>
          <Col xs={12} sm={6}>
            <p>Cumulative total:</p>
          </Col>
          <Col xs={12} sm={18} className="marking-guide-input">
            <Input value={this.props.answers.length + parseInt(this.props.global_score, 10)} addonAfter={"/" + parseInt(max_marks + 5, 10)} disabled />
          </Col>
        </Row>

        {this.props.groupStudy ? <Row>
          <Divider />
          <h3>Examiner Notes</h3>
          <Notes notes={this.props.notes} updateNotes={this.props.updateNotes} />
        </Row> : ""}

        {this.props.groupStudy ?
          <ControlCandidates
            removeCandidate={(id, group) => this.props.removeCandidate(id, group)}
            addCandidate={(user) => this.props.addCandidate(user)}
            candidate={this.props.candidate}
          />
        : ""}
      </div>
    )
  }
}

export default connect(state => {
  return {
    groupStudy: state.groupStudy
  }
})(MarkingGuide);