import React, { Component } from "react";
import { Form, Button, Input, Spin } from "antd";
import { connect } from "react-redux";
const FormItem = Form.Item;

class ResetPasswordForm extends Component {

  submit = () => {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        if (await this.props.submit(values))
          this.props.form.resetFields();
      }
    });
  }

  checkPasswords = (rule, value, callback) => {
    if (value && value !== this.props.form.getFieldValue("password1"))
      callback('The entered passwords do not match.');
    callback();
  }

  checkPasswordRules = (rule, value, callback) => {
    if (value === undefined || value === "")  //Only display one error at a time
      callback();
    else
    {
      if (value.length < 5)
        callback('Please enter at least five characters for your password');
      if (! /[A-Z]/.test(value))
        callback('Please ensure that your password has at least one upper-case letter in it');
      if (! /[0-9]/.test(value))
        callback('Please ensure that your password has at least one number in it')
      callback();
    }
  }

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    return (
      <div>
        <Spin spinning={this.props.loading}>
          <Form className="login-form">
            <h1 style={{ textAlign: 'center', marginBottom: '1em' }}>Reset Password</h1>

            <FormItem label="New Password">
              {getFieldDecorator("password1", {
                rules: [{ required: true, message: "Password is required" }, { validator: this.checkPasswordRules }]
              })(<Input.Password
                size="large"
              />)}
            </FormItem>
            <FormItem label="Repeat Password">
              {getFieldDecorator("password2", {
                rules: [{ required: true, message: "Repeat password is required" }, { validator: this.checkPasswords }]
              })(<Input.Password
                size="large"
              />)}
            </FormItem>
            <Button
              type="primary"
              htmlType="submit"
              disabled={hasErrors(getFieldsError())}
              onClick={this.submit}
              icon="check"
              size="large"
              block
            >
              Reset
            </Button>
          </Form>
        </Spin>
      </div>
    )
  }
}

export default connect()(Form.create({ name: 'login' })(ResetPasswordForm));

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}