import React, { Component } from "react";
import { connect } from "react-redux";
import { List, Spin } from "antd";
import StatsStationDisplay from "./StatsStationDisplay";

class StatsStationPicker extends Component {
  render() {
    if (this.props.stations.length === 0)
      return (
        <div className="station-select">
          <p style={{ paddingTop: '1em' }}>Sorry, but we weren't able to find any stations with those criteria</p>
        </div>
      )

    if (this.props.user === null) //Prevent crashes on StationDisplay
      return <Spin />

    let stations = this.props.stations.map((value) => {
      return <StatsStationDisplay
        station={value}
        key={"display-" + value.id}
        user={this.props.user}
        chooseStation={(station) => this.props.chooseStation(station)}
        addToPlaylist={(station) => this.props.addToPlaylist(station)}
        playlist={this.props.playlist}
        displayName={this.props.displayName}
        showNotes={(id) => this.props.changeDisplay(id)}
      />
    });

    return (
      <div className="station-select">
        <List pagination={{ pageSize: 10, current: this.props.stationPage, onChange: this.props.changePage }}
          dataSource={stations}
          renderItem={item => (
            <List.Item key={item.key}>
              {item}
            </List.Item>
          )}
        >
        </List>
      </div>)
  }
}

export default connect()(StatsStationPicker);