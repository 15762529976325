import "babel-polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import reducers from "./reducers";
import { createStore, applyMiddleware, compose } from 'redux';
import persistState from "redux-localstorage";
import { routerMiddleware } from "react-router-redux";
import thunk from 'redux-thunk';
import { createBrowserHistory} from 'history';
import { Provider } from "react-redux";

const history = createBrowserHistory();
const middleware = [thunk, routerMiddleware(history)];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  {},
  composeEnhancers(applyMiddleware(...middleware), persistState(null, {slicer: (paths) => state => state ? {viewer: state.viewer} : null}))
);

ReactDOM.render(
  <Provider store={store}>
    <App history={history} store={store} />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
