import React, { Component } from "react";
import { connect } from "react-redux";
import { API } from "../../actions/API";
import { detectIOS, getRoleDetails } from "../../actions/functions";
import { Row, Col, Select, Button, Checkbox, Icon, Modal, message } from "antd";

const Option = Select.Option;
class TimingControls extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reading_time: props.preview ? 1 : 2,
      station_time: 6,
      question_time: 2,
      feedback_time: 2,
      disabled: false,
      show_safari_modal: false,
      show_ios_modal: false,
      loading_save_timing: false
    }
  }

  updateTiming = (value, variable, whisper) => {
    if (this.examiner() && window.presence !== undefined && whisper)
      window.presence.whisper('timing', {
          [variable.key]:  value
        });
    switch (variable.key)
    {
      case "reading":
        this.setState({reading_time: value});
        break;
      case "station":
        this.setState({station_time: value});
        break;
      case "question":
        this.setState({question_time: value});
        break;
      case "feedback":
        this.setState({feedback_time: value});
        break;
      default:
        break;
    }
  }

  switchActive = (event) => {
    this.setState({disabled: event.target.checked});
  }

  saveTiming = async () => {
    this.setState({ loading_save_timing: true });
    let timings = { reading: this.state.reading_time, station: this.state.station_time, question: this.state.question_time, feedback: this.state.feedback_time };
    await this.props.dispatch(API("/user/save_timing", "POST", timings));
    message.success("You have successfully updated your preferred timings!");

    if (this.props.user.preferred_timing === undefined || this.props.user.preferred_timing === null)
      this.props.user.preferred_timing = {};
    this.props.user.preferred_timing.reading = this.state.reading_time;
    this.props.user.preferred_timing.station = this.state.station_time;
    this.props.user.preferred_timing.question = this.state.question_time;
    this.props.user.preferred_timing.feedback = this.state.feedback_time;

    this.setState({ loading_save_timing: false });
  }

  syncTiming = () => {
    if (this.examiner() && this.props.groupStudy) {
      window.presence.whisper('timing', { reading: this.state.reading_time });
      window.presence.whisper('timing', { station: this.state.station_time });
      window.presence.whisper('timing', { question: this.state.question_time });
      window.presence.whisper('timing', { feedback: this.state.feedback_time });
    }
  }

  componentDidMount = () => {
    if (this.props.groupStudy)
      window.presence.listenForWhisper('timing', (event) => {
                    let section = Object.keys(event)[0];
                    this.updateTiming(event[section], { key: section }, false);
                  }).listenForWhisper('ready', (event) => {
                    this.props.onClick(this.state, false)
                  });;

    if (!this.props.loading)
    {
      let reading = this.props.user.preferred_timing !== null && this.examiner() ? this.props.user.preferred_timing.reading : 2;
      let station = this.props.user.preferred_timing !== null && this.examiner() ? this.props.user.preferred_timing.station : 6;
      let question = this.props.user.preferred_timing !== null && this.examiner() ? this.props.user.preferred_timing.question : 2;
      let feedback = this.props.user.preferred_timing !== null && this.examiner() ? this.props.user.preferred_timing.feedback : 2;

      this.setState({ reading_time: reading, station_time: station, question_time: question, feedback_time: feedback });

      if (this.examiner() && this.props.groupStudy)
      {
        window.presence.whisper('timing', { reading: reading });
        window.presence.whisper('timing', { station: station });
        window.presence.whisper('timing', { question: question });
        window.presence.whisper('timing', { feedback: feedback });
      }
    }
  }

  examiner = () => {
    if (!this.props.groupStudy)
      return true;

    return this.props.role === "examiner"
  }

  render() {
    let role_details = getRoleDetails(this.props.role);

    if (this.props.ready || this.props.loading)
      return "";

    const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

    return (
      <div>
        <Modal
          title="Enabling Auto-Play"
          visible={this.state.show_safari_modal}
          onOk={() => this.setState({ show_safari_modal: false })}
          onCancel={() => this.setState({ show_safari_modal: false })}
          okText="Got it!"
          cancelButtonProps={{style: {display: "none"}}}
        >
          <h2>Enabling Auto-Play</h2>
          <p>As part of the OSCEbank experience, a notification noise will play as you move through the different steps. This is intended
              to simulate an exam scenario.</p>
          <p>Safari disables auto-play by default, so you will have to grant permission for it on OSCEbank. We recommend you do this so as to
              have the best experience.</p>

          <h2>How Do I Do This?</h2>
          <ol>
            <li>Select <i>Safari</i> in your menu bar</li>
            <li>Select <i>Settings for this Website</i></li>
            <li>For the <i>Auto-Play</i> option, change it to <i>Allow All Auto-Play</i></li>
          </ol>
          <p>If you feel the need to undo this, you can simply go through the steps above and select another option. OSCEbank will never
            play annoying, flashy ads or unnecessary noises.</p>

          <h2>Why Do I Have to Do This?</h2>
          <p>Safari automatically blocks audio from playing automatically without permission being granted. While this is a great feature most
            of the time, it unfortunately sometimes blocks necessary audio, such as our notification noise.</p>
        </Modal>

        <Modal
          title="Apple Device Issues"
          visible={this.state.show_ios_modal}
          onOk={() => this.setState({ show_ios_modal: false })}
          onCancel={() => this.setState({ show_ios_modal: false })}
          okText="Got it!"
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <h2>What's wrong with using an Apple Device?</h2>
          <p>As part of the OSCEbank experience, a notification noise will play as you move through the different steps. This is intended
              to simulate an exam scenario.</p>
          <p>Apple Devices block audio from playing automatically without permission being granted. While this is a great feature most
            of the time, it unfortunately sometimes blocks necessary audio, such as our notification noise.</p>
          <p>You'll still get a visual notification as you move through the different steps of the station, but if you want to hear the
            ringing notification, as in an exam, you might want to switch to using a computer.</p>
        </Modal>

        <p>This is a history station and you are the {role_details.role_name}.</p>
        {this.examiner() ? <p>Please choose your preferred timing below. {!this.props.groupStudy ? "Our suggested times are prefilled:" : ""}</p> :
          <p>The station timing will be set by the examiner.</p>}

        <Row gutter={40} style={{ paddingTop: '1em' }}>
          <Col xs={24} sm={12} lg={6} style={{ paddingTop: '1em' }}>
            <b><p>Reading Time</p></b>
            {!this.props.preview ? <Select defaultValue={this.props.user.preferred_timing !== null && !this.props.groupStudy ? this.props.user.preferred_timing.reading : "2"}
                                            size="large"
                                            style={{ width: '100%'}}
                                            onSelect={(value, variable) => this.updateTiming(value, variable, true)}
                                            disabled={!this.examiner()}
                                            value={this.state.reading_time}>
                                      <Option value="0.0167" key="reading">0</Option>
                                      <Option value="1" key="reading">1</Option>
                                      <Option value="2" key="reading">2</Option>
                                      <Option value="3" key="reading">3</Option>
                                    </Select> : <p>1 minute</p>}
          </Col>
          <Col xs={24} sm={12} lg={6} style={{ paddingTop: '1em' }}>
            <b><p>Station Time</p></b>
            {!this.props.preview ? <Select defaultValue={this.props.user.preferred_timing !== null && !this.props.groupStudy ? this.props.user.preferred_timing.station : "6"}
                                          size="large"
                                          style={{ width: '100%'}}
                                          onSelect={(value, variable) => this.updateTiming(value, variable, true)}
                                          disabled={!this.examiner()}
                                          value={this.state.station_time}>
                                    <Option value="0.0167" key="station">0</Option>
                                    <Option value="2" key="station">2</Option>
                                    <Option value="3" key="station">3</Option>
                                    <Option value="4" key="station">4</Option>
                                    <Option value="5" key="station">5</Option>
                                    <Option value="6" key="station">6</Option>
                                    <Option value="7" key="station">7</Option>
                                    <Option value="8" key="station">8</Option>
                                    <Option value="9" key="station">9</Option>
                                    <Option value="10" key="station">10</Option>
                                  </Select>: <p>6 minutes</p>}
          </Col>
          <Col xs={24} sm={12} lg={6} style={{ paddingTop: '1em' }}>
            <b><p>Question Time</p></b>
            {!this.props.preview ? <Select defaultValue={this.props.user.preferred_timing !== null && !this.props.groupStudy ? this.props.user.preferred_timing.question : "2"}
                                          size="large"
                                          style={{ width: '100%'}}
                                          onSelect={(value, variable) => this.updateTiming(value, variable, true)}
                                          disabled={!this.examiner()}
                                          value={this.state.question_time}>
                                      <Option value="0.0167" key="question">0</Option>
                                      <Option value="1" key="question">1</Option>
                                      <Option value="2" key="question">2</Option>
                                      <Option value="3" key="question">3</Option>
                                      <Option value="4" key="question">4</Option>
                                    </Select> : <p>2 minutes</p>}
          </Col>
          <Col xs={24} sm={12} lg={6} style={{ paddingTop: '1em' }}>
            <b><p>Feedback Time</p></b>
            {!this.props.preview ? <Select defaultValue={this.props.user.preferred_timing !== null && !this.props.groupStudy ? this.props.user.preferred_timing.feedback : "2"}
                                          size="large"
                                          style={{ width: '100%'}}
                                          onSelect={(value, variable) => this.updateTiming(value, variable, true)}
                                          disabled={!this.examiner()}
                                          value={this.state.feedback_time}>
                                      <Option value="0.0167" key="feedback">0</Option>
                                      <Option value="1" key="feedback">1</Option>
                                      <Option value="2" key="feedback">2</Option>
                                      <Option value="3" key="feedback">3</Option>
                                      <Option value="4" key="feedback">4</Option>
                                      <Option value="5" key="feedback">5</Option>
                                      <Option value="6" key="feedback">6</Option>
                                      <Option value="7" key="feedback">7</Option>
                                      <Option value="8" key="feedback">8</Option>
                                      <Option value="9" key="feedback">9</Option>
                                      <Option value="10" key="feedback">10</Option>
                                    </Select> : <p>2 minutes</p>}
          </Col>
        </Row>
        {!this.props.preview && this.examiner() ?
          <div className="timer-presets">
            <Checkbox onChange={this.switchActive}>Disable Timer</Checkbox>
            {this.examiner() && this.props.groupStudy ? <Button size="default" type="primary" onClick={this.syncTiming}><Icon type="clock-circle"></Icon>Sync Timers</Button> : ""}
            <Button id="save-timing" size="default" onClick={this.saveTiming} loading={this.state.loading_save_timing}><Icon type="hourglass" />Save Timers</Button>
          </div>
        : ""}
        {isSafari && !detectIOS() ?
            <p style={{ marginTop: '2em' }}>You appear to be using Safari. You might want to allow auto-play on this site to experience it as intended.&nbsp;
              <span style={{ color: "#1890ff", cursor: "pointer"}} onClick={() => this.setState({ show_safari_modal: true })}>How do I do this?</span></p>
        : ""}
        {detectIOS() ?
            <p style={{ marginTop: '2em' }}>You appear to be viewing this site on an Apple Device, which may stop the alert bells from ringing. Watch for the visual aid
              instead or switch to a computer.&nbsp;
              <span style={{ color: "#1890ff", cursor: "pointer" }} onClick={() => this.setState({ show_ios_modal: true })}>Why is this?</span></p>
        : ""}
        {this.examiner() ? <Button className="next" type="primary" size="large" onClick={() => this.props.onClick(this.state, true)}>Start <Icon type="arrow-right" /></Button> : ""}
      </div>
    )
  }
}

export default connect(state => {
  return {
    groupStudy: state.groupStudy !== null,
    role: state.role
  }
})(TimingControls);