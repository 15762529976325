import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Tabs, Input, Select } from "antd";

const Tab = Tabs.TabPane;

class AddItem extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name: "",
      playlist_id: null
    }
  }

  updateName = (e) => {
    let { value } = e.target;

    this.setState({ name: value });
  }

  render() {
    return <div>
      <Tabs type="card">
        {this.props.playlists.filter(playlist => playlist.items.length > 0).length > 0 ?
          <Tab tab="Add to Playlist" key="add">
            <div className="playlist-name">
              <Select placeholder="Select..."
                style={{ width: '100%' }}
                onChange={(value) => this.setState({ playlist_id: value })}>
                {this.props.playlists.filter(playlist => playlist.items.length > 0).map((playlist, index) => {
                  return <Select.Option key={"playlist-" + index} value={playlist.id}>{playlist.name}</Select.Option>
                })}
              </Select>

              <Button type="primary" onClick={() => this.props.addToPlaylist(this.state.playlist_id)}>Add</Button>
            </div>
          </Tab>
          : ""}
        <Tab tab="Create Playlist" key="create">
          <div className="playlist-name">
            <Input placeholder="Playlist Name" onChange={(e) => this.updateName(e)} value={this.state.name} />
            <Button type="primary" onClick={() => { this.setState({ name: ""}); this.props.createPlaylist(this.state.name); }}>Create</Button>
          </div>
        </Tab>
      </Tabs>
    </div>;
  }
}
export default connect()(AddItem);