import React, { Component } from "react";
import { Form, Select, Button, Icon, Input, Tooltip } from "antd";
import { connect } from "react-redux";
const FormItem = Form.Item;

class StationSorter extends Component {

  sort = (e) => {
    let sort_type = e;
    this.props.sort(sort_type);
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form id="station-sorter">
        {this.props.searchFilters ?
          <Input placeholder="Search Stations..." className="station-searcher" onChange={(e) => this.props.search(e)} value={this.props.searchTerm} />
        : ''}
        {this.props.keywordSearch ? <Input placeholder="Search..." className="keyword-searcher" onChange={this.props.search} value={this.props.searchTerm} /> : ''}
        {this.props.searchFilters ?
          <Tooltip placement="top" title="Filter Stations"><Button type="primary" icon="filter" className="filter-button" onClick={() => this.props.showFilters()} /></Tooltip>
        : ''}

        {getFieldDecorator('sort_type', {}) (
          <Select className="sort-by" placeholder="Sort by" size="small" onChange={(e) => this.sort(e)}>
            <Select.Option value="number">Number</Select.Option>
            <Select.Option value="name">Name</Select.Option>
            <Select.Option value="difficulty">Difficulty</Select.Option>
            {this.props.keywordSearch ? <Select.Option value="latest">Recent</Select.Option> : ''}
          </Select>
        )}
        <Tooltip placement="top" title="Reverse Sort Order"><Button className="reverse-order" onClick={this.props.reverse}><Icon type="swap" rotate="90" /></Button></Tooltip>
      </Form>
    )
  }
}
export default connect()(Form.create({ name: 'station-sorter' })(StationSorter));