import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Form, Button, Input, Icon} from "antd";
import FacebookLogin from 'react-facebook-login';
const FormItem = Form.Item;
const facebookAppId = process.env.REACT_APP_FACEBOOK_APPID;

class LoginForm extends Component {

  submit = () => {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        if (await this.props.onSave(values))
          this.props.form.resetFields();
      }
    });
  }

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    return (
      <div>
        <Form className="login-form">
          <h1 style={{ textAlign: 'center', marginBottom: '1em' }}>Welcome Back</h1>

          <FormItem>
            {getFieldDecorator("username", {
              rules: [{ required: true, message: "Username is required" }]
            })(<Input
              size="large"
              placeholder="Username"
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator("password", {
              rules: [{ required: true, message: "Password is required" }]
            })(<Input.Password
              size="large"
              placeholder="Password"
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
            )}
          </FormItem>
          <Button
            type="primary"
            htmlType="submit"
            disabled={hasErrors(getFieldsError())}
            onClick={this.submit}
            size="large"
            block
          >
            Log In
          </Button>
            {this.props.showForgotPassword ?
                <Link to="reset-password">
                    Forgotten your password?
                </Link>
            : ""}
            <p className="or">OR</p>
          <FacebookLogin
            appId={facebookAppId}
            autoLoad={true}
            fields="email"
            scope="email"
            callback={this.props.loginWithFacebook}
            textButton={<span><Icon type='facebook' theme='filled' /> <b>Login with Facebook</b></span>}
            size="small"
            cssClass="fb-button" />
        </Form>
      </div>
    )
  }
}

export default connect()(Form.create({ name: 'login' })(LoginForm));

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}