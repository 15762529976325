import React, { Component } from "react";
import { connect } from "react-redux";
import { APITokenless } from '../../actions/API.js';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { message } from "antd";
import { Link } from "react-router-dom";
import SignupForm from "../Auth/Forms/SignupForm";
import Errors from "../Auth/Errors";
import MetaTags from 'react-meta-tags';

const stripeKey = process.env.REACT_APP_STRIPE_API_KEY;
const country = process.env.REACT_APP_COUNTRY

class PromotionPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      visible_modal: null,
      countries: null,
      medical_schools: null,
      promotion: null,
      loading: true,
      voucher: null,
      signup_success: false,
      terms_and_conditions: null
    }

    this.errorRef = React.createRef();
  }

  componentDidMount = async () => {
    let countries = await this.props.dispatch(APITokenless("/countries", "GET"));
    let medical_schools = await this.props.dispatch(APITokenless("/medical_schools", "GET"));
    let subscription_types = await this.props.dispatch(APITokenless("/subscription_types/signup?country=" + country, "GET"));
    let promotion_slug = this.props.match.params.promotion;
    let promotion = await this.props.dispatch(APITokenless("/promotion/" + promotion_slug + "?country=" + country), "GET");
    let terms_and_conditions = await this.props.dispatch(APITokenless("/terms_and_conditions", "GET"));
    if (!this.unmounted)
      this.setState({ countries, medical_schools, subscription_types, loading: false, promotion, terms_and_conditions });
  }

  componentWillUnmount = () => {
    this.unmounted = true;
  }

  creditCardSignUp = async (data) => {
    this.setState({ errors: [] });
    if (this.state.voucher !== null)
      data.voucher = this.state.voucher.id;
    window.scrollTo(0, this.errorRef.current.offsetTop);
    data.country = country;
    let response = await this.props.dispatch(APITokenless("/credit_card_signup", "POST", data));
    if (response.success === true) {
      message.success("Thanks for signing up to OSCEbank! You have been sent an activation email (please note that this may take up to 10 minutes to arrive in your inbox)", 20);
      if (!this.unmounted)
        this.setState({ loading: false, voucher: null, signup_success: true });
      return true;
    }
    else {
      if (!this.unmounted)
        this.setState({ errors: response.errors, loading: false });
      return false;
    }
  }

  setLoading = (state) => {
    this.setState({ loading: state });
  }

  applyVoucher = async (voucher) => {
    this.setState({ loading: true });
    let response = await this.props.dispatch(APITokenless("/apply_voucher", "POST", { voucher: voucher }));
    this.setState({ loading: false });
    if (response.success !== undefined && response.success === false)
      message.error("Sorry, but that doesn't look like a valid voucher");
    else {
      this.setState({ voucher: response });
      message.success("You have successfully applied your voucher!");
    }
    return true;
  }

  validate = async (data) => {
    this.setState({ errors: [] });
    if (this.state.voucher !== null)
      data.voucher = this.state.voucher.id;

    let response = await this.props.dispatch(APITokenless("/signup_validation", "POST", data));
    if (response.success === true) {
      return true;
    }
    else {
      if (response.errors.length > 0)
        window.scrollTo(0, this.errorRef.current.offsetTop);
      if (!this.unmounted)
        this.setState({ errors: response.errors, loading: false });
      return false;
    }
  }

  payPalSignup = async (data) => {
    if (this.state.voucher !== null)
      data.voucher = this.state.voucher.id;
    window.scrollTo(0, this.errorRef.current.offsetTop);
    let response = await this.props.dispatch(APITokenless("/paypal_signup", "POST", data));
    if (response.success === true) {
      message.success("Thanks for signing up to OSCEbank! You have been sent an activation email (please note that this may take up to 10 minutes to arrive in your inbox)", 20);
      if (!this.unmounted)
        this.setState({ loading: false, voucher: null, signup_success: true });
      return true;
    }
    else {
      if (!this.unmounted)
        this.setState({ errors: ["Sorry, something went wrong. Please contact OSCEbank directly and use " + data.paypal_reference + " as your reference."], loading: false });
      return false;
    }
  }

  freeSubscription = async (data) => {
    this.setState({ errors: [] });
    data.voucher = this.state.voucher.id;
    data.country = country;
    window.scrollTo(0, this.errorRef.current.offsetTop);

    let response = await this.props.dispatch(APITokenless("/free_subscription_signup", "POST", data));
    if (response.success === true) {
      message.success("Thanks for signing up to OSCEbank! You have been sent an activation email (please note that this may take up to 10 minutes to arrive in your inbox)", 20);
      if (!this.unmounted)
        this.setState({ loading: false, voucher: null, signup_success: true });
      return true;
    }
    else {
      if (!this.unmounted)
        this.setState({ errors: ["Sorry, something went wrong. Please contact OSCEbank directly."], loading: false });
      return false;
    }
  }

  render() {
    return (
        <div id="promotion-page">
            <MetaTags>
                <title>Promotion | OSCEbank</title>
            </MetaTags>
            <div className={`container fixed`}>
            <Errors errors={this.state.errors} ref={this.errorRef} />
            {this.state.promotion !== null && Object.keys(this.state.promotion).length === 0 ?
                <p>Sorry, it looks like this promotion is no longer active</p>
                : <StripeProvider apiKey={stripeKey}>
                      <Elements>
                          <div>
                              <h1 style={{ textAlign: 'center', marginBottom: '1em' }}>Sign Up to OSCEbank - Promotion</h1>
                              {this.state.promotion !== null && this.state.promotion.sub_types.length < 1 ?
                                <p class="promotion-not-available">Sorry, this promotion is not available in your country</p>
                                : <SignupForm
                                    creditCardSignup={this.creditCardSignUp}
                                    payPalSignup={this.payPalSignup}
                                    freeSubscription={this.freeSubscription}
                                    countries={this.state.countries}
                                    medical_schools={this.state.medical_schools}
                                    subscription_types={this.state.promotion !== null ? this.state.promotion.sub_types : []}
                                    loading={this.state.loading}
                                    setLoading={this.setLoading}
                                    applyVoucher={this.applyVoucher}
                                    voucher={this.state.voucher}
                                    validate={this.validate}
                                    signupSuccess={this.state.signup_success}
                                    selectedPlan={null}
                                    termsAndConditions={this.state.terms_and_conditions}
                                    promotion={true}
                                />}
                          </div>
                      </Elements>
                    </StripeProvider>}

                <p style={{ marginTop: '1.5em', textAlign: 'center' }}>Already Registered? <Link to="/login">Log in</Link></p>
            </div>
        </div>
    )
  }
}

export default connect()(PromotionPage);