import React, { Component } from "react";
import { connect } from "react-redux";
import { Spin, Button, Card, Icon, Drawer, Tabs, Tooltip, Modal, Switch, message } from "antd";
import { push } from "react-router-redux";
import { Prompt } from 'react-router'
import { API } from '../../actions/API.js';
import { readyToStart, beginStation, removeUser } from "../../actions/Echo.js"
import { setCurrentStation, toggleStationStart, setPlaylist, setPlaylistIndex, setRole } from "../../constants/ActionTypes"
import MarkingGuide from "./MarkingGuide";
import TimingControls from "./TimingControls";
import CountdownDisplay from "./CountdownDisplay";
import Notes from "./Notes";
import StationContinue from "./Display/StationContinue";
import StationStatus from "./Display/StationStatus";
import StationCompleted from "./Display/StationCompleted";
import { generateStem, getUser, displayTab } from "../../actions/functions";
import MetaTags from 'react-meta-tags';
import GroupStudy from '../Partials/GroupStudy';

import candidate from "../../static/images/candidate-icon.svg";
import observer from "../../static/images/observer-icon.svg";
import patient from "../../static/images/patient-icon.svg";
import examiner from "../../static/images/examiner-icon.svg";

const TabPane = Tabs.TabPane;

class Station extends Component {
  constructor(props) {
    super(props);

    this.state = {
        station: null,
        user: null,
        station_components: {},
        loading: true,
        content: null,
        marking_guide: null,
        marking_guide_answers: [],
        timing: null,
        mark: null,
        visible_modal: false,
        notes: "",
        display_notes: false,
        show_timer: true,
        timer_paused: false,
        global_score: 0,
        visible: false,
        group_visible: false,
        closable: false,
        mask: false,
        width: 0,
        cumulative_score: 0,
        show_all: false,
        examiner_notes: "",
        viewing_feedback: false,
        candidate: null,
        allowed_to_leave: false,
        rerender: false,
        failed_mark: false,
        loading_mark_submit: false
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  closeGroup = () => {
    this.setState({
      group_visible: false
    });

    document.body.style = '';
  };

  updateWindowDimensions() {
    if (!this.unmounted)
      this.setState({ width: window.innerWidth });
  }

  componentDidMount = async () => {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    document.body.classList.add('overflow-initial');

    this.props.dispatch(setCurrentStation(this.props.match.params.code));

    if (!this.props.preview)
    {
      let code = this.props.match.params.code;
      let user = await getUser(this.props);
      if (this.props.role !== "candidate-1" && !this.props.groupStudy && !this.unmounted)
      {
        this.props.dispatch(setRole(null));
        this.props.dispatch(push("/study"));
        return false;
      }
      let station = await this.props.dispatch(API("/station/" + code, "GET"));
      if (this.props.role == "candidate-1" || this.props.role == "candidate-2")
        this.props.dispatch(API("/station/" + station.station.id + "/started", "POST", {role: this.props.role}));
      if (!this.unmounted)
        this.setState({ station: station.station, user, station_components: station.station_components, loading: false });
      if (this.state.user !== null && (this.props.role === "candidate-1" || this.props.role === "candidate-2") && !this.unmounted)
        this.setState({ candidate: {id: this.state.user.id, name: this.state.user.first_name } });

    }
    else if (!this.unmounted)
      this.setState({ station: this.props.station, station_components: this.props.components, user: { role: this.props.userRole }, loading: false });

    if (this.props.groupStudy)
    {
      if (!this.state.station.available_to_trial && this.state.user.status == "Trial" && this.props.members.filter(member => member.status == "Active").length < 1)
      {
        this.setState({ allowed_to_leave: true })
        message.warning("This station is not available to trial users");
        this.props.dispatch(push("/study"))
      }

      window.presence.listenForWhisper('pause', (event) => {
        this.setState({ timer_paused: event.paused })
      }).listenForWhisper('back', (event) => {
        this.back(false);
      }).listenForWhisper('skip', (event) => {
        this.skip(false);
      }).listenForWhisper('confirm-end', (event) => {
        this.confirmEnd(false);
      }).listenForWhisper('update_marking', (event) => {
        this.updateMarkingGuide(event.marking_guide_answers, event.global_score, event.cumulative_score, false)
      }).listenForWhisper('update_examiner_notes', (event) => {
        this.updateExaminerNotes(event.notes, false)
      }).listenForWhisper('reset', (event) => {
        this.reset(this.props.role, false);
      }).listenForWhisper('examiner-left', (event) => {
        this.setState({ allowed_to_leave: true })
        message.warning("The examiner has left the station - please attempt again later")
        this.props.dispatch(push("/study"));
      }).listenForWhisper('finish', (event) => {
        this.finish(false);
      }).listenForWhisper('return-to-feedback', (event) => {
        this.returnToFeedback(false);
      });

      if (this.props.role === "examiner")
        window.presence.listenForWhisper('request-timing', (event) => {
          this.syncTimers();
        });
      else
        window.presence.whisper('request-timing', { requesting: true })
          .on('set-timing', (event) => {
            this.props.dispatch(toggleStationStart(true));
            this.setState({ timing: event.timing })
            this.updateTiming("reading", event.timing.reading_time );
          });
    }
    else
    {
      if (!this.state.station.available_to_trial && this.state.user.status == "Trial")
      {
        message.warning("This station is not available to trial users");
        this.props.dispatch(push("/study"))
      }
    }
  }

  componentWillReceiveProps = async (props) => {
    let code = props.match.params.code;
    this.props.dispatch(setCurrentStation(code));
    if (this.state.station.number != code)
    {
      this.setState({ loading: true });
      let station = await this.props.dispatch(API("/station/" + code, "GET"));
      this.props.dispatch(API("/station/" + station.station.id + "/started", "POST", { role: this.props.role }));
      if (!this.unmounted)
      {
        this.setState({ station: station.station, station_components: station.station_components, loading: false });

        if (this.props.role !== "candidate-1" && this.props.role !== "candidate-2")
          this.setState({ candidate: null });
      }
    }
  }

  componentWillUnmount = () => {
    this.unmounted = true;
    if (window.presence !== undefined)
    {
      window.presence.stopListeningForWhisper("request-timing");
      window.presence.stopListeningForWhisper("examiner-left");
    }
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  setTiming = (values, toggle) => {
    let timing = {};
    if (!values.disabled) {
      timing.original_reading = values.reading_time;
      timing.reading_time = values.reading_time * 60000;
      timing.original_station = values.station_time;
      timing.station_time = values.station_time * 60000;
      timing.original_question = values.question_time;
      timing.question_time = values.question_time * 60000;
      timing.original_feedback = values.feedback_time;
      timing.feedback_time = values.feedback_time * 60000;
      timing.disabled = false;
    } else {
      timing.reading_time = false;
      timing.station_time = false;
      timing.question_time = false;
      timing.feedback_time = false;
      timing.disabled = true;
    }
    this.setState({timing});
    this.props.dispatch(readyToStart(toggle))
    if (this.props.groupStudy && this.props.role == "examiner")
      this.syncTimers(timing);
  }

  updateTiming = (type, value) => {
    let timing = this.state.timing;
    if (timing === null)
      return value;

    switch (type)
    {
      case "reading":
        if (timing.reading_time === 0)
          return 0;
        if (timing.station_time !== timing.original_station * 60000 && !timing.disabled)
          timing.station_time = timing.original_station * 60000
        timing.reading_time = value;
        break;
      case "station":
        if (timing.station_time === 0)
          return 0;
        if (timing.question_time !== timing.original_question * 60000 && !timing.disabled)
          timing.question_time = timing.original_question * 60000;
        timing.station_time = value;

        break;
      case "question":
        if (timing.question_time === 0)
          return 0;
        if (timing.feedback_time !== timing.original_feedback * 60000 && !timing.disabled)
          timing.feedback_time = timing.original_feedback * 60000
        timing.question_time = value;

        break;
      case "feedback":
        if (value === 0)
          this.updateMark();
        timing.feedback_time = value;
        break;
      default:
        break;
    }

    if (value === 0 && type === "feedback" && !timing.complete_3)
      timing.complete_1 = true;

    this.setState({timing});
    if (value == 0 && this.props.groupStudy && this.props.role === "examiner")
      this.syncTimers();
    return value;
  }

  currentState = () => {
    let timing = this.state.timing;
    if (timing === null)
      return false;
    if (timing.reading_time > 0 || timing.reading_time === false)
      return "Reading Time";
    if (timing.station_time > 0 || timing.station_time === false)
      return "Station Time";
    if (timing.question_time > 0 || timing.question_time === false)
      return "Question Time";
    if (timing.feedback_time > 0 || timing.feedback_time === false || this.state.viewing_feedback)
      return "Feedback Time";
    if (timing.complete_1)
      return "station_completed_1";
    if (timing.complete_2)
      return "station_completed_2";
    if (timing.complete_3)
      return "station_completed_3";

    return "";
  }

  back = (clicked) => {
    let timing = this.state.timing;
    switch(this.currentState())
    {
      case "Reading Time":
        break;
      case "Station Time":
        timing.reading_time = timing.original_reading * 60000;
        if (timing.disabled)
          timing.reading_time = false;
        break;
      case "Question Time":
        timing.station_time = timing.original_station * 60000;
        if (timing.disabled)
          timing.station_time = false;
        break;
      case "Feedback Time":
        timing.question_time = timing.original_question * 60000;
        if (timing.disabled)
          timing.question_time = false;
        break;
      default:
        break;
    }
    this.setState({ timing, rerender: !this.state.rerender });
    if (this.props.groupStudy && clicked)
      window.presence.whisper('back', { back: true });
  }

  togglePause = () => {
    this.setState({ timer_paused: !this.state.timer_paused })
    if (this.props.groupStudy)
      window.presence.whisper('pause', { paused: !this.state.timer_paused });
  }

  skip = (clicked) => {
    if (this.state.timing.disabled === true)
    {
      let timing = this.state.timing;

      if (timing.reading_time === false)
        timing.reading_time = 0;
      else if (timing.station_time === false)
        timing.station_time = 0;
      else if (timing.question_time === false)
        timing.question_time = 0;
      else if (timing.feedback_time === false || timing.feedback_time === 1) {
        timing.feedback_time = 0;
        timing.complete_1 = true;
      }
      this.setState({ timing, timer_paused: !this.state.timer_paused });
    }
    else
      switch (this.currentState())
      {
        case "Reading Time":
          this.updateTiming("reading", 0);
          break;
        case "Station Time":
          this.updateTiming("station", 0);
          break;
        case "Question Time":
          this.updateTiming("question", 0);
          break;
        case "Feedback Time":
          this.setState({ viewing_feedback: false });
          this.updateTiming("feedback", 0);
          break;
        default:
          return false;
      }

    if (this.props.groupStudy && clicked)
      window.presence.whisper('skip', { skip: true });
  }

  finish = (notify = false) => {
    let timing = this.state.timing;
    if (this.currentState() === "station_completed_1")
    {
      timing.complete_1 = false;
      timing.complete_2 = (this.props.role === "candidate-1" || this.props.role === "candidate-2");
      timing.complete_3 = (this.props.role === "examiner" || this.props.role === "patient" || this.props.role === "observer");
    }
    else if (this.currentState() === "station_completed_2")
    {
      timing.complete_1 = timing.complete_2 = false;
      timing.complete_3 = true;
    }

    this.setState({timing, viewing_feedback: false});

    if(this.props.groupStudy && notify)
      window.presence.whisper('finish', { finish: true })
  }

  reset = (role, post_started = true) => {
    if (post_started)
      this.props.dispatch(API("/station/" + this.state.station.id + "/started", "POST", { role: role }));
    this.props.dispatch(toggleStationStart(false));
    this.setState({
      content: null,
      marking_guide: null,
      marking_guide_answers: [],
      timing: null,
      timer_paused: false,
      global_score: 0,
      cumulative_score: 0,
      examiner_notes: "",
      viewing_feedback: false,
    });
  }

  end = () => {
    Modal.confirm({
      title: 'Warning',
      content: "Are you sure you would like to end the station? All progress will be lost",
      okText: 'Yes',
      onOk: () => this.confirmEnd(true),
      cancelText: 'No',
      width: '50%',
      className: 'modal-role-chooser'
    });
  }

  confirmEnd = (clicked) => {
    Modal.destroyAll();
    let timing = this.state.timing;
    timing.reading_time = 0;
    timing.station_time = 0;
    timing.question_time = 0;
    timing.feedback_time = 0;
    if (!timing.complete_3)
      timing.complete_1 = true;
    else
      timing.complete_1 = false;
    this.updateMark();
    this.setState({ timing, viewing_feedback: false });
    if (this.props.groupStudy && clicked)
      window.presence.whisper('confirm-end', { confirmed: true })
  }

  nextPlaylistItem = () => {
    let index = this.props.playlist_index;
    index ++;
    let number = this.props.playlist.items[index].number;
    this.props.dispatch(setPlaylistIndex(index));

    this.reset(this.props.role, false);
    if (this.props.groupStudy && this.props.role === "examiner")
    {
      window.presence.whisper('reset', { reset: true })
      this.props.dispatch(beginStation(number))
    }
    else
      this.props.dispatch(push("/station/" + number));
  }

  updateMark = async () => {
    let data = { mark: this.state.marking_guide_answers.length + this.state.global_score,
                  notes: this.state.notes,
                  examiner_notes: this.state.examiner_notes,
                  scores: this.state.marking_guide_answers };
    if (this.state.candidate !== null)
      data.user_id = this.state.candidate.id;
    data.status = "complete"; //Set status as complete so that if they leave they still get notes saved

    if (this.props.role === "candidate-1" || this.props.role === "candidate-2" || this.state.candidate !== null)
    {
      this.setState({ loading_mark_submit: true })
      let mark = await this.props.dispatch(API("/station/" + this.state.station.id + "/mark", "POST", data));
      this.setState({ failed_mark: !mark.success, loading_mark_submit: false });
    }
  }

  updateMarkingGuide = (marking_guide_answers, global_score, cumulative_score, self) => {
    this.setState({ marking_guide_answers, global_score, cumulative_score })

    if (this.props.groupStudy && self)
      window.presence.whisper('update_marking', { marking_guide_answers, global_score, cumulative_score });
  }

  updateExaminerNotes = (notes, self) => {
    this.setState({ examiner_notes: notes })

    if (this.props.groupStudy && self)
      window.presence.whisper('update_examiner_notes', { notes })
  }

  everyoneReady = () => {
    if (!this.props.groupStudy)
      return this.state.timing !== null

    return this.props.stationReady;
  }

  syncTimers = (timing = null) => {
    if (timing === null)
      timing = this.state.timing;
    if (timing !== null) {
      if (timing.reading_time > 1000 && timing.reading_time != timing.original_reading * 60000)
        timing.reading_time = timing.reading_time - 1000;
      if (timing.station_time > 1000 && timing.station_time != timing.original_station * 60000)
        timing.station_time = timing.station_time - 1000;
      if (timing.question_time > 1000 && timing.question_time != timing.original_question * 60000)
        timing.question_time = timing.question_time - 1000;
      if (timing.feedback_time > 1000 && timing.feedback_time != timing.original_feedback * 60000)
        timing.feedback_time = timing.feedback_time - 1000;
      this.props.dispatch(API("/broadcast/set_timing", "POST", { group: this.props.group, timing: timing }))
    }
  }

  removeCandidate = (id, group) => {
    if (group)
      this.props.dispatch(removeUser(id));
    else
      this.setState({ candidate: null });
  }

  addCandidate = (user) => {
    message.success("User " + user.first_name + " has been added!");
    this.props.dispatch(API("/station/" + this.state.station.id + "/started", "POST", { role: "candidate-2", user_id: user.id }));
    this.setState({ candidate: {id: user.id, name: user.first_name} });
  }

  prompt = (location, action) => {
    if (this.state.allowed_to_leave)
      return true;

    if (action === "POP" && this.props.groupStudy)
    {
      if (this.props.role !== "examiner")
        window.presence.whisper('request-station', { requesting: true} );
      else
        window.presence.whisper('examiner-left', { left: true });
      return true;
    }

    if (location.pathname.substring(0, 8) === "/station")
      return true;

    if (this.props.groupStudy && (this.currentState() === false || this.currentState() === "Reading Time" || this.currentState() === "Station Time" || this.currentState() === "Question Time" || this.currentState() === "Feedback Time"))
      return "Leaving this station will end your group study. Are you sure you want to continue?";

    return true;
  }

  returnToFeedback = (notify = false) => {
    this.setState({ viewing_feedback: true })

    if (this.props.groupStudy && notify)
      window.presence.whisper('return-to-feedback', { return: true })
  }

  skipSaving = async () => {
    this.setState({ failed_mark: false })
    await this.props.dispatch(API("/station/" + this.state.station.id + "/status", "POST", { status: "hidden" }));
    this.finish();  //Calling it twice is a cheap trick but it works
    this.finish();
  }

  render() {
    const width = this.state.width;
    const isSmallScreen = width <= 992;

    if (this.state.loading) //TimingControls has to be rendered so that it knows to listen for the timing update before everything else has finished loading
      return <Spin className="loading">
        <TimingControls user={this.state.user}
          onClick={this.setTiming}
          preview={this.props.preview}
          ready={this.everyoneReady()}
          loading={this.state.loading} />
        </Spin>
    let show_timer = this.currentState() !== "station_completed_1" && this.currentState() !== "station_completed_2" && this.currentState() !== "station_completed_3";
    let content = null;
    let questions = "";

    if (this.state.station_components.questions !== undefined) {
      this.state.station_components.questions.map((value) => { return questions += value.question })
    }

    if (show_timer && this.currentState() !== false && this.everyoneReady())
      content = <Tabs type="card"
                    defaultActiveKey={this.props.role === "patient" ? "patient_script" : this.props.role === "examiner" ? "marking_guide" : "stem"}>
                  {displayTab(this.props.role, this.currentState(), "stem", this.state.show_all) ?
                    <TabPane tab="Stem" key="stem">
                      <div dangerouslySetInnerHTML={{ __html: generateStem(this.state.station_components, this.state.timing) }} />
                    </TabPane> : ""}
                  {displayTab(this.props.role, this.currentState(), "patient_script", this.state.show_all) ?
                    <TabPane tab="Patient Script" key="patient_script">
                      <div dangerouslySetInnerHTML={{ __html: this.state.station_components.patient_scenario.content }} />
                    </TabPane> : ""}
                  {displayTab(this.props.role, this.currentState(), "marking_guide", this.state.show_all) ?
                    <TabPane tab="Marking Guide" key="marking_guide">
                      <MarkingGuide
                        questions={this.state.station_components.marking_guides}
                        onChange={(marking_guide_answers, global_score, cumulative_score) => this.updateMarkingGuide(marking_guide_answers, global_score, cumulative_score, true)}
                        answers={this.state.marking_guide_answers}
                        global_score={this.state.global_score}
                        cumulative_score={this.state.cumulative_score}
                        notes={this.state.examiner_notes}
                        updateNotes={(notes) => this.updateExaminerNotes(notes, true)}
                        removeCandidate={(id, group) => this.removeCandidate(id, group)}
                        addCandidate={(user) => this.addCandidate(user)}
                        candidate={this.state.candidate}
                      />
                    </TabPane> : ""}
                  {displayTab(this.props.role, this.currentState(), "approach_guide", this.state.show_all) && this.props.role !== "candidate-1" ?
                    <TabPane tab="Approach Guide" key="approach_guide">
                      <div dangerouslySetInnerHTML={{ __html: this.state.station_components.approach_guide.content }} />
                    </TabPane> : ""}
                  {displayTab(this.props.role, this.currentState(), "questions", this.state.show_all) ?
                    <TabPane tab="Questions" key="questions">
                      <div dangerouslySetInnerHTML={{ __html: questions }} />
                    </TabPane> : ""}
                  {displayTab(this.props.role, this.currentState(), "notes", this.state.show_all) ?
                    <TabPane tab="Notes" key="notes">
                      <Notes notes={this.state.notes} updateNotes={(notes) => this.setState({ notes })} />
                    </TabPane> : ""}
                  {displayTab(this.props.role, this.currentState(), "approach_guide", this.state.show_all) && this.props.role === "candidate-1" ?
                    <TabPane tab="Approach Guide" key="approach_guide">
                      <div dangerouslySetInnerHTML={{ __html: this.state.station_components.approach_guide.content }} />
                    </TabPane> : ""}
                </Tabs>
    else if (this.currentState() === false || (!this.everyoneReady() && this.currentState() === "Reading Time"))
      content = <TimingControls user={this.state.user}
                                onClick={this.setTiming}
                                preview={this.props.preview}
                                ready={this.everyoneReady()}
                                loading={this.state.loading} />
    else if (this.currentState() === "station_completed_1")
      content = <StationContinue
                  finish={() => this.finish(true)}
                  feedback={() => this.returnToFeedback(true)}
                />;
    else if (this.currentState() === "station_completed_2")
      content = <StationStatus
                  finish={this.finish}
                  stationId={this.state.station.id}
                  preview={this.props.preview}
                  score={this.state.marking_guide_answers}
                  globalScore={this.state.global_score}
                  marking={this.state.station_components.marking_guides}
                  notes={this.state.notes}
                  examiner_notes={this.state.examiner_notes}
                />
    else if (this.currentState() === "station_completed_3")
      content = <StationCompleted
                  role={this.props.role}
                  feedback={() => this.setState({ viewing_feedback: true })}
                  redirect={(url) => this.props.dispatch(push(url))}
                  nextPlaylistItem={() => this.nextPlaylistItem()}
                  repeat={(role) => this.reset(role)}
                  preview={this.props.preview}
                  stationId={this.state.station.id}
                />

    return (
      <div id="station">
        <Prompt message={this.prompt} />
        <MetaTags>
          <title>Study | OSCEbank</title>
        </MetaTags>

        <Modal
          title="Error"
          visible={this.state.failed_mark}
          okButtonProps={{ style: {display: "none"} }}
          cancelButtonProps={{ style: {display: "none"} }}
        >
          <p>Sorry, an error went wrong trying to record your mark! Please try again.</p>
          <p>Your score: <b>{this.state.marking_guide_answers.length + this.state.global_score}</b></p>
          <Button type="primary" onClick={() => this.updateMark()} loading={this.state.loading_mark_submit}>Resubmit</Button>
          <Button onClick={() => this.skipSaving()} className="danger-button">Don't save stats</Button>
        </Modal>

        <div className="container">
          <div className="intro">
            <h1>
              {this.props.role === "candidate-1" || this.props.role === "candidate-2" ?
                <img src={candidate} id="logo-icon" alt="Candidate" /> :
                this.props.role === "patient" ?
                  <img src={patient} id="logo-icon" alt="Patient" /> :
                  this.props.role === "observer" ?
                    <img src={observer} id="logo-icon" alt="Observer" /> :
                    <img src={examiner} id="logo-icon" alt="Examiner" />
              }
              Station {this.state.station.number} {this.props.role === "candidate-1" || this.props.role === "candidate-2" ? "" : " - " + this.state.station.name}
            </h1>
          </div>

          <div className="station-content">
            {show_timer && this.state.timing !== null && this.everyoneReady() ?
              <div className="station-tabs has-sidebar">{content}</div>
            : <Card className={"station-setup " + (this.props.groupStudy ? 'has-sidebar' : '')}>{content}</Card> }

            {!isSmallScreen && ((show_timer && this.state.timing !== null && this.everyoneReady()) || this.props.groupStudy) ?
              <div id="sidebar">
                {show_timer && this.state.timing !== null && this.everyoneReady() ?
                    <div className="time-nav sidenav">
                      {this.props.role === "candidate-1" && this.currentState() !== "Feedback Time" ? <div className="show-all-switch"><Switch onChange={() => this.setState({ show_all: !this.state.show_all })} />{this.state.show_all ? "Show Less Content" : "Show All Content"}</div> : ""}
                      {this.props.groupStudy && this.props.role === "examiner" ? <Tooltip placement="right" title="Sync Timers"><Button type="primary" onClick={() => this.syncTimers()} icon="sync" className="sync-timers" /></Tooltip> : ""}
                      <CountdownDisplay rerender={this.state.rerender} timing={this.state.timing} updateTiming={this.updateTiming} show={this.state.show_timer} paused={this.state.timer_paused} />
                      {this.state.timing !== null && (this.props.role === "candidate-1" || this.props.role === "examiner") ?
                          <div className="timing-controls">
                            <Button.Group>
                              <Tooltip placement="bottom" title={this.state.show_timer ? "Hide timer" : "Show timer"}>
                                <Button size="default" onClick={() => this.setState({ show_timer: !this.state.show_timer })}> {this.state.show_timer ? <Icon type="eye-invisible" /> : <Icon type="eye" />} </Button>
                              </Tooltip>
                              <Tooltip placement="bottom" title="Previous step">
                                <Button size="default" onClick={() => this.back(true)}><Icon type="step-backward" /></Button>
                              </Tooltip>
                              {this.state.timing.disabled !== true ?
                                <Tooltip placement="bottom" title={this.state.timer_paused ? "Resume" : "Pause"}>
                                  <Button size="default" onClick={this.togglePause}> {this.state.timer_paused ? <Icon type="caret-right" /> : <Icon type="pause" />} </Button>
                                </Tooltip> : ""}
                              <Tooltip placement="bottom" title="Next step">
                                <Button size="default" onClick={() => this.skip(true)}><Icon type="step-forward" /></Button>
                              </Tooltip>
                              <Tooltip placement="bottom" title="Finish station">
                                <Button size="default" onClick={this.end}><Icon type="fast-forward" /></Button>
                              </Tooltip>
                            </Button.Group>
                          </div>
                      : ""}

                      {/* <HeadingButtons preview={this.props.preview} timing={this.state.timing} /> */}
                    </div>
                : ''}

                {this.props.groupStudy ?
                  <GroupStudy layout="vertical" displayStation={false} />
                : ''}
              </div>
            : ''}

            {show_timer && this.state.timing !== null && this.everyoneReady() &&
              <Drawer
                title="Study Timers"
                placement="right"
                closable={true}
                onClose={this.onClose}
                visible={isSmallScreen}
                className={"time-nav sidenav " + (isSmallScreen && this.state.visible ? 'open' : isSmallScreen && !this.state.visible ? 'closed' : '')}
                mask={isSmallScreen && this.state.visible ? true : false}
                width="356"
              >
                <div>
                  {this.props.role === "candidate-1" && this.currentState() !== "Feedback Time" ? <div className="show-all-switch"><Switch onChange={() => this.setState({ show_all: !this.state.show_all })} />{this.state.show_all ? "Show Less Content" : "Show All Content"}</div> : ""}
                  {this.props.groupStudy && this.props.role === "examiner" ? <Tooltip placement="right" title="Sync Timers"><Button type="primary" onClick={() => this.syncTimers()} icon="sync" className="sync-timers" /></Tooltip> : ""}
                  <CountdownDisplay rerender={this.state.rerender} timing={this.state.timing} updateTiming={this.updateTiming} show={this.state.show_timer} paused={this.state.timer_paused} />
                  {this.state.timing !== null && (this.props.role === "candidate-1" || this.props.role === "examiner") ?
                      <div className="timing-controls">
                        <Button.Group>
                          <Tooltip placement="bottom" title={this.state.show_timer ? "Hide timer" : "Show timer"}>
                            <Button size="default" onClick={() => this.setState({ show_timer: !this.state.show_timer })}> {this.state.show_timer ? <Icon type="eye-invisible" /> : <Icon type="eye" />} </Button>
                          </Tooltip>
                          <Tooltip placement="bottom" title="Previous step">
                            <Button size="default" onClick={() => this.back(true)}><Icon type="step-backward" /></Button>
                          </Tooltip>
                          {this.state.timing.disabled !== true ?
                            <Tooltip placement="bottom" title={this.state.timer_paused ? "Resume" : "Pause"}>
                              <Button size="default" onClick={this.togglePause}> {this.state.timer_paused ? <Icon type="caret-right" /> : <Icon type="pause" />} </Button>
                            </Tooltip> : ""}
                          <Tooltip placement="bottom" title="Next step">
                            <Button size="default" onClick={() => this.skip(true)}><Icon type="step-forward" /></Button>
                          </Tooltip>
                          <Tooltip placement="bottom" title="Finish station">
                            <Button size="default" onClick={this.end}><Icon type="fast-forward" /></Button>
                          </Tooltip>
                        </Button.Group>
                      </div>
                  : ""}

                  {/* <HeadingButtons preview={this.props.preview} timing={this.state.timing} /> */}
                </div>
              </Drawer>
            }

            {this.props.groupStudy ?
              <Drawer
                title="Group Study"
                placement="right"
                closable={true}
                onClose={this.closeGroup}
                visible={isSmallScreen && this.state.group_visible}
                className="time-nav sidenav"
                mask={true}
                width="356"
              >
                <GroupStudy layout="vertical" displayStation={false} />
              </Drawer>
            : ''}
          </div>

          {isSmallScreen && ((show_timer && this.state.timing !== null && this.everyoneReady()) || this.props.groupStudy) ?
            <Button.Group className={"show-drawer " + ((window.innerHeight + window.pageYOffset + 32) < document.getElementById('footer').offsetTop ? 'pinned' : '')}>
              {show_timer && this.state.timing !== null && this.everyoneReady() ?
                <Button type="primary" onClick={this.showDrawer}>Show Timer</Button>
              : ''}
              {this.props.groupStudy ?
                <Button type="primary" onClick={() => this.setState({group_visible: !this.state.group_visible})}>Show Group</Button>
              : ''}
            </Button.Group>
          : ''}
        </div>
      </div>
    )
  }
}

export default connect(state => {
  return {
    groupStudy: state.groupStudy !== null,
    group: state.groupStudy,
    members: state.members,
    stationReady: state.stationReady,
    playlist: state.playlist,
    playlist_index: state.playlistIndex,
    role: state.role
  }
})(Station);