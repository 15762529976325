import Echo from "laravel-echo"
import { API } from "./API"
import { updateGroupMembers,
          changeGroupStudy,
          toggleGroupStudyFlag,
          updateSuggestedStations,
          toggleStationUpdateFlag,
          updateSuggestedPlaylists,
          togglePlaylistUpdateFlag,
          toggleStationStart,
          setCurrentStation,
          updateGroupName,
          updateGroupUniqueId,
          setRole,
          roleUpdated,
          setPlaylist } from "../constants/ActionTypes"
import { message } from "antd"
import { push } from "react-router-redux";
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const pusher_key = process.env.REACT_APP_PUSHER_KEY;
const log_pusher = process.env.REACT_APP_LOG_PUSHER;

export function setupEcho()
{
  return (dispatch, getState) => {
    let { token } = getState().auth;
    if(token !== null)
    {
      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: pusher_key,
        cluster: 'ap4',
        forceTLS: true,
        authEndpoint: apiEndpoint + '/broadcast/auth',
        auth: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      });
    }
  }
}

export function start(group)
{
  return (dispatch, state) => {
    //console.log(state());
    let group_name = group.code;
    if (group.nickname !== null && group.nickname.length > 0)
      group_name = group.nickname + " (" + group.code + ")";

    Pusher.logToConsole = log_pusher;
    dispatch(changeGroupStudy(group.id));
    dispatch(updateGroupName(group_name));

    dispatch(setRole(null));
    dispatch(roleUpdated(state().roleFlag));

    let presence = window.Echo.join('group-' + group.id);

    presence.on('pusher:subscription_succeeded', (event) => {
        dispatch(updateGroupUniqueId(event.myID))
      })
      .here((users) => {  //User has arrived - users are everyone currently in group
        dispatch(updateGroupMembers(users));
      })
      .joining((user) => {  //New user has joined - user is the new user
        message.success(user.name + " joined the study session!");
        if (state().role !== null)  //Update so that new user knows our role
          dispatch(API("/broadcast/change_role", "POST", { role: state().role, group: group.id, uid: state().groupUniqueId }))

        let station = state().stations.find(station => station.user === state().groupUniqueId);  //Broadcast what we've suggested so the new user knows
        if (station !== undefined)
          dispatch(API("/broadcast/suggest_station", "POST", { station: station.station_no, group: state().groupStudy, notify: false, uid: state().groupUniqueId }))

        let playlist = state().groupPlaylists.find(playlist => playlist.user = state().groupUniqueId);
        if (playlist !== undefined)
          dispatch(API("/broadcast/suggest_playlist", "POST", { playlist: playlist.playlist, group: state().groupStudy, notify: false, uid: state().groupUniqueId }))

        if (state().role === "examiner" && state().currentStation !== null)
          dispatch(API("/broadcast/set_station", "POST", { group: state().groupStudy, station: state().currentStation }));

        let members = state().members;
        members.push(user);
        dispatch(updateGroupMembers(members));
        dispatch(toggleGroupStudyFlag(!state().groupUpdateFlag));
      })
      .leaving((user) => {  //Other user has left - user is the user leaving
        message.warning(user.name + " left the study session");

        let group = state().members;
        group.splice(group.indexOf(group.find(value => value.uid === user.uid)), 1)
        dispatch(updateGroupMembers(group));
        dispatch(toggleGroupStudyFlag(!state().groupUpdateFlag));

      })
      .on('change-role', (event) => {
        let group = state().members;
        let member = group.find(member => member.uid == event.user);
        member.role = event.role;

        dispatch(updateGroupMembers(group));
        dispatch(toggleGroupStudyFlag(!state().groupUpdateFlag));
      })
      .on('suggest-station', (event) => {
        let examiner = state().role !== "candidate-2"
        if (event.station.user !== state().groupUniqueId && event.station.notify)
          message.success(event.station.name + " has suggested " + (examiner ? event.station.station_name : "station " + event.station.station_no))
        else if (event.station.user === state().groupUniqueId && event.station.notify)
          message.success("You have suggested " + (examiner ? event.station.station_name : "station " + event.station.station_no))

        let stations = state().stations;
        let index = stations.indexOf(stations.find(station => station.user === event.station.user));
        if (index > -1)
          stations.splice(index, 1);
        stations.push(event.station);

        dispatch(updateSuggestedStations(stations))
        dispatch(toggleStationUpdateFlag(!state().stationsUpdateFlag));
      })
      .on('suggest-playlist', (event) => {
        if (event.playlist.user !== state().groupUniqueId && event.playlist.notify)
          message.success(event.playlist.name + " has added a playlist suggestion!");
        else if (event.playlist.user === state().groupUniqueId && event.playlist.notify)
          message.success("You have suggested this playlist")

        let playlists = state().groupPlaylists;
        let index = playlists.indexOf(playlists.find(playlist => playlist.user === event.playlist.user))
        if (index > -1)
          playlists.splice(index, 1);
        playlists.push(event.playlist);

        dispatch(updateSuggestedPlaylists(playlists))
        dispatch(togglePlaylistUpdateFlag(!state().groupPlaylistsUpdateFlag))
      })
      .on('set-station', (event) => {
        if (state().currentStation === null)
          dispatch(setCurrentStation(event.station))
      })
      .on('clear-role', (event) => {
        message.warning("Your role has been cleared and you are now an observer")
        dispatch(setRole("observer"))
        dispatch(roleUpdated(!state().roleFlag))

        let group = state().members;
        group.forEach(member => {
          member.role = "observer";
        })

        dispatch(updateGroupMembers(group));
        dispatch(toggleGroupStudyFlag(!state().groupUpdateFlag));

        if (event.redirect)
          dispatch(push("/study"));
      })
      .listenForWhisper('station', (event) => {
        dispatch(setPlaylist(null));
        dispatch(updateSuggestedStations([]))
        dispatch(updateSuggestedPlaylists([]))
        dispatch(toggleStationUpdateFlag(!state().stationsUpdateFlag));
        dispatch(toggleStationStart(false));
        dispatch(push("/station/" + event.station))
      })
      .listenForWhisper('ready', (event) => {
        dispatch(toggleStationStart(true));
      })
      .listenForWhisper('remove', (event) => {
        if (event.uid === state().groupUniqueId)
          dispatch(end());
      })
      .listenForWhisper('request-station', (event) => {
        if (state().role === "examiner" && state().currentStation !== null)
          dispatch(API("/broadcast/set_station", "POST", { group: state().groupStudy, station: state().currentStation }));
      });

    window.presence = presence;

    dispatch(push("/study"));
  }
}

export function end(redirect = null)
{
  return (dispatch, state) => {
    let group = state().groupStudy;
    if (group === null)
      return false;

    dispatch(toggleStationStart(false));
    dispatch(changeGroupStudy(null));
    dispatch(updateGroupName(""));
    dispatch(updateGroupMembers([]));
    dispatch(updateSuggestedStations([]));
    dispatch(updateSuggestedPlaylists([]))
    dispatch(updateGroupUniqueId(""))
    if (state().role !== "candidate-1")
    {
      dispatch(setRole(null))
      dispatch(roleUpdated(!state().roleFlag))
    }

    window.Echo.leave('group-' + group);
    if (redirect == null)       //Null: go to profile
      dispatch(push("/profile"));
    else if (redirect !== false)  //Not false: go where we're being directed
      dispatch(push(redirect))

    //False: no redirecting; we're ending because we've navigated away ourselves
  }
}

export function broadcastRole(role)
{
  return (dispatch, state) => {
    let group = state().groupStudy;
    if (group === null)
      return false;

    dispatch(API("/broadcast/change_role", "POST", { role: role, group: state().groupStudy, uid: state().groupUniqueId }));
  }
}

export function broadcastStationSuggestion(station)
{
  return (dispatch, state) => {
    let group = state().groupStudy;
    if (group === null)
      return false;

    if (state().members.find(member => member.role === "examiner") === undefined)
      message.warning("To start a station you require an examiner");

    dispatch(API("/broadcast/suggest_station", "POST", { station: station, group: state().groupStudy, notify: true, uid: state().groupUniqueId }))
  }
}

export function broadcastPlaylistSuggestion(playlist)
{
  return (dispatch, state) => {
    let group = state().groupStudy;
    if (group === null)
      return false;

    if (state().members.find(member => member.role === "examiner") === undefined)
      message.warning("To start a playlist you require an examiner");

    dispatch(API("/broadcast/suggest_playlist", "POST", { playlist: playlist, group: state().groupStudy, notify: true, uid: state().groupUniqueId }))
  }
}

export function beginStation(station)
{
  return (dispatch, state) => {
    dispatch(updateSuggestedStations([]))
    dispatch(updateSuggestedPlaylists([]))
    dispatch(toggleStationUpdateFlag(!state().stationsUpdateFlag));

    window.presence.whisper('station', {
        station: station
      });

    dispatch(push("/station/" + station));
  }
}

export function readyToStart(toggle)
{
  return (dispatch, state) => {
    let group = state().groupStudy;
    if (group === null)
      return false;

    dispatch(toggleStationStart(true));
    if (toggle)
      window.presence.whisper('ready', { ready: true });
  }
}

export function removeUser(uid)
{
  return (dispatch, state) => {
    window.presence.whisper('remove', { uid: uid })
  }
}

export function clearRole(redirect)
{
  return (dispatch, state) => {
    dispatch(API("/broadcast/clear_role", "POST", { group: state().groupStudy, redirect: redirect }));
  }
}