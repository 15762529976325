import React, { Component } from "react";
import { connect } from "react-redux";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Input, Modal } from "antd";
import Playlist from "./Playlist";

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  margin: `0 0 .5em 0`,

  ...draggableStyle
});

class Playlists extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: null,
      show_delete_modal: false,
      show_delete_item_modal: false,
      show_edit_name_modal: false,
      current_playlist: null,
      current_playlist_item: null,
      playlist_name: ""
    }
  }

  onDragEnd = (result) => {
    if (!result.destination)
      return;
    let source = result.source.index;
    let dest = result.destination.index;
    if (source === dest)
      return;

    if (result.type == "playlists")
    {
      let playlists = Array.from(this.props.playlists);
      let removed = playlists.splice(source, 1);
      playlists.splice(dest, 0, removed[0]);
      this.props.reorderPlaylists(playlists);
    }
    else if (result.type == "playlist-items")
    {
      let playlist = this.props.playlists.find(playlist => playlist.id === result.destination.droppableId);
      let items = Array.from(playlist.items);
      let removed = items.splice(source, 1);
      items.splice(dest, 0, removed[0]);

      playlist.items = items;

      this.props.reorderPlaylistItems(items, playlist);
    }
  }

  delete = () => {
    this.props.deletePlaylist(this.state.current_playlist.id);
    this.setState({ show_delete_modal: false, current_playlist: null });
  }

  deleteItem = () => {
    this.props.deletePlaylistItem(this.state.current_playlist_item);
    this.setState({ show_delete_item_modal: false, current_playlist_item: null })
  }

  updatePlaylistName = () => {
    this.props.updatePlaylistName(this.state.playlist_name, this.state.current_playlist.id)
    this.setState({ show_edit_name_modal: false, current_playlist: null, playlist_name: "" })
  }

  updateName = (e) => {
    let { value } = e.target;

    this.setState({ playlist_name: value });
  }

  render() {
    return <div id="playlist">
      <Modal
        title="Confirm"
        visible={this.state.show_delete_modal}
        onCancel={() => this.setState({ show_delete_modal: false, current_playlist: null })}
        onOk={() => this.delete()}
        cancelText="Cancel"
        okText="Confirm"
      >
       {this.state.current_playlist != null
        ? <p>Are you sure you want to delete <b>{this.state.current_playlist.name}</b>?</p>
        : ""}
      </Modal>

      <Modal
        title="Confirm"
        visible={this.state.show_delete_item_modal}
        onCancel={() => this.setState({ show_delete_item_modal: false, current_playlist_item: null })}
        onOk={() => this.deleteItem()}
        cancelText="Cancel"
        okText="Confirm"
      >
        <p>Are you sure you want to delete this item?</p>
      </Modal>

      <Modal
        title="Edit Playlist"
        visible={this.state.show_edit_name_modal}
        onCancel={() => this.setState({ show_edit_name_modal: false, current_playlist: null, playlist_name: "" })}
        onOk={() => this.updatePlaylistName()}
        cancelText="Cancel"
        okText="Confirm"
      >
        <Input onChange={(e) => this.updateName(e)} value={this.state.playlist_name} />
      </Modal>

      <DragDropContext onDragEnd={this.onDragEnd}>
        {this.props.playlists.map((playlist, index) => {
          return <Playlist key={"playlist-list-" + index}
                          playlist={playlist}
                          open={this.props.open_playlist == playlist.id}
                          toggle={() => this.props.toggleOpen(playlist.id)}
                          delete={() => this.setState({ current_playlist: playlist, show_delete_modal: true })}
                          deleteFromPlaylist={(item_id) => this.setState({ current_playlist_item: item_id, show_delete_item_modal: true})}
                          editName={() => this.setState({ current_playlist: playlist, show_edit_name_modal: true, playlist_name: playlist.name })} />
            })
        }
      </DragDropContext>
    </div>;
  }
}
export default connect()(Playlists);