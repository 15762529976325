import React, { Component } from "react";
import { connect } from "react-redux";
import {  Modal } from "antd";

/**
 * We will *probably* only need this for the one modal, but if we need to add others later
 * then it's set up so we can add them easily
 */
class AuthModals extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    }
  }
  componentWillUnmount = () => {
    this.unmounted = true;
  }

  confirmTokenWipe = async() => {
    this.setState({ loading: true });
    await this.props.modalConfirm();
    if (!this.unmounted)
      this.setState({ loading: false});
  }

  render() {
    return (
      <div>
        <Modal
          title="Warning"
          visible={this.props.visible_modal === "confirm_token_wipe"}
          onOk={this.confirmTokenWipe}
          onCancel={() => this.props.close()}
          okText="Yes"
          cancelText="No"
          okButtonProps={{ loading: this.state.loading }}
        >
          <p>You appear to be logged in on another device. If you continue, you will be automatically logged out
              of all other devices. Would you like to continue?</p>
        </Modal>
      </div>
    )
  }
}

export default connect()(AuthModals);