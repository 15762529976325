import {
  PLAYLIST,
  PLAYLISTS,
  PLAYLIST_INDEX
} from "../constants/ActionTypes";

export const playlist = (state = null, action) => {
  switch (action.type) {
    case PLAYLIST:
      return action.value
    default:
      return state;
  }
}

export const playlists = (state = null, action) => {
  switch(action.type) {
    case PLAYLISTS:
      return action.value;
    default:
      return state;
  }
}

export const playlistIndex = (state = 0, action) => {
  switch(action.type) {
    case PLAYLIST_INDEX:
      return action.value;
    default:
      return state;
  }
}