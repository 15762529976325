import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "antd";

class StationContinue extends Component {
  render() {
    return (
      <div>
        <h3>This station has now ended</h3>
        {this.props.role == "candidate-1" || this.props.role == "examiner" ?
          <div>
            <p>Please choose an option to continue.</p>
            <div className="centered-buttons">
              <Button onClick={() => this.props.feedback()}>Return to feedback</Button>
              <Button onClick={() => this.props.finish()} type="primary">Finish station</Button>
            </div>
          </div>
          : <p>Waiting for the Examiner to continue.</p>}
      </div>
    )
  }
}

export default connect(state => {
  return {
    role: state.role
  }
})(StationContinue);