import React, { Component } from "react";
import { connect } from "react-redux";
import { APITokenless } from '../../actions/API.js';
import { Link } from "react-router-dom";
import { Skeleton, Card, Tag, List } from "antd";
import MetaTags from 'react-meta-tags';
import blogDefault from "../../static/images/blog-default-image.jpg";

const base_url = process.env.REACT_APP_IMAGE_ENDPOINT + "blog/";

class Blogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogs: [],
      loading: true
    }
  }

  componentDidMount = async() => {
    let blogs = await this.props.dispatch(APITokenless("/blogs", "GET"));
    if (!this.unmounted)
      this.setState({ blogs, loading: false });
  }

  componentWillUnmount = () => {
    this.unmounted = true;
  }

    render() {
        if (this.state.loading)
            return <div>
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
            </div>
        return  <div id="blogs">
                    <MetaTags>
                        <title>Blog | OSCEbank</title>
                    </MetaTags>

                    <div className={`container fixed`}>
                        <h1 className="page-title">Blog</h1>
                        {this.state.blogs.length > 0 ?
                            <List
                                pagination={{pageSize: 10}}
                                type="flex"
                                grid={{
                                    gutter: 20,
                                    xs: 1,
                                    md: 2,
                                    lg: 3
                                }}
                                dataSource={this.state.blogs.map((value, index) => {
                                    return <Link to={"blog/" + value.slug}>
                                                <Card title={value.title} key={"blog-" + index}>
                                                    <div className="blog-card-content">
                                                        {value.image !== "" && value.image !== null && value.image !== undefined ?
                                                        <img className="blog-thumbnail" src={base_url + value.id + "/" + value.image} alt={value.title + " feature image"} /> :
                                                        <img className="blog-thumbnail" src={blogDefault} alt={value.title + " feature image"} />}
                                                        <p dangerouslySetInnerHTML={{ __html: value.extract }} />

                                                        <div className="blog-tags">
                                                            {value.tags.map((tag, index) => {
                                                                return <Tag color="blue" key={"tag-" + value.id + "-" + index}>{tag.name}</Tag>
                                                            })}
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Link>
                                })}
                                renderItem={item => (
                                    <List.Item key={item.key}>
                                        {item}
                                    </List.Item>
                                )}
                            >
                            </List> : <div>New blog posts are coming soon! Check back later :)</div>
                        }
                    </div>
                </div>
    }
}

export default connect()(Blogs);