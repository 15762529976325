import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Icon } from "antd";
import { connect } from "react-redux";

import logo from "../../static/images/logo.svg";
import facebook from "../../static/images/facebook.svg";
import instagram from "../../static/images/instagram.svg";
import email from "../../static/images/email.svg";


class Footer extends Component {
    render() {
        return (
            <div id="footer" className="footer">
                <div className={`container fixed`}>
                    <div className="row">
                        <div className="col">
                            <Link to="/">
                                <img src={logo} id="logo-footer" alt="OSCEbank logo" />
                            </Link>
                        </div>

                        <div className="col">
                            <ul>
                                <li>
                                    <Link to="/">
                                        Home
                                    </Link>
                                </li>

                                <li>
                                    <Link to={"/about-us"}>
                                        About
                                    </Link>
                                </li>
                                {!this.props.isAuthenticated ?
                                  <li>
                                    <Link to={"/subscription-plans"}>
                                        Subscribe
                                    </Link>
                                  </li> : ""}
                                {!this.props.isAuthenticated ?
                                  <li>
                                      <Link to="/free-trial">
                                          Trial
                                      </Link>
                                  </li> : ""}
                                <li>
                                    <Link to={"/blog"}>
                                        Blog
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/faqs">
                                        FAQs
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/contact"}>
                                        Contact
                                    </Link>
                                </li>
                            </ul>

                            <div className="legal-links">
                                <div className="left-col">
                                    <a href="https://www.facebook.com/OSCEbank/" target="_blank"><img src={facebook} alt="OSCEbank on facebook" /></a>
                                    <a href="https://www.instagram.com/oscebank/" target="_blank"><img src={instagram} alt="OSCEbank on instagram" /></a>
                                    <a href="mailto:info@oscebank.com" target="_blank"><img src={email} alt="OSCEbank on email" /></a>
                                </div>
                                <div className="right-col">
                                    <Link to={"/privacy-policy"}>
                                        Privacy Policy
                                    </Link>
                                    <Link to={"/terms-and-conditions"}>
                                        Terms and Conditions
                                    </Link>
                                    <p>&copy; {new Date().getFullYear()} OSCEbank. All rights reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(Footer);
