import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, List, Spin, Icon, Input, Tabs } from "antd";
import { API } from '../../actions/API.js';
import MarkingDisplay from "./MarkingDisplay"


import candidate from "../../static/images/candidate-icon.svg";
import patient from "../../static/images/patient-icon.svg";
import examiner from "../../static/images/examiner-icon.svg";


const TabPane = Tabs.TabPane;

class StationHistory extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      guides: [],
      name: null
    }
  }

  componentDidMount = async() => {
    this.setState({ loading: true })

    let markings = await this.props.dispatch(API("/station/" + this.props.stationId + "/markings", "GET"));
    this.setState({ loading: false, guides: markings.guides, name: markings.name})
    this.props.setHistory(markings.markings, markings.markings[0].id);
  }

  marking() {
    if (this.props.markingId === null)
      return {};

    return this.props.markings.find(marking => marking.id === this.props.markingId);
  }

  roleImage(role) {
    switch(role)
    {
      case "candidate-1":
      case "candidate-2":
        return <img src={candidate} className="role-img" />
      case "patient":
        return <img src={patient} className="role-img" />
      case "examiner":
        return <img src={examiner} className="role-img" />
    }
  }

  roleText(role) {
    switch (role) {
      case "candidate-1":
        return "Solo Candidate"
      case "candidate-2":
        return "Candidate"
      case "patient":
        return "Patient"
      case "examiner":
        return "Examiner"
    }
  }

  render() {

    if (this.state.loading)
      return <Spin />

    return (
      <div className="notes-and-marking">
        <Button type="primary" onClick={() => this.props.displayStations()} className="start-btn">
          <Icon type="left" /> Back
        </Button>

        <h1>{this.state.name !== null ? this.state.name : ""}</h1>

        {this.props.markingId !== null ?
          <div className="display-info">
            <Tabs type="card" defaultActiveKey="scores">
              <TabPane tab={<span><b><Icon type="trophy" />Marking Guide</b></span>} key="scores" className="notes-scores-display">
                <MarkingDisplay
                  guides={this.state.guides}
                  marks={this.marking().scores}
                  globalScore={this.marking().global_score}
                />
              </TabPane>
              {this.marking().notes !== null ?
                <TabPane tab={<span><b><Icon type="book" />Notes</b></span>} key="notes" className="notes-scores-display">
                  <h2>Notes</h2>
                  <Input.TextArea
                    value={this.marking().notes.notes}
                    autosize={{ minRows: 5 }}
                  />

                  {this.marking().notes.examiner_notes !== null ?
                    <div className="examiner-notes">
                      <h2>Examiner Notes</h2>
                      <Input.TextArea
                        value={this.marking().notes.examiner_notes}
                        autosize={{ minRows: 5 }}
                      />
                    </div> : ""}
                </TabPane> : ""}
            </Tabs>
          </div>
        : ""}
      </div>
    )
  }
}

export default connect()(StationHistory);