import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Elements, StripeProvider } from 'react-stripe-elements';
import { API, APITokenless } from '../../actions/API.js';
import { message, Spin, Card, Row, Col, Tag, Button, Modal, Divider } from "antd";
import PurchaseSubscriptionForm from "./PurchaseSubscriptionForm";
import { getUser, displayPrice } from "../../actions/functions";
import MetaTags from 'react-meta-tags';

import subscription from "../../static/images/subscription.svg";

const stripeKey = process.env.REACT_APP_STRIPE_API_KEY;
const country = process.env.REACT_APP_COUNTRY
const paypal_currency = process.env.REACT_APP_CURRENCY;

class SubscriptionsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      loading: true,
      subscriptions: null,
      subscription_types: null,
      expiry_date: null,
      selected_subscription: null,
      purchase_loading: false,
      visible_modal: false,
      cancel_loading: false,
      voucher: null
    }
  }

  componentDidMount = async () => {
    let user = await getUser(this.props);
    let subscriptions = await this.props.dispatch(API("/user/subscriptions", "GET"));
    let subscription_types = await this.props.dispatch(API("/subscription_types/renewable?country=" + country, "GET"));
    let expiry_date = null;
    if (subscriptions.length > 0)
    {
      if (subscriptions[subscriptions.length - 1].status !== "expired")
        expiry_date = subscriptions[subscriptions.length - 1].expiry_date;
      else
        expiry_date = false;
    }
    if (!this.unmounted)
      this.setState({ user, subscriptions: subscriptions, subscription_types, expiry_date, loading: false });
  }

  creditCardPayment = async(values) => {
    values.subscription_type = this.state.selected_subscription.id;
    if (this.state.voucher !== null)
      values.voucher = this.state.voucher.id;
    values.country = country;
    let add = await this.props.dispatch(API("/user/purchase_subscription_credit_card", "POST", values));
    if (add.success === false)
    {
      message.error(add.message, 10);
      this.setState({ purchase_loading: false });
      return false;
    }
    else if (add.success === true)
    {
      await this.loadNewSubscriptions();
    }
    this.setState({ purchase_loading: false, selected_subscription: null });
    return true;
  }

  payPalPayment = async(response) => {
    if (response.paid === true) {
      this.setState({ purchase_loading: true });
      let data = {
        subscription_type: this.state.selected_subscription.id,
        payment_id: response.paymentID,
        voucher: this.state.voucher !== null ? this.state.voucher.id : null
      }
      let add = await this.props.dispatch(API("/user/purchase_subscription_paypal", "POST", data));
      if (add.success === false) {
        message.error(add.message, 10);
        this.setState({ purchase_loading: false });
        return false;
      }
      else if (add.success === true) {
        await this.loadNewSubscriptions();
      }
      this.setState({ purchase_loading: false, selected_subscription: null });
      return true;
    }
  }

  freeSubscription = async() => {
    this.setState({ purchase_loading: true });
    let subscription = await this.props.dispatch(API("/user/purchase_free_subscription", "POST", { subscription_type: this.state.selected_subscription.id, voucher: this.state.voucher.id }));
    if (subscription.success === false)
    {
      message.error(add.message, 10);
      this.setState({ purchase_loading: false });
      return false;
    }
    await this.loadNewSubscriptions();
    this.setState({ purchase_loading: false, selected_subscription: null });
    return true;
  }

  loadNewSubscriptions = async() => {
    let subscriptions = await this.props.dispatch(API("/user/subscriptions", "GET"));
    let expiry_date = this.state.expiry_date;
    if (expiry_date === null && subscriptions.length > 0)
      expiry_date = subscriptions[subscriptions.length - 1].expiry_date;
    this.setState({ subscriptions, expiry_date });
    message.success("You have successfully purchased a new subscription!");
  }

  cancel = async() => {
    this.setState({ cancel_loading: true });
    await this.props.dispatch(API("/user/cancel", "POST", {cancel: true}));
    let subscriptions = await this.props.dispatch(API("/user/subscriptions", "GET"));
    message.success("You have successfully cancelled this subscription");
    this.setState({ visible_modal: false, subscriptions, cancel_loading: false });
  }

  setPurchaseLoadingState = (value) => {
    this.setState({purchase_loading: value});
  }

  selectSubscription = (subscription) => {
    if (this.state.subscriptions !== null && this.state.subscriptions.some(subscription => subscription.status === "pending")) {
      message.error("You already have a pending subscription. You can only have one pending subscription at a time.")
      return true;
    }
    this.setState({ selected_subscription: subscription });
  }

  applyVoucher = async (voucher) => {
    this.setState({ purchase_loading: true });
    let response = await this.props.dispatch(APITokenless("/apply_voucher", "POST", { voucher: voucher }));
    this.setState({ purchase_loading: false });
    if (response.success !== undefined && response.success === false)
      message.error("Sorry, but that doesn't look like a valid voucher");
    else {
      this.setState({ voucher: response });
      message.success("You have successfully applied your voucher!");
    }
    return true;
  }

  render() {
    let tags = {
      active: <Tag color="green">Active</Tag>,
      pending: <Tag color="blue">Pending</Tag>,
      cancelled: <Tag color="volcano">Cancelled</Tag>,
      expired: <Tag color="#cccccc">Expired</Tag>,
      refunded: <Tag color="geekblue">Refunded</Tag>
    };

    return <div id="subscriptions-page">
      {this.state.loading ?
        <Spin />
        :
        <div>
            <MetaTags>
                <title>Subscriptions | OSCEbank</title>
            </MetaTags>

            <div className={`container fixed`}>
                <h1 style={{ textAlign: 'center' }}>Manage Subscriptions</h1>

                <Modal
                    title="Warning"
                    visible={this.state.visible_modal}
                    onOk={() => this.cancel()}
                    onCancel={() => this.setState({ visible_modal: false })}
                    okText="Yes"
                    cancelText="No"
                    okButtonProps={{ loading: this.state.cancel_loading }}
                >
                    <p>Are you sure you want to cancel your subscription?</p>
                    <ul>
                        <li>You will still be able to access OSCEbank until {moment(this.state.expiry_date).format("DD/MM/YYYY")}</li>
                        <li>You will not receive any email notifications reminding you to renew your subscription</li>
                    </ul>
                </Modal>

                {this.state.subscriptions.length > 0 ?
                <div className="subscriptions-list">
                    {this.state.expiry_date !== false ? <p>You subscription expires on <b>{moment(this.state.expiry_date).format("DD/MM/YYYY")}</b></p> : ""}
                    {this.state.subscriptions.map((value, index) => {
                      return <Card key={'subscriptions-' + index} title={<span>{value.type.name} {tags[value.status]}</span>}>
                            <Row>
                                <Col md={6} sm={8} xs={24} className={"subscription-heading " + value.status}><b>Purchased</b><br/>{moment(value.created_at).format("DD/MM/YYYY")}</Col>
                                <Col md={6} sm={8} xs={24} className={"subscription-heading " + value.status}><b>{value.status !== "pending" ? "Activated" : "Activates"}</b><br/>{moment(value.start_date).format("DD/MM/YYYY")}</Col>
                                <Col md={6} sm={8} xs={24} className={"subscription-heading " + value.status}><b>{value.status !== "expired" ? "Expires" : "Expired"}</b><br/>{moment(value.expiry_date).format("DD/MM/YYYY")}</Col>
                                {value.status === "active" ? <Col md={6} sm={24} xs={24} className="btn-col"><Button type="danger" onClick={() => this.setState({ visible_modal: true })}>Cancel Subscription</Button></Col> : ""}
                                {value.status === "refunded" ? <Col md={6} sm={24} xs={24} className="btn-col"><b>Refunded</b><br />{value.refund_date}</Col> : ""}
                            </Row>
                        </Card>
                    })}
                </div>
                : <p>You do not have any subscriptions. This is because you are either an admin, or are cheating the system.</p>}
            </div>

            {this.state.subscriptions !== null && !this.state.subscriptions.some(subscription => subscription.status === "pending") && this.state.subscriptions.filter(sub => sub.status === "active").length < 2 ?
              <div className="subscriptions">
                  <div className="subscriptions-heading">
                      <h1 style={{ textAlign: 'center' }}>Purchase your next subscription</h1>
                      <p>Your next subscription will commence after your current one has expired. You can purchase up to two subscriptions at a time.</p>
                  </div>
                  <div className={`container fixed`}>
                      {this.state.subscription_types.map((value, index) => {
                          return <Card className={this.state.selected_subscription !== null && this.state.selected_subscription.id === value.id ? "selected" : ""} key={"subscription-select-" + index}>
                                      <img src={subscription} alt={value.name} />
                                      <h1>{value.name}</h1>
                                      <Divider/>
                                      <p className="cost">
                                          {displayPrice(value)}
                                          <span className="time-period">/ {value.display_length}</span>
                                      </p>
                                      <div className="description" dangerouslySetInnerHTML={{ __html: value.description }} />

                                      {this.state.expiry_date !== null && this.state.expiry_date !== false ?
                                          <p>This subscription will commence after your current one and will expire on {moment(this.state.expiry_date).add(value.length, 'days').format("DD/MM/YYYY")}.</p>
                                          : this.state.expiry_date === false ? <p>This subscription will commence immediately and will expire on {moment().add(value.length, 'days').format("DD/MM/YYYY")}.</p>
                                          : ""}

                                      {this.state.selected_subscription !== null && this.state.selected_subscription.id === value.id ?
                                          <Button type="primary" className="button-selected" onClick={() => this.selectSubscription(value)} disabled={this.state.purchase_loading}>Selected</Button>
                                          : <Button type="primary" onClick={() => this.selectSubscription(value)} disabled={this.state.purchase_loading}>Purchase</Button>}
                                  </Card>
                      })}
                  </div>
              </div> : ""}
            {this.state.subscriptions !== null && !this.state.subscriptions.some(subscription => subscription.status === "pending") && this.state.subscriptions.filter(sub => sub.status === "active").length < 2 ?
              <Spin spinning={this.state.purchase_loading}>
                  <StripeProvider apiKey={stripeKey}>
                      <Elements>
                          <PurchaseSubscriptionForm
                              creditCardPayment={this.creditCardPayment}
                              payPalPayment={this.payPalPayment}
                              freeSubscription={this.freeSubscription}
                              setPurchaseLoadingState={this.setPurchaseLoadingState}
                              subscription={this.state.selected_subscription}
                              voucher={this.state.voucher}
                              applyVoucher={this.applyVoucher}
                          />
                      </Elements>
                  </StripeProvider>
              </Spin> : ""}
        </div>}
    </div>;
  }
}
export default connect()(SubscriptionsPage);