import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Button, Input, Icon } from "antd";
const FormItem = Form.Item;

class ChangePasswordForm extends Component {
  submit = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSave(values);
        this.props.form.resetFields();
      }
    });
  }

  checkPasswords = (rule, value, callback) => {
    if (value && value !== this.props.form.getFieldValue("new_password1"))
      callback('The entered passwords do not match.');
    callback();
  }

  checkPasswordRules = (rule, value, callback) => {
    if (value === undefined || value === "")  //Only display one error at a time
      callback();
    else
    {
      if (value.length < 5)
        callback('Please enter at least five characters for your password');
      if (! /[A-Z]/.test(value))
        callback('Please ensure that your password has at least one upper-case letter in it');
      if (! /[0-9]/.test(value))
        callback('Please ensure that your password has at least one number in it')
      callback();
    }
  }

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;

    return (
      <Form className="change-password-form" style={{ margin: '3em auto' }}>
        <h1 style={{ textAlign: 'center', marginBottom: '1em' }}>Change Password</h1>

        <FormItem>
          {getFieldDecorator("current_password", {
            rules: [{ required: true, message: "Please enter your current password" }]
          })(<Input.Password
            size="large"
            placeholder="Enter your current password"
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
          />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator("new_password1", {
            rules: [{ required: true, message: "Please enter a new password" }, { validator: this.checkPasswordRules }]
          })(<Input.Password
            size="large"
            placeholder="Enter a new password"
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
          />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator("new_password2", {
            rules: [{ required: true, message: "Please repeat your new password" }, { validator: this.checkPasswords }]
          })(<Input.Password
            size="large"
            placeholder="Repeat your new password"
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
          />)}
        </FormItem>
        <Button
          type="primary"
          htmlType="submit"
          disabled={hasErrors(getFieldsError())}
          onClick={this.submit}
          icon="check"
          size="large"
          block
        >
          Change Password
        </Button>
      </Form>
    )
  }
}

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

export default connect()(Form.create({ name: 'settings' })(ChangePasswordForm));