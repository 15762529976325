import {
  MEMBERS_UPDATED,
  GROUP_STUDY,
  GROUP_STUDY_FLAG,
  STATIONS_SUGGESTED,
  STATION_UPDATE_FLAG,
  STATION_STARTED,
  CURRENT_STATION,
  GROUP_NAME,
  GROUP_UNIQUE_ID,
  PLAYLISTS_SUGGESTED,
  PLAYLIST_UPDATE_FLAG
} from "../constants/ActionTypes";

export const members = (state = [], action) => {
  switch (action.type) {
    case MEMBERS_UPDATED:
      return action.value
    default:
      return state;
  }
}

export const groupStudy = (state = null, action) => {
  switch(action.type) {
    case GROUP_STUDY:
      return action.value;
    default:
      return state;
  }
}

export const groupUpdateFlag = (state = false, action) => {
  switch(action.type) {
    case GROUP_STUDY_FLAG:
      return action.value;
    default:
      return state;
  }
}

export const stations = (state = [], action) => {
  switch(action.type) {
    case STATIONS_SUGGESTED:
      return action.value;
    default:
      return state;
  }
}

export const stationsUpdateFlag = (state = false, action) => {
  switch(action.type) {
    case STATION_UPDATE_FLAG:
      return action.value;
    default:
      return state;
  }
}

export const groupPlaylists = (state = [], action) => {
  switch(action.type) {
    case PLAYLISTS_SUGGESTED:
      return action.value;
    default:
      return state;
  }
}

export const groupPlaylistsUpdateFlag = (state = false, action) => {
  switch(action.type) {
    case PLAYLIST_UPDATE_FLAG:
      return action.value;
    default:
      return state;
  }
}

export const stationReady = (state = false, action) => {
  switch(action.type) {
    case STATION_STARTED:
      return action.value;
    default:
      return state;
  }
}

export const currentStation = (state = null, action) => {
  switch(action.type) {
    case CURRENT_STATION:
      return action.value;
    default:
      return state;
  }
}

export const groupName = (state = "", action) => {
  switch(action.type) {
    case GROUP_NAME:
      return action.value;
    default:
      return state;
  }
}

export const groupUniqueId = (state = "", action) => {
  switch(action.type) {
    case GROUP_UNIQUE_ID:
      return action.value;
    default:
      return state;
  }
}