import { push } from "react-router-redux";
import "whatwg-fetch";
import { message } from "antd";
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export function API(
  endpoint,
  method,
  data = null,
  contentType = "application/json",
  toJSON = true
) {
  return (dispatch, getState) => {
    let { token } = getState().auth;
    let config = {
      method: method,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`
      }
    };
    if (contentType) config.headers["Content-Type"] = contentType;

    if (method === "POST" || method === "PUT") {
      if (toJSON) config.body = JSON.stringify(data);
      else config.body = data;
    }

    return new Promise((resolve, reject) => {
      if (!token) {
        dispatch(push("/login"));
        //reject(new NoTokenError("No token saved. Redirecting..."));
      }

      fetch(`${apiEndpoint}${endpoint}`, config)
        .then(parseAndPreserveDetails)
        .then(res => {
          let { result, ok, status } = res;

          if (ok) {
            if (result.update_terms)
              localStorage.setItem("update_terms", true);
            resolve(result);
          }
          else {
            console.log(result);
            if (result.error === "token_expired") {
              //If we have an expired token, first invalidate the store
              //dispatch({ type: TOKEN_EXPIRED });

              //Now attempt to refresh the token
              /*refreshToken(token, dispatch)
                .then(newToken => {
                  //if the token is refreshed, try the original API method again
                  console.log("New token received");
                  resolve(dispatch(API(endpoint, method, data)));
                })
                .catch(reject);*/
            } else if (status === 403 && endpoint !== "/station/featured") {
              message.error("Please purchase a subscription if you wish to access this area.");
              dispatch(push("/subscriptions"));
            } else if (status === 403 && endpoint === "/station/featured") {
              resolve([]);
            } else if (status === 401) {
              if (localStorage.getItem("token"))
                localStorage.removeItem("token");
              message.error("Your credentials have expired. Please login again.");
              dispatch(push("/login"));
            } else {
              reject(result);
            }
          }
        });
    });
  };
}

function parseAndPreserveDetails(response) {
  return new Promise((resolve, reject) => {
    let ok = response.ok;
    let status = response.status;
    response
      .json()
      .then(result => resolve({ result, ok, status }))
      .catch(reject);
  });
}

export function APITokenless( //This function is for if we want to do API stuff with a route that doesn't require tokens - otherwise we get pushed to /login
  endpoint,
  method,
  data = null,
  contentType = "application/json",
  toJSON = true
) {
  return (dispatch, getState) => {
    let config = {
      method: method,
      headers: {
        Accept: "application/json"
      }
    };
    if (contentType) config.headers["Content-Type"] = contentType;

    if (method === "POST" || method === "PUT") {
      if (toJSON) config.body = JSON.stringify(data);
      else config.body = data;
    }

    return new Promise((resolve, reject) => {
      fetch(`${apiEndpoint}${endpoint}`, config)
        .then(parseAndPreserveDetails)
        .then(res => {
          let { result, ok } = res;

          if (ok) resolve(result);
          else {
            reject(result);
          }
        });
    });
  };
}