import React, { Component } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import { Form, Button, Radio, Divider, Input, Spin, Icon } from "antd";
import PaypalExpressBtn from 'react-paypal-express-checkout';
import { calculateTotalCost } from "../../actions/functions";
const FormItem = Form.Item;
const paypal_env = process.env.REACT_APP_PAYPAL_ENV;
const paypal_currency = process.env.REACT_APP_CURRENCY;
const paypal_client = {
  sandbox: process.env.REACT_APP_PAYPAL_SANDBOX_ID,
  production: process.env.REACT_APP_PAYPAL_PRODUCTION_ID
}

class PurchaseSubscriptionForm extends Component {
  constructor(props) {
    super(props);
    this.card_element = null;
  }

  submit = () => {
    this.props.form.validateFields(async (err, values) => {
      this.props.setPurchaseLoadingState(true);
      let { token } = await this.props.stripe.createToken({ name: "Name" });
      if (err || token === undefined)
        this.props.setPurchaseLoadingState(false);

      if (!err && token !== undefined) {
        values.token_id = token.id;
        if (await this.props.creditCardPayment(values))
        {
          this.props.form.resetFields();
        }
      }
    });
  }

  payPalPayment = async (response) => {
    await this.props.payPalPayment(response);
    this.props.form.resetFields();
  }

  applyVoucher = async () => {
    await this.props.applyVoucher(this.props.form.getFieldValue("voucher"));
  }

  freeSub = async () => {
    await this.props.freeSubscription();
    this.props.form.resetFields();
  }

  render() {
    const { getFieldsError, getFieldDecorator } = this.props.form;
    let cost = calculateTotalCost(this.props.voucher, this.props.subscription);
    if (cost === "0.00")
      this.props.form.resetFields("payment_method");

    let apply_voucher = this.props.form.getFieldValue("voucher") !== undefined && this.props.form.getFieldValue("voucher").length > 0 && this.props.voucher == null
    let currency_symbol = paypal_currency == "GBP" ? "£" : "$";

    return (
        <div className="payment-section" style={{ display: this.props.subscription !== null ? "block" : "none" }}>
            <div className={`container fixed`}>
                <Divider/>

                <Form className="purchase-subscription-form">
                    <h3>Payment Method</h3>
                    {cost !== "0.00" ?
                      <FormItem>
                          {getFieldDecorator("payment_method", {
                              rules: [{ required: true, message: "Please select how you are paying for your subscription" }]
                          })(<Radio.Group>
                              <Radio value="credit_card">Credit Card</Radio>
                              <Radio value="paypal">PayPal</Radio>
                          </Radio.Group>)}
                      </FormItem>
                    : ""}

                    <FormItem className="voucher">
                        <Form.Item style={{ display: 'inline-block', width: 'calc(70% - 5px)', marginBottom: '0' }}>
                            {getFieldDecorator("voucher", {})(
                                <Input
                                    size="large"
                                    placeholder="Have a voucher?"
                                />)}
                            {apply_voucher ? <p className="success"><span>Press Apply to use this voucher before making your payment.</span></p> : ""}
                        </Form.Item>
                        <Button
                            type="primary"
                            onClick={this.applyVoucher}
                            size="large"
                            id="apply-voucher-button"
                        >
                            Apply
                        </Button>
                    </FormItem>


                    { cost !== null && this.props.voucher !== null &&
                      (cost === this.props.subscription.cost || cost === this.props.subscription.discounted_cost)
                      ? <p className="success"><span>Sorry, but the voucher you entered cannot be used with this subscription type. Please select another subscription type.</span></p>
                      : cost !== null && this.props.voucher !== null
                        ? <p className="success"><Icon type="check-circle" theme="twoTone" /><span>Voucher has been successfully applied. <br /> Your new subscription cost is <b>{currency_symbol}{cost}</b>.</span></p>
                      : ""}

                    {this.props.form.getFieldValue("payment_method") === "credit_card" ?
                      <div>
                          <p><b style={{fontSize: "1.5em"}}>Please enter your Card Details</b></p>
                          <p>Your card details will <b>not</b> be used to auto-renew a subscription - only to purchase this one.</p>
                          <CardElement />
                          <Button
                              type="primary"
                              htmlType="submit"
                              disabled={hasErrors(getFieldsError()) || apply_voucher}
                              onClick={this.submit}
                              icon="check"
                              size="large"
                              block
                              style={{ marginTop: "2em" }}
                          >
                              Buy Subscription
                          </Button>
                      </div>
                    : ""}

                    <div id="paypal-payment" style={{ display: this.props.form.getFieldValue("payment_method") === "paypal" && parseFloat(cost) > 0 ? "block" : "none" }}>
                        {!apply_voucher ?
                          <PaypalExpressBtn env={paypal_env}
                              client={paypal_client}
                              currency={paypal_currency}
                              total={parseFloat(cost)}
                              onSuccess={this.payPalPayment}
                              onCancel={() => console.log("cancelled")}
                              onError={(err) => console.log(err)}
                          /> : ""}
                    </div>
                    {this.props.form.getFieldValue("payment_method") === "paypal" && parseFloat(cost) <= 0 ?
                      <div>
                        <p>The cost of your subscription comes to {currency_symbol}0, so it cannot be processed by PayPal. Please click the button below to complete your signup.</p>
                        <Button type="primary" disabled={apply_voucher} onClick={() => this.payPalPayment({ paid: true, paymentID: null })}>Complete Purchase</Button>
                      </div>
                    : ""}

                    {cost === "0.00" ?
                      <Button type="primary" onClick={() => this.freeSub()}>Activate Subscription</Button>
                    : ""}
                </Form>
            </div>
        </div>
    )
  }
}

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

export default injectStripe(Form.create({ name: 'purchase-subscription' })(PurchaseSubscriptionForm));