import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { API } from '../../../actions/API.js';
import { broadcastRole, end, clearRole } from "../../../actions/Echo"
import { toggleStationStart, setRole, roleUpdated } from "../../../constants/ActionTypes"
import { Col, Row, Icon, Modal, message } from "antd";
import ContactForm from "../../Contact/ContactForm";

import candidate from "../../../static/images/icon-candidate-white.svg";
import observer from "../../../static/images/icon-observer-white.svg";
import patient from "../../../static/images/icon-patient-white.svg";
import examiner from "../../../static/images/icon-examiner-white.svg";

class StationCompleted extends Component {
  constructor (props) {
    super(props);
    this.state = {
      playlist: false,
      playlist_number: null
    }
  }

  chooseRole = (value) => {
    this.props.dispatch(toggleStationStart(false))
    if(value === "candidate")
      value = "candidate-2";
    this.props.dispatch(setRole(value));
    this.props.dispatch(roleUpdated(!this.props.roleUpdated))
    this.props.dispatch(broadcastRole(value));
    if (this.props.preview)
      this.props.repeat(value);
    else
      this.props.redirect("/study");
  }

  roleChooserRedirect = (redirect) => {
    Modal.destroyAll();
    this.props.dispatch(push(redirect))
  }

  switchSolo = () => {
    Modal.destroyAll();
    this.props.dispatch(setRole("candidate-1"));
    this.props.dispatch(roleUpdated(!this.props.roleUpdated))
    this.props.dispatch(end("/study"));
  }

  changeToGroup = () => {
    this.redirect("/study-groups");
  }

  reportIssue = () => {
    Modal.warning({
      title: 'Report Issue',
      content: <ContactForm onSave={(values) => this.submitForm(values)} />,
      okText: 'Cancel',
      width: "80%"
    });
  }

  submitForm = async(values) => {
    values.station_id = this.props.stationId;

    await this.props.dispatch(API("/message/user-contact", "POST", values));
    message.success("Your message has been sent to an administrator. You should receive a reply within 24 hours.");
    Modal.destroyAll();
    return true;
  }

  goToStudy = () => {
    this.props.dispatch(toggleStationStart(false))
    this.props.dispatch(broadcastRole(this.props.role));
    Modal.destroyAll();
    if (this.props.preview)
      this.props.repeat(this.props.role);
    else
      this.redirect("/study");
  }

  redirect = (route) => {
    this.props.dispatch(toggleStationStart(false))
    if (this.props.preview && route === "/study")
      this.props.repeat(this.props.role);
    else
      this.props.redirect(route);
  }

  leaveGroup = () => {
    this.props.dispatch(end());
    this.redirect("/study")
  }

  repeat = (role) => {
    if (window.presence !== undefined )
      window.presence.whisper('reset', { reset: true })

    this.props.repeat(role);
  }

  clearRoles = () => {
    this.props.dispatch(setRole(null));
    this.props.dispatch(roleUpdated(!this.props.roleUpdated))
    this.props.dispatch(clearRole(true));
    this.redirect("/study");
  }

  render() {
    let role_options = [];
    let route_options = [];
    let switch_group = <p onClick={() => this.changeToGroup()} className="role-chooser" key="switch-group"><Icon type="team"/> Switch to Group Study</p>;
    let repeat_station = <p onClick={() => this.repeat(this.props.role)} className="role-chooser" key="repeat"><Icon type="redo"/> Repeat this Station</p>;
    let report_issue = <p onClick={() => this.reportIssue()} className="role-chooser" key="report-issue"><Icon type="warning" theme="twoTone"/> Report an error/issue</p>;
    let subscribe = <p onClick={() => this.redirect("/signup")} className="role-chooser" key="subscribe"><Icon type="credit-card" theme="twoTone"/> Subscribe Now</p>;
    let leave_group = <p onClick={() => this.leaveGroup()} className="role-chooser" key="leave-group"><Icon type="usergroup-delete" /> Leave Group</p>

    switch (this.props.role)
    {
      case "candidate-1":
        role_options.push(<p onClick={() => this.redirect("/study")} className="role-chooser primary" key="continue-candidate"><img src={candidate} alt="Candidate" />Continue Solo Study</p>);
        role_options.push(switch_group);
        break;
      case "candidate-2":
        role_options.push(<p onClick={() => this.redirect("/study")} className="role-chooser primary" key="continue-candidate"><img src={candidate} alt="Candidate" />Continue as Candidate</p>);
        break;
      case "examiner":
        if (this.props.playlist !== null)
          role_options.push(<p onClick={() => this.redirect("/study")} className="role-chooser" key="continue-examiner"><Icon type="export" rotate="180" />Exit Playlist</p>);
        else
        {
          role_options.push(<p onClick={() => this.redirect("/study")} className="role-chooser primary" key="continue-examiner"><img src={examiner} alt="Examiner" />Continue as Examiner</p>);
          role_options.push(<p onClick={() => this.clearRoles()} className="role-chooser" key="clear-roles"><Icon type="switcher" />Clear all roles</p>);
        }
        break;
      case "patient":
        role_options.push(<p onClick={() => this.redirect("/study")} className="role-chooser primary" key="continue-patient"><img src={patient} alt="Patient" />Continue as Patient</p>);
        break;
      case "observer":
        role_options.push(<p onClick={() => this.redirect("/study")} className="role-chooser primary" key="continue-observer"><img src={observer} alt="Observer" />Continue as Observer</p>);
        break;
      default:
        break;
    }

    if (this.props.playlist !== null && this.props.playlist.items[this.props.playlist_index + 1] !== undefined)
      route_options.push(<p onClick={() => this.props.nextPlaylistItem()} className="role-chooser next-playlist-btn" key="continue-playlist"><Icon type="fast-forward" /> Start Next Station</p>);

    if (!this.props.groupStudy || this.props.role === "examiner")
      route_options.push(repeat_station);
    if (!this.props.preview)
      route_options.push(report_issue);
    if (this.props.preview)
      route_options.push(subscribe);
    if (this.props.groupStudy)
      route_options.push(leave_group);

    return (
      <div>
        <h3>Please select one of the following options:</h3>
        <Row type="flex" gutter={24} className="station-complete">
          <Col md={12} xs={24}>{role_options}</Col>
          <Col md={12} xs={24}>{route_options}</Col>
        </Row>
      </div>
    )
  }
}

export default connect(state => {
  return {
    groupStudy: state.groupStudy !== null,
    playlist: state.playlist,
    playlist_index: state.playlistIndex,
    role: state.role,
    roleUpdated: state.roleFlag
  }
})(StationCompleted);