import React, { Component } from 'react';
import { ConnectedRouter as Router } from "react-router-redux";
import { Switch } from "react-router-dom";
import PageRoute from "./PageRoute";
import AuthPageRoute from "./AuthPageRoute";
import '../static/css/index.scss';
import { AuthScreen, LogoutPage, ActivatePage, ResetPasswordPage } from "./Auth";
import { Home } from "./Home";
import { Station } from "./Station";
import { Search } from "./Search";
import { StatsPage } from "./Stats";
import { SettingsPage, SubscriptionsPage, Profile, StudyGroupsPage } from './Settings';
import { ContactPage } from "./Contact";
import { PreviewPage } from "./Preview";
import { Page, SubscriptionPlansPage } from "./Pages";
import { Blogs, Blog } from "./Blogs";
import NotFound from './NotFound';
import PromotionPage from './Promotion/PromotionPage';
import ReactPixel from 'react-facebook-pixel';
import { setupEcho } from '../actions/Echo'

const pixel_id = process.env.REACT_APP_PIXEL_ID;

class App extends Component {
  componentDidMount() {
    this.props.store.dispatch(setupEcho());
    ReactPixel.init(pixel_id, {}, { autoConfig: true, debug: false, });
  }

  render() {
    return (
      <div>
        <Router history={this.props.history} store={this.props.store}>
          <Switch>
            <AuthPageRoute exact path="/search" component={Search} />
            <AuthPageRoute exact path="/study" component={Search} />
            <AuthPageRoute exact path="/station/:code" component={Station} />
            <AuthPageRoute exact path="/stats" component={StatsPage} />
            <AuthPageRoute exact path="/logout" component={LogoutPage} />
            <AuthPageRoute exact path="/study-groups" component={StudyGroupsPage} />
            <AuthPageRoute exact path="/settings" component={SettingsPage} />
            <AuthPageRoute exact path="/subscriptions" component={SubscriptionsPage} />
            <AuthPageRoute exact path="/profile" component={Profile} />
            <PageRoute exact path="/contact" component={ContactPage} />
            <PageRoute exact path="/" component={Home} />
            <PageRoute exact path="/activate/:code" component={ActivatePage} />
            <PageRoute exact path="/reset-password" component={ResetPasswordPage} />
            <PageRoute exact path="/reset-password/:code" component={ResetPasswordPage} />
            {/*<PageRoute exact path="/preview" component={PreviewPage} />*/}
            <PageRoute exact path="/login" component={AuthScreen} />
            <PageRoute exact path="/signup" component={AuthScreen} />
            <PageRoute exact path="/free-trial" component={AuthScreen} />
            <PageRoute exact path="/subscription-plans" component={SubscriptionPlansPage} />
            <PageRoute exact path="/promotion/:promotion" component={PromotionPage} />
            <PageRoute exact path="/blog" component={Blogs} />
            <PageRoute exact path="/blog/:slug" component={Blog} />
            <PageRoute exact path="/:slug" component={Page} />
            <PageRoute component={ NotFound } />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
