import React, { Component } from "react";
import { connect } from "react-redux";
import { Collapse } from 'antd';

const Panel = Collapse.Panel;

class Accordion extends Component {
    constructor (props){
        super(props);
        this.state = {
            open: null
        }
    }

    render() {
        let accordion = this.props.accordion;
        return <div className="accordion">
            <h1 className="accordion-name">{accordion.name}</h1>
            <p className="accordion-description" dangerouslySetInnerHTML={{ __html: accordion.description}} />

            <Collapse
                accordion
                bordered={false}
            >
                {accordion.accordions.map((value, index) => {
                    return <Panel header={value.title} key={"accordion-content-" + index}>
                        <div dangerouslySetInnerHTML={{ __html: value.content }} />
                    </Panel>
                })}
            </Collapse>
        </div>
    }
}

export default connect()(Accordion);