import React, { Component } from "react";
import { Form, Button, Input, Icon, Row, Col, Divider, Checkbox, Select } from "antd";
import { connect } from "react-redux";
import { setHideStationNames } from "../../constants/ActionTypes";
const FormItem = Form.Item;

class FiltersForm extends Component {

  componentDidMount = () => {
    if (Object.keys(this.props.filters).length > 0)
      this.setFields(this.props.filters);
  }

  submit = (random = false) => {
    this.props.search(this.createFiltersObject(random));
  }

  clearAll = () => {
    this.props.form.resetFields();
    this.submit();
  }

  createFiltersObject = (random = false) => {
    let filters = {};
    let levels = this.props.form.getFieldValue("levels");
    let difficulties = this.props.form.getFieldValue("difficulties");
    let types = this.props.form.getFieldValue("types");
    let specialties = this.props.form.getFieldValue("specialties");
    let systems = this.props.form.getFieldValue("systems");
    let free_trial = this.props.form.getFieldValue("free_trial");

    if (levels !== undefined)
      filters.levels = levels;
    if (difficulties !== undefined)
      filters.difficulties = difficulties;
    if (types !== undefined)
      filters.types = types;
    if (specialties !== undefined)
      filters.specialties = specialties;
    if (systems !== undefined)
      filters.systems = systems;
    if (free_trial !== undefined && free_trial.length > 0)
      filters.free_trial = true;

    let completed_filters = this.props.form.getFieldValue("status");

    return { filters, name: this.props.form.getFieldValue("name"), completed_filters, random };
  }

  saveFilters = () => {
    this.props.saveFilters(this.createFiltersObject());
  }

  loadFilters = () => {
    if (this.props.user.filters.hidden_station_names !== undefined)
      this.props.dispatch(setHideStationNames(this.props.user.filters.hidden_station_names))
    this.setFields(this.props.user.filters);
  }

  setFields = (filters) => {

    this.props.form.setFieldsValue({ name: filters.name,
                                      free_trial: filters.filters.free_trial !== undefined ? ["free_trial"] : [],
                                      status: filters.completed_filters,
                                      levels: filters.filters.levels,
                                      difficulties: filters.filters.difficulties,
                                      types: filters.filters.types,
                                      specialties: filters.filters.specialties,
                                      systems: filters.filters.systems
                                     });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    if (this.props.roleDetails === null || this.props.taxons === null)
      return "";
    let role_details = this.props.roleDetails;

    return (
        <Form>
          <Row type="flex" className="search-line">
            <Col>
              {getFieldDecorator("name", {})(
              <Input placeholder="Search any station here" size="large" onPressEnter={() => this.submit()} />)}
            </Col>
            <Col>
              <Button type="primary" size="large" onClick={() => this.submit()}><Icon type="search" /></Button>
            </Col>
          </Row>

          <div className="heading main-heading">
            <p className="filter-name">Filters</p>
            <p className="clear-filter" onClick={() => this.clearAll()}>Clear all filters</p>
          </div>
          <Divider />
          {this.props.user.status == "Trial" ?
          <div>
            <div className="heading">
              <p className="filter-name">Access</p>
              <p className="clear-filter" onClick={() => this.props.form.resetFields("free_trial")}>Clear</p>
            </div>
            <FormItem className="checkbox-item">
              {getFieldDecorator("free_trial", {})(
                <Checkbox.Group style={{ width: "100%" }}>
                  <Checkbox value="free_trial">Free Trial Stations</Checkbox>
                </Checkbox.Group>
              )}
            </FormItem>
          </div> : ""}

          <div className="heading">
            <p className="filter-name">Status</p>
            <p className="clear-filter" onClick={() => this.props.form.resetFields("status")}>Clear</p>
          </div>
          <FormItem className="checkbox-item">
            {getFieldDecorator("status", {})(
              <Checkbox.Group style={{ width: "100%" }}>
                <Checkbox value="starred">Starred Stations</Checkbox>
                <Checkbox value="not_attempted">Not Attempted</Checkbox>
                <Checkbox value="incomplete">Incomplete</Checkbox>
                <Checkbox value="attempt_again">Attempt Again</Checkbox>
                <Checkbox value="complete">Complete</Checkbox>
              </Checkbox.Group>
            )}
          </FormItem>

          {role_details.role !== "candidate-2" ?
            <div>
              <div className="heading">
                <p className="filter-name">Level</p>
                <p className="clear-filter" onClick={() => this.props.form.resetFields("levels")}>Clear</p>
              </div>
              <FormItem className="checkbox-item">
                {getFieldDecorator("levels", {})(
                  <Checkbox.Group style={{ width: "100%" }}>
                    {this.props.taxons.levels.map((value, index) => {
                      return <Checkbox value={value.id} key={"levels-" + index}>{value.name}</Checkbox>
                    })}
                  </Checkbox.Group>
                )}
              </FormItem>
            </div> : ""}

          {role_details.role !== "candidate-2" ?
            <div>
              <div className="heading">
                <p className="filter-name">Difficulty</p>
                <p className="clear-filter" onClick={() => this.props.form.resetFields("difficulties")}>Clear</p>
              </div>
              <FormItem className="checkbox-item">
                {getFieldDecorator("difficulties", {}) (
                  <Checkbox.Group style={{ width: "100%" }}>
                    {this.props.taxons.difficulties.map((value, index) => {
                      return <Checkbox value={value.id} key={"difficulties-" + index}>{value.name}</Checkbox>
                    })}
                  </Checkbox.Group>
                )}
              </FormItem>
            </div> : ""}

          <div className="heading">
            <p className="filter-name">Types</p>
            <p className="clear-filter" onClick={() => this.props.form.resetFields("types")}>Clear</p>
          </div>
          <FormItem>
            {getFieldDecorator("types", {})(
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Please select"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                getPopupContainer={trigger => trigger.parentNode}
              >
                {this.props.taxons.types.map((value, index) => {
                  return <Select.Option value={value.id} key={"types-" + index}>{value.name}</Select.Option>
                })}
              </Select>
            )}
          </FormItem>

          {role_details.role !== "candidate-2" ?
            <div>
              <div className="heading">
                <p className="filter-name">Specialties</p>
                <p className="clear-filter" onClick={() => this.props.form.resetFields("specialties")}>Clear</p>
              </div>
              <FormItem>
                {getFieldDecorator("specialties", {})(
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    getPopupContainer={trigger => trigger.parentNode}
                  >
                    {this.props.taxons.specialties.map((value, index) => {
                      return <Select.Option value={value.id} key={"specialties-" + index}>{value.name}</Select.Option>
                    })}
                  </Select>
                )}
              </FormItem>
            </div> : ""}

          <div className="heading">
            <p className="filter-name">Systems</p>
            <p className="clear-filter" onClick={() => this.props.form.resetFields("systems")}>Clear</p>
          </div>
          <FormItem>
            {getFieldDecorator("systems", {})(
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Please select"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                getPopupContainer={trigger => trigger.parentNode}
              >
                {this.props.taxons.systems.map((value, index) => {
                  return <Select.Option value={value.id} key={"systems-" + index}>{value.name}</Select.Option>
                })}
              </Select>
            )}
          </FormItem>
          <Button.Group size="default" className="form-buttons">
            <Button type="primary" onClick={() => this.submit()}>
              Apply Filters
            </Button>
            <Button type="primary" onClick={() => this.submit(true)}>
              Random Station
            </Button>
          </Button.Group>

          <Button.Group size="default" className="form-buttons">
            <Button onClick={() => this.saveFilters()}>Save Filters</Button>
            <Button onClick={() => this.loadFilters()}>My Filter</Button>
          </Button.Group>
        </Form>
    )
  }
}
export default connect()(Form.create({ name: 'login' })(FiltersForm));