import React, { Component } from "react";
import { Row, Col, Button, Icon } from "antd";

import candidate from "../../static/images/candidate-icon.svg";
import observer from "../../static/images/observer-icon.svg";
import patient from "../../static/images/patient-icon.svg";
import examiner from "../../static/images/examiner-icon.svg";

class RoleChooser extends Component {

  chooseRole = (value) => {
    this.props.selected(value);
  }

  render() {
    let candidate_user = this.props.members.find(member => member.role === "candidate-2");
    let patient_user = this.props.members.find(member => member.role === "patient");
    let examiner_user = this.props.members.find(member => member.role === "examiner");

    return (
      <div>
      <Row type="flex" justify="center" gutter={20} className="study-time">
        {!this.props.groupStudy ?
          <div className="buttons">
            <Button type="primary" className="button" onClick={() => this.chooseRole("candidate-1")}><Icon type="book" />Solo Study</Button>
            <Button type="primary" className="button" onClick={() => this.props.redirect("/study-groups")}><Icon type="team" /> Group Study</Button>
          </div>
        : <Col md={8} xs={24} className="roles-container">
            <div className="station-section">
              <h2>Group Study</h2>
              <div>
                {candidate_user !== undefined ?
                    <p className="role-chooser selected"><img src={candidate} alt="Candidate" />Candidate <span className="selected-by">Selected by {candidate_user.name}</span></p>
                    : <p onClick={() => this.chooseRole("candidate-2")} className="role-chooser"><img src={candidate} alt="Candidate" />Candidate</p>}
                {patient_user !== undefined ?
                    <p className="role-chooser selected"><img src={patient} alt="Patient" />Patient <span className="selected-by">Selected by {patient_user.name}</span></p>
                    : <p onClick={() => this.chooseRole("patient")} className="role-chooser"><img src={patient} alt="Patient" />Patient</p>}
                {examiner_user !== undefined ?
                    <p className="role-chooser selected"><img src={examiner} alt="Examiner" />Examiner <span className="selected-by">Selected by {examiner_user.name}</span></p>
                    : <p onClick={() => this.chooseRole("examiner")} className="role-chooser"><img src={examiner} alt="Examiner" />Examiner</p>}
                <p onClick={() => this.chooseRole("observer")} className="role-chooser"><img src={observer} alt="Observer" />Observer</p>
              </div>
            </div>
            {this.props.clearRoles !== undefined ? <p onClick={() => this.props.clearRoles()} className="role-chooser"><Icon type="switcher" />Clear all roles</p> : ""}
          </Col>
        }
      </Row>
      </div>
    )
  }
}

export default RoleChooser;