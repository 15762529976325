import React, { Component } from 'react';
import { Icon, Button } from "antd";
import { Link } from "react-router-dom";

class NotFound extends Component {

  render() {
    return (
        <div id="not-found-page" style={{ marginTop: '3em' }}>
            <div className={`container fixed`}>
                <Icon
                    type="close-circle"
                    style={{ fontSize: "50px" }}
                    theme="twoTone"
                    twoToneColor="#FF3333"
                />
                <h1>Whoops!</h1>
                <p>It looks like you've clicked an invalid or broken link.</p>

                <Link to="/">
                    <Button type="primary" style={{ marginTop: '2em' }}>
                        <Icon type="left" />Return Home
                    </Button>
                </Link>
            </div>
        </div>
    );
  }
}

export default NotFound;