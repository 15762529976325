import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Button, Modal, Tag, Icon, Tooltip, message } from "antd";
import { broadcastRole, broadcastStationSuggestion } from "../../actions/Echo"
import { setRole, roleUpdated } from "../../constants/ActionTypes"
import RoleChooser from "../Partials/RoleChooser";
import Percentage from "../Partials/Percentage";

class StatsStationDisplay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_modal: false
    }
    this.candidate = (this.props.role === "candidate-1" || this.props.role === "candidate-2");
  }

  chooseRole = (role) => {
    this.props.dispatch(setRole(role))
    this.props.dispatch(broadcastRole(role))
    this.props.dispatch(roleUpdated(!this.props.roleUpdated))
    Modal.destroyAll();
    this.props.chooseStation(this.props.station)
  }

  roleChooserRedirect = (redirect) => {
    Modal.destroyAll();
    message.warning("Return to my stats once you have entered a group")
    this.props.dispatch(push(redirect))
  }

  switchSolo = () => {
    Modal.destroyAll();
    this.props.dispatch(broadcastRole("candidate-1"))
    this.props.dispatch(roleUpdated(!this.props.roleUpdated))
    this.props.dispatch(end("/study"));
  }

  trial_available = () => {
    return !(this.props.user.status == "Trial" && !this.props.station.available_to_trial);
  }

  start = (check_completed = true) => {
    if (!this.trial_available())
      return false;

    if (this.props.groupStudy)
      this.props.dispatch(broadcastStationSuggestion(this.props.station.number))
    else if (this.props.user.completed.indexOf(this.props.station.id) > -1 && this.candidate && check_completed)
      this.setState({ show_modal: true });
    else if (this.props.role === null) {
      Modal.warning({
        title: 'Please choose a role before continuing',
        content: <RoleChooser selected={(role) => this.chooseRole(role)} groupStudy={this.props.groupStudy} members={this.props.members} redirect={(redirect) => this.roleChooserRedirect(redirect)} />,
        width: '80%',
        className: 'modal-role-chooser',
        okButtonProps: { disabled: true },
        okText: this.props.groupStudy ? "Switch to Solo Study" : "Switch to Group Study"
      });
    }
    else
      this.props.chooseStation(this.props.station);
  }

  addToPlaylist = () => {
    if (this.props.user.status == "Trial" && !this.props.station.available_to_trial)
      return false;

    this.props.addToPlaylist(this.props.station)
  }

  buttonClassName = () => {
    if (!this.trial_available())
      return "start-btn unavailable disabled"
    return "start-btn";
  }

  render() {
    let station = this.props.station;

    let levels = station.stationTaxons.levels.map((value, index) => {
      return value.level + (index === station.stationTaxons.levels.length - 1 ? "" : ", ");
    });
    let systems = station.stationTaxons.systems.map((value, index) => {
      return value.system + (index === station.stationTaxons.systems.length - 1 ? "" : ", ");
    });
    let types = station.stationTaxons.types.map((value, index) => {
      return value.type + (index === station.stationTaxons.types.length - 1 ? "" : ", ");
    });
    let difficulties = station.stationTaxons.difficulties.map((value, index) => {
      let colour = value.difficulty === 'Easy' ? 'green' : (value.difficulty === 'Intermediate' ? 'orange' : 'red');
      return <Tag color={colour} key={"difficulty-" + index}>{value.difficulty}</Tag>;
    });
    let specialties = station.stationTaxons.specialties.map((value, index) => {
      return value.specialty + (index === station.stationTaxons.specialties.length - 1 ? "" : ", ");
    });

    let status = "";
    if (station.status !== undefined && station.status === "not_attempted")
      status = <Tag className="status-tag" color="gold">Not Attempted</Tag>;
    else if (station.status !== undefined && station.status === "incomplete")
      status = <Tag className="status-tag" color="volcano">Incomplete</Tag>;
    else if (station.status !== undefined && station.status === "complete")
      status = <Tag className="status-tag" color="blue">Complete</Tag>;
    else if (station.status !== undefined && station.status === "attempt_again")
      status = <Tag className="status-tag" color="purple">Attempt Again</Tag>;

    return (
      <div className="station-container">
        <Modal
          title="Warning"
          visible={this.state.show_modal}
          onOk={() => this.start(false)}
          onCancel={() => this.setState({ show_modal: false })}
          okText="Continue"
          cancelText="Choose Another Station"
        >
          <p>You have attempted and marked this station as complete. Do you wish to continue, or choose another station?</p>
        </Modal>

        <div className="station-display">
          <div className="station-info">
            <h2><b>{station.number}</b>{this.props.displayName ? <span>{station.name}</span> : ""}<span className="tags">{this.props.displayName || this.props.role === "candidate-1" ? difficulties : ""} {status}</span></h2>

            <table className="stations-list">
              <tbody>
                {(this.props.displayName || this.props.role === "candidate-1") &&
                  <tr>
                    <td className="table-heading">Levels</td>
                    <td className="table-data">{levels.length > 0 ? levels : "-"}</td>
                  </tr>}
                <tr>
                  <td className="table-heading">Systems</td>
                  <td className="table-data">{systems.length > 0 ? systems : "-"}</td>
                </tr>
                <tr>
                  <td className="table-heading">Types</td>
                  <td className="table-data">{types.length > 0 ? types : "-"}</td>
                </tr>
                {(this.props.displayName || this.props.role === "candidate-1") &&
                  <tr>
                    <td className="table-heading">Specialties</td>
                    <td className="table-data">{specialties.length > 0 ? specialties : "-"}</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>

          <div className="station-actions">
            {station.mark !== undefined ? <Percentage mark={station.mark} max_marks={100} tooltip={true} /> : ""}

            {station.history ? <Button type="primary" onClick={() => this.props.showNotes(station.id)} className="start-btn">
              History<Icon type="right" />
            </Button> : ""}

            <Tooltip title={!this.trial_available() ? "This station is only available to paid subscribers" : ""}>
              <Button type="primary" onClick={this.start} className={this.buttonClassName()} style={{ marginTop: "1em" }}>
                {this.props.groupStudy ? "Suggest" : "Start"} <Icon type="right" />
              </Button>
            </Tooltip>


            {this.props.playlist ?
              <Button onClick={() => this.addToPlaylist()} className={"playlist-btn" + (!this.trial_available() ? " disabled" : "")}><Icon type="bars" />Add to Playlist</Button>
              : ""}
          </div>
        </div>
      </div>
    )
  }
}

export default connect(state => {
  return {
    groupStudy: state.groupStudy !== null,
    members: state.members,
    role: state.role,
    roleUpdated: state.roleFlag
  }
})(StatsStationDisplay);