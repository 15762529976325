import {
  ROLE,
  ROLE_UPDATED
} from "../constants/ActionTypes"

export const role = (state = null, action) => {
  switch(action.type) {
    case ROLE:
      return action.value;
    default:
      return state;
  }
}

export const roleFlag = (state = false, action) => {
  switch(action.type) {
    case ROLE_UPDATED:
      return action.value;
    default:
      return state;
  }
}