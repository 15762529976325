import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { APITokenless } from '../../actions/API.js';
import { Link } from "react-router-dom";
import { Elements, StripeProvider } from 'react-stripe-elements';
import { message } from "antd";
import SignupForm from "./Forms/SignupForm";
import LoginForm from "./Forms/LoginForm";
import Errors from "./Errors";
import AuthModals from "./AuthModals";
import MetaTags from 'react-meta-tags';
import ReactPixel from 'react-facebook-pixel';

import { loginSuccess } from "../../actions/auth";
import { calculateTotalCost } from "../../actions/functions";
import { setupEcho } from '../../actions/Echo'
import { setRole } from "../../constants/ActionTypes"
const stripeKey = process.env.REACT_APP_STRIPE_API_KEY;
const country = process.env.REACT_APP_COUNTRY

class AuthScreen extends Component {
  constructor(props) {
    super(props);

    let path = props.match.path;

    this.state = {
      showSignUp: (path === "/signup" || path === "/free-trial"),
      showLogIn: path === "/login",
      freeTrial: path === "/free-trial",
      errors: [],
      show_forgot_password: false,
      visible_modal: null,
      form_data: null,
      countries: null,
      medical_schools: null,
      subscription_types: null,
      loading: true,
      voucher: null,
      terms_and_conditions: null,
    }

    this.errorRef = React.createRef();
  }

  componentDidMount = async() => {
    let countries = await this.props.dispatch(APITokenless("/countries", "GET"));
    let medical_schools = await this.props.dispatch(APITokenless("/medical_schools", "GET"));
    let subscription_types = await this.props.dispatch(APITokenless(this.state.freeTrial ? "/subscription_types/trial" : "/subscription_types/signup?country=" + country, "GET"));
    let terms_and_conditions = await this.props.dispatch(APITokenless("/terms_and_conditions", "GET"));
    if (!this.unmounted)
      this.setState({ countries, medical_schools, subscription_types, terms_and_conditions, loading: false });

    if (this.state.showSignUp)
      ReactPixel.pageView();
  }

  componentWillUnmount = () => {
    if (this.state.showSignUp)
      ReactPixel.trackCustom("noSignup", { left_page: true})
    this.unmounted = true;
  }

  componentWillReceiveProps = (props) => {
    if (props.match.path === "/signup")
      this.showSignUp();
    else if (props.match.path === "/login")
      this.showLogin();
    else if (props.match.path === "/free-trial")
      this.showTrial();
  }

  showLogin = async() => {
    let subscription_types = await this.props.dispatch(APITokenless("/subscription_types/signup?country=" + country, "GET"));
    this.setState({ showLogIn: true, showSignUp: false, freeTrial: false, subscription_types });
  }

  showSignUp = () => {
    this.setState({ showLogIn: false, showSignUp: true, freeTrial: false });
    ReactPixel.pageView();
  }

  showTrial = async() => {
    let subscription_types = await this.props.dispatch(APITokenless("/subscription_types/trial", "GET"));
    this.setState({ showLogIn: false, showSignUp: true, freeTrial: true, subscription_types})
    ReactPixel.pageView();
  }

  creditCardSignUp = async (data) => {
    this.setState({errors: []});
    if (this.state.voucher !== null)
      data.voucher = this.state.voucher.id;

    window.scrollTo(0, this.errorRef.current.offsetTop);
    data.country = country;
    let response = await this.props.dispatch(APITokenless("/credit_card_signup", "POST", data));
    if (response.success === true)
    {
      if (!this.unmounted)
        this.setState({showLogIn: true, showSignUp: false, loading: false, voucher: null});
      message.success("Thanks for signing up to OSCEbank! You have been sent an activation email (please note that this may take up to 10 minutes to arrive in your inbox)", 20);

      ReactPixel.track("CompleteRegistration", { content_name: "signup", currency: "AUD", status: true, value: calculateTotalCost(this.state.voucher, this.state.subscription_types.find(type => type.id === data.subscription_type_id))});
      return true;
    }
    else
    {
      if (!this.unmounted)
        this.setState({ errors: response.errors, loading: false });
      return false;
    }
  }

  payPalSignup = async (data) => {
    if (this.state.voucher !== null)
      data.voucher = this.state.voucher.id;
    window.scrollTo(0, this.errorRef.current.offsetTop);
    let response = await this.props.dispatch(APITokenless("/paypal_signup", "POST", data));
    if (response.success === true) {
      if (!this.unmounted)
        this.setState({ showLogIn: true, showSignUp: false, loading: false, voucher: null });
      message.success("Thanks for signing up to OSCEbank! You have been sent an activation email (please note that this may take up to 10 minutes to arrive in your inbox)", 20);

      ReactPixel.track("CompleteRegistration", { content_name: "signup", currency: "AUD", status: true, value: calculateTotalCost(this.state.voucher, this.state.subscription_types.find(type => type.id === data.subscription_type_id)) });
      return true;
    }
    else {
      if (!this.unmounted)
        this.setState({ errors: ["Sorry, something went wrong. Please contact OSCEbank directly and use " + data.paypal_reference + " as your reference."], loading: false });
      return false;
    }
  }

  trialSignup = async (data) => {
    window.scrollTo(0, this.errorRef.current.offsetTop);
    let response = await this.props.dispatch(APITokenless("/trial_signup", "POST", data));
    if (response.success === true) {
      if (!this.unmounted)
        this.setState({ showLogIn: true, showSignUp: false, loading: false, voucher: null });
      message.success("You have successfully registered for a free trial with OSCEbank! Please check your emails to activate your account!");

      ReactPixel.track("StartTrial", { currency: "AUD", value: 0, predicted_ltv: 7 });
      return true;
    }
    else {
      if (!this.unmounted)
        this.setState({ errors: ["Sorry, something went wrong. Please contact OSCEbank directly."], loading: false });
      return false;
    }
  }

  login = async (data) => {
    let response = await this.props.dispatch(APITokenless("/login", "POST", data));
    if (response.success === true)
    {
      localStorage.setItem("token", response.token);
      message.success("You have successfully logged in!");
      this.props.dispatch(setRole(null));
      this.props.dispatch(loginSuccess(response.token), null);
      this.props.dispatch(setupEcho());
      this.props.dispatch(push("/profile"));
    }
    else
    {
      if (response.message)
        message.error(response.message);
      else if (response.modal)
        this.setState({ visible_modal: response.modal, form_data: data });

      if (response.show_forgot_password)
        this.setState({ show_forgot_password: true });
    }

    return response.success;
  }

  loginWithFacebook = async (response) => {
    if (response.email === undefined) {
      message.error("Sorry, it looks like you haven't granted access to let us see your email address. Please enable access to login via Facebook.");
    } else {
      let login_response = await this.props.dispatch(APITokenless("/fb_login", "POST", {email: response.email}));
      if (login_response.success === true) {
        localStorage.setItem("token", login_response.token);
        message.success("You have successfully logged in!");
        this.props.dispatch(setRole(null));
        this.props.dispatch(loginSuccess(login_response.token), null);
        this.props.dispatch(setupEcho());
        this.props.dispatch(push("/profile"));
      }
      else {
        if (login_response.message)
          message.error(login_response.message);
        else if (login_response.modal)
          this.setState({ visible_modal: login_response.modal, form_data: {email: response.email, fb_login: true} });
      }
    }
  }

  modalConfirm = async () => {
    if (this.state.visible_modal === 'confirm_token_wipe')
    {
      let response = await this.props.dispatch(APITokenless("/login-and-clear", "POST", this.state.form_data));
      if (response.success === true)
      {
        this.setState({ visible_modal: null, form_data: null });
        localStorage.setItem("token", response.token);
        message.success("You have successfully logged in!");
        this.props.dispatch(setRole(null));
        this.props.dispatch(loginSuccess(response.token), null);
        this.props.dispatch(setupEcho());
        this.props.dispatch(push("/profile"));
      }
      else if (response.message) {
        message.error(response.message);
      }
    }
    if (!this.unmounted)
      this.setState({ visible_modal: null, form_data: null });
  }

  setLoading = (state) => {
    this.setState({loading: state});
  }

  applyVoucher = async (voucher) => {
    this.setState({ loading: true });
    let response = await this.props.dispatch(APITokenless("/apply_voucher", "POST", { voucher: voucher}));
    this.setState({ loading: false });
    if (response.success !== undefined && response.success === false)
      message.error("Sorry, but that doesn't look like a valid voucher");
    else {
      this.setState({ voucher: response });
      message.success("You have successfully applied your voucher!");
    }
    return true;
  }

  validate = async (data) => {
    this.setState({ errors: [] });
    if (this.state.voucher !== null)
      data.voucher = this.state.voucher.id;

    let response = await this.props.dispatch(APITokenless("/signup_validation", "POST", data));
    if (response.success === true) {
      return true;
    }
    else {
      if (response.errors.length > 0)
        window.scrollTo(0, this.errorRef.current.offsetTop);
      if (!this.unmounted)
        this.setState({ errors: response.errors, loading: false });
      return false;
    }
  }

  render() {
    let selected_plan = null;
    if(this.props.location.pathname === "/signup" && this.props.location.search !== "")
      selected_plan = parseInt(this.props.location.search.split("plan=")[1], 10);
    return (
        <div id="login">
            <div className={`container fixed`}>
                <Errors errors={this.state.errors} ref={this.errorRef} />
                <AuthModals visible_modal={this.state.visible_modal} close={() => this.setState({ visible_modal: null })} modalConfirm={this.modalConfirm} />
                {this.state.showSignUp ?
                    <div>
                        <MetaTags>
                            <title>Sign Up | OSCEbank</title>
                        </MetaTags>
                        <StripeProvider apiKey={stripeKey}>
                            <Elements>
                                <div>
                                    <h1 className="signup-heading">Sign Up to OSCEbank</h1>
                                    <SignupForm
                                        creditCardSignup={this.creditCardSignUp}
                                        payPalSignup={this.payPalSignup}
                                        trialSignup={this.trialSignup}
                                        countries={this.state.countries}
                                        medical_schools={this.state.medical_schools}
                                        subscription_types={this.state.subscription_types}
                                        loading={this.state.loading}
                                        setLoading={this.setLoading}
                                        applyVoucher={this.applyVoucher}
                                        voucher={this.state.voucher}
                                        validate={this.validate}
                                        signupSuccess={false}
                                        selectedPlan={selected_plan}
                                        termsAndConditions={this.state.terms_and_conditions}
                                        trial={this.state.freeTrial}
                                        promotion={false}
                                    />
                                </div>
                            </Elements>
                        </StripeProvider>
                    </div>
                : this.state.showLogIn ?
                    <div>
                        <MetaTags>
                            <title>Log In | OSCEbank</title>
                        </MetaTags>
                        <LoginForm
                            onSave={this.login}
                            showForgotPassword={this.state.show_forgot_password}
                            loginWithFacebook={this.loginWithFacebook}
                        />
                    </div>
                : ""}

                {this.state.showSignUp ?
                  <p style={{ marginTop: '1.5em', textAlign: 'center' }}>Already Registered? <Link to="login">Log In</Link></p>
                : this.state.showLogIn ?
                  <p style={{ marginTop: '1.5em', textAlign: 'center' }}>Not registered yet? <Link to="signup">Sign Up</Link></p>
                : ""}
            </div>
        </div>
    )
  }
}

export default connect()(AuthScreen);
